'use strict';


var SceneLoading = new Phaser.Class({

  Extends: Phaser.Scene,

  initialize:

  function SceneLoading ()
  {
    Phaser.Scene.call(this, { key: 'sceneLoading', active: false });
  },

  init: function(params){
    this.sceneBoot = this.scene.get('sceneBoot');
    this.scaler = this.sceneBoot.scaler;

    this.sceneBoot.scaleMode = 'height';
    this.scaler.scaleMode = 'height';

    this.progress = 0;

    this.sceneBoot.windowResized = true;

    this.frame = 0;
  },

  preload: function ()
  {
  },

  create: function ()
  {
    var self = this;
    var sprite, designX, designY;

    designX = 0;
    designY = 0;
    sprite = this.add.rectangle(
      this.scaler.scaleX(this.scaler.designRefWidth()/2 + designX),
      this.scaler.scaleY(this.scaler.designRefHeight()/2 + designY),
      this.scaler.designRefWidth(),
      this.scaler.designRefHeight()
      , 0xffffff, 1);
    sprite.setOrigin(0.5, 0.5);
    this.scaler.scaleSpriteFill(sprite);
    sprite.keepData = {
      resized: true,
      fill: true,
      xlocation: 'center',
      ylocation: 'center',
      designX: designX,
      designY: designY
    };
    sprite.setInteractive();

    designX = 0;
    designY = 0;
    sprite = this.add.rectangle(
      this.scaler.scaleX(this.scaler.designRefWidth()/2 + designX),
      this.scaler.scaleY(this.scaler.designRefHeight()/2 + designY),
      this.scaler.designRefWidth(),
      this.scaler.designRefHeight()
      , 0x660874, 0.25);
    sprite.setOrigin(0.5, 0.5);
    this.scaler.scaleSpriteFill(sprite);
    sprite.keepData = {
      resized: true,
      fill: true,
      xlocation: 'center',
      ylocation: 'center',
      designX: designX,
      designY: designY
    };
    sprite.setInteractive();

    // this.iconLoadingSprites = [];
    // for(var i = 0; i < 10; i ++) {
    //   designX = -341 - 104 * i;
    //   designY = 540;
    //   sprite = this.add.image(
    //     this.scaler.scaleX(this.scaler.designRefWidth()/2 + designX),
    //     this.scaler.scaleY(designY),
    //     'icon_loading');
    //   sprite.setOrigin(0.5, 0.5);
    //   this.scaler.scaleSprite(sprite);
    //   sprite.keepData = {
    //     resized: true,
    //     xlocation: 'center',
    //     designX: designX,
    //     designY: designY,
    //     originalX: designX,
    //   };
    //   this.iconLoadingSprites.push(sprite);
    // }

    // for(var i = 0; i < 10; i ++) {
    //   designX = 283 + 104 * i;
    //   designY = 540;
    //   sprite = this.add.image(
    //     this.scaler.scaleX(this.scaler.designRefWidth()/2 + designX),
    //     this.scaler.scaleY(designY),
    //     'icon_loading');
    //   sprite.setOrigin(0.5, 0.5);
    //   this.scaler.scaleSprite(sprite);
    //   sprite.keepData = {
    //     resized: true,
    //     xlocation: 'center',
    //     designX: designX,
    //     designY: designY,
    //     originalX: designX,
    //   };
    //   this.iconLoadingSprites.push(sprite);
    // }

    designX = 33;
    designY = 540;
    sprite = this.add.image(
      this.scaler.scaleX(this.scaler.designRefWidth()/2 + designX),
      this.scaler.scaleY(designY),
      'text_loading');
    sprite.setOrigin(1, 0.5);
    this.scaler.scaleSprite(sprite);
    sprite.keepData = {
      resized: true,
      xlocation: 'center',
      designX: designX,
      designY: designY,
      originalX: designX,
    };
    this.textLoadingSprite = sprite;
    
    designX = 35;
    designY = 525;
    sprite = this.add.bitmapText(
      this.scaler.scaleX(this.scaler.designRefWidth()/2 + designX),
      this.scaler.scaleY(designY),
      'loadingFont',
      '0%', 
      24
    );
    sprite.setOrigin(0, 0);
    // sprite.setCenterAlign();
    this.scaler.scaleSprite(sprite);
    sprite.keepData = {
      resized: true,
      xlocation: 'center',
      designX: designX,
      designY: designY,
      originalX: designX,
    };
    this.loadingTextSprite = sprite;

    designX = (window.sidebarOffset + GC.sidebarWidth)/2;
    designY = 882;
    sprite = this.add.image(
      this.scaler.scaleX(this.scaler.designRefWidth()/2  + designX),
      this.scaler.scaleY(designY),
      'btn_refresh'
    );
    sprite.setOrigin(0.5, 0.5);
    this.scaler.scaleSpriteByHeight(sprite);
    sprite.keepData = {
      resized: true,
      xlocation: 'center',
      designX: designX,
      designY: designY
    };
    this.btnRefreshSprite = sprite;
    this.btnRefreshSprite.visible = false;

    sprite.setInteractive();
    sprite.on("pointerdown", pointer => {
      this.btnRefreshSprite.alpha = 0.6;
    });
    sprite.on("pointerup", pointer => {
      this.btnRefreshSprite.alpha = 1.0;

      console.log('reload...');
      window.location.reload();
    });

    designX = 0;
    designY = -72;
    sprite = this.add.image(
      this.scaler.scaleX(this.scaler.designRefWidth()/2 + designX),
      this.scaler.scaleY(this.scaler.designRefHeight() + designY),
      'btn_skip'
    );
    sprite.setOrigin(0.5, 1);
    this.scaler.scaleSprite(sprite);
    sprite.keepData = {
      resized: true,
      xlocation:'center',
      ylocation:'bottom',
      designX: designX,
      designY: designY
    };
    sprite.visible = false;

    sprite.setInteractive();
    sprite.on("pointerdown", pointer => {
      this.btnSkipSprite.alpha = 0.6;
    });
    sprite.on("pointerup", pointer => {
      this.btnSkipSprite.alpha = 1.0;

      if(this.scene.get('scenePhoto')){
        this.scene.get('scenePhoto').nextPage();
      }

      this.scene.get('scenePreload').stopLoadPhotoSources();
    });
    this.btnSkipSprite = sprite;

    this.sceneBoot.windowResized = true;
    // this.loadingAnimation();
  },

  update: function(){

  },

  resized: function () {
    if(this.animationing) {
      return;
    }
    utils.resized(this);
  },

  moveRight: function() {
    var targetSprites = [];

    // this.iconLoadingSprites.forEach(sprite => {
    //   targetSprites.push(sprite);
    // });
    targetSprites.push(this.textLoadingSprite);
    targetSprites.push(this.loadingTextSprite);

    targetSprites.forEach(sprite => {
      sprite.keepData.designX = sprite.keepData.originalX + (window.sidebarOffset + GC.sidebarWidth)/2;
      sprite.x = this.scaler.scaleX(this.scaler.designRefWidth()/2 + sprite.keepData.designX);
    });

    this.btnRefreshSprite.visible = true;
  },

  setLoadingProgress: function(progress, autoOpen){
    if(this.loadingTextSprite){
      this.progress = progress;
      this.loadingTextSprite.setText('L' + progress + '%');
    }

    var open = true;
    if(autoOpen != undefined){
      open = autoOpen;
    }
    if(open){
     this.openLoadingMask(); 
    }

    if(this.scene.isActive('sceneSideBar') && this.scene.get('sceneSideBar').scene.isVisible()){
      this.scene.get('sceneSideBar').scene.bringToTop();
    }
    if(this.scene.isActive('sceneSystemPreface') && this.scene.get('sceneSystemPreface').scene.isVisible()){
      this.scene.get('sceneSystemPreface').scene.bringToTop();
    }
    if(this.scene.isActive('sceneAvatar') && this.scene.get('sceneAvatar').scene.isVisible()){
      this.scene.get('sceneAvatar').scene.bringToTop();
    }
  },
  
  openLoadingMask: function(showBg){
    this.cameras.main.alpha = 1;
    if(this.scene.isPaused()){
      this.scene.resume();
    }
    this.scene.setVisible(true);
    this.scene.bringToTop();
  },

  closeLoadingMask: function(){
    this.scene.setVisible(false);
    this.scene.sendToBack();

    // if(this.scene.isActive('sceneMain') && this.scene.get('sceneMain').scene.isVisible()){
    //   this.scene.get('sceneMain').bgSprite.visible = false;
    // }

    // this.scene.pause();
  },

  loadingAnimation: function(){
    this.tweens.add({
      targets: this.iconLoadingSprite,
      angle: 360,
      duration: 1000,
      delay: 0,
      ease: 'Linear',
      repeat: -1,
      yoyo: false,
      onComplete: function () {
      },
    }); 
  },

  enterAnimation: function(func) {
    utils.slideAimation(this, 'in', function() {
      if(func) {
        func();
      }
    });
  },

  outAnimation: function(func_callback) {
    utils.slideAimation(this, 'back', function() {
      if(func_callback) {
        func_callback();
      }
    });
  },

  fadeOutAnimation: function(func_callback) {
    var self = this;
    this.animationing = true;

    utils.sceneFadeOutAnimation(this, function() {
      if(func_callback) {
        func_callback();
      }
      self.animationing = false;
    });
  },

  updateElementsPosition: function(offset){
    this.loadingTextSprite.x = this.scaler.scaleX((this.scaler.designRefWidth() + offset)/2 - 99);
    this.loadingTextSprite.keepData.designX = -99 + offset/2;

    this.iconLoadingSprites.forEach((sprite, index) => {
      sprite.x = this.scaler.scaleX(this.scaler.designRefWidth()/2 + offset/2 -53 + 53*index);
      sprite.keepData.designX = -53 + 53*index + offset/2;
    });

    this.btnRefreshSprite.x = this.scaler.scaleX(this.scaler.designRefWidth()/2 + offset/2);
    this.btnRefreshSprite.keepData.designX = offset/2;
  },

  circleAnimation: function() {
    var totalFrame = 180;
    this.circleAnimationTimer = this.time.addEvent({
      delay: 1000/30,
      callback: function(){
        this.frame ++;
        
        if(this.frame > totalFrame - 1){
          this.frame = 0;
        }
        this.animationSprite.setFrame(this.frame);
      },
      callbackScope: this,
      loop: true
    });

  },

  stopCircleAnimation: function() {
    if(this.circleAnimationTimer) {
      this.circleAnimationTimer.destroy();
    }
  },
});
export default SceneLoading;