import GC from "./const";
import videoController from "./video-controller-sprite";
var EventEmitter = require('events').EventEmitter;

var _self;

function Portfolio(scene, scaler) {
  this.scene = scene;
  this.scaler = scaler;
  this.depth = 60000;
  this.group = this.scene.add.group();
  this.bgSprite;
  this.closeSprite;
  this.leftSprite;
  this.rightSprite;
  this.labelSprite;
  this.zoomInSprite;
  this.zoomOutSprite;

  this.current;
  this.currentItem;
  this.imageSprite;
  this.imageDragSprite;
  this.videoSprites = [];
  this.showItems;

  this.zoom = 1.0;

  this.videoProgress;
  this.loadState = GC.UNLOAD;
  this.createState = GC.DESTROYED;

  this.targetItem;
  _self = this;

  this.displayBounds = {
    left: this.scaler.scaleX(170 + window.sidebarOffset + GC.sidebarWidth),
    top: this.scaler.scaleX(112),
    width: this.scaler.scaleX(this.scaler.designRefWidth() - (170 + window.sidebarOffset + GC.sidebarWidth) - (316)),
    height: this.scaler.scaleY(this.scaler.designRefHeight() - 112 - 244),
  };

  this.showVideoController = true;

  this.videoController = new videoController(scene, scaler, this);
  this.videoController.setDepth(this.depth + 10000);

  this.allowDown = true;
  this.allowUp = true;

  // this.scrollerBarBounds = {
  //   top: 460 - 480/2,
  //   bottom: 460 + 480/2,
  // };

  // this.on('dragstart-zoom', function () {
  //   this.dragSprites.map(sprite => {
  //     sprite.keepData.x = sprite.x;
  //     sprite.keepData.y = sprite.y;
  //   });
  // });

  // this.on('drag-zoom', function(evt) {
  //   if(evt.offset < 0 && this.allowDown == false)
  //     return;
  //   if(evt.offset > 0 && this.allowUp == false)
  //     return;
  //   let v = 2;
  //   this.dragSprites.map(sprite => {
  //     sprite.y = sprite.keepData.y + evt.offset * v;
  //   });
  // });

  // this.on('dragend-zoom', function(evt) {
  //   this.dragSprites.map(sprite => {
  //     sprite.alpha = sprite.keepData.alpha;
  //   });
  // }, this);
}

Portfolio.prototype.__proto__ = EventEmitter.prototype;

Portfolio.prototype.setMaterials = function(materials) {
  this.materials = materials;
};

Portfolio.prototype.setItems = function(items) {
  var self = this;
  this.items = {};

  this.itemsByGroup = {};
  for(var key in items) {
    var group = items[key].group;
    if(this.itemsByGroup[group]) {
      this.itemsByGroup[group][key] = items[key];
    } else {
      this.itemsByGroup[group] = {};
      this.itemsByGroup[group][key] = items[key];
    }
  }

  // 按照作品图集顺序排序
  for(var groupKey in this.itemsByGroup) {
    const ordered = {};
    var keys = Object.keys(this.itemsByGroup[groupKey]);
    var indexs = [];
    keys.forEach(key => {
      indexs.push(parseInt(key.replace('item__', '')));
    });
    indexs.sort(this.compare);

    indexs.forEach(key => {
      ordered['item__' + key] = this.itemsByGroup[groupKey]['item__' + key];
    });

    // Object.keys(this.itemsByGroup[groupKey]).sort().forEach(function(key) {
    //   ordered[key] = self.itemsByGroup[groupKey][key];
    // });
    this.itemsByGroup[groupKey] = ordered;
  

    for(var itemKey in this.itemsByGroup[groupKey]) {
      this.items[[itemKey]] = this.itemsByGroup[groupKey][itemKey];
    }
  }
};

Portfolio.prototype.setVideos = function (videos) {
  this.videos = videos;
};

Portfolio.prototype.preload = function() {
};

Portfolio.prototype.create = function() {
  var self = this;
  let designX, designY, sprite;

  // add the temporary sprite for image
  // sprite = this.scene.add.image(0, 0, 'bg_dot_black');
  sprite = this.scene.add.rectangle(
    0,
    0,
    this.scaler.designRefWidth(),
    this.scaler.designRefHeight()
    , 0x000000, 0.5);
  sprite.setOrigin(0, 0);
  //bgSprite.setScale(
  //  this.scene.cameras.main.worldView.width,
  //  this.scene.cameras.main.worldView.height
  //);
  //sprite.keepData = {
  //  resized: true
  //};
  sprite.depth = this.depth;
  sprite.setInteractive();  // to disable drags behind the background
  this.group.add(sprite);
  this.bgSprite = sprite;
  //bgSprite.setInteractive();

  // close button

  designX = -143;
  designY = 948;
  sprite = this.scene.add.image(
    this.scaler.scaleX(this.scaler.designRefWidth() + designX),
    this.scaler.scaleY(designY),
    'btn_close_portfolio'
  );
  sprite.setOrigin(0.5, 0.5);
  this.scaler.scaleSpriteByHeight(sprite);
  sprite.depth = this.depth;
  sprite.keepData = {
    resized: true,
    xlocation: 'right',
    designX: designX,
    designY: designY
  };

  sprite.setInteractive();
  sprite.on("pointerdown", pointer => {
    this.closeSprite.alpha = 0.6;
  });
  sprite.on("pointerup", pointer => {
    this.closeSprite.alpha = 1.0;
    this.hide();
    // bgSprite.destroy();
    // assetSprite.destroy();
    // closeSprite.destroy();
  });
  this.group.add(sprite);
  this.closeSprite = sprite;

  this.zoomGroup = this.scene.add.group();

  designX = -143;
  designY = 464;
  sprite = this.scene.add.rectangle(
    this.scaler.scaleX(this.scaler.designRefWidth() + designX),
    this.scaler.scaleY(designY),
    120,
    774,
    0x000000, 0.2);
  sprite.setOrigin(0.5, 0.5);
  this.scaler.scaleSpriteByHeight(sprite);
  sprite.keepData = {
    resized: true,
    xlocation: 'right',
    designX: designX,
    designY: designY
  };
  sprite.depth = this.depth;
  this.group.add(sprite);
  this.zoomGroup.add(sprite);

  designX = -143;
  designY = 464;
  sprite = this.scene.add.rectangle(
    this.scaler.scaleX(this.scaler.designRefWidth() + designX),
    this.scaler.scaleY(designY),
    6,
    480,
    0xFFFFFF, 0.5);
  sprite.setOrigin(0.5, 0.5);
  this.scaler.scaleSpriteByHeight(sprite);
  sprite.keepData = {
    resized: true,
    xlocation: 'right',
    designX: designX,
    designY: designY
  };
  sprite.depth = this.depth;
  this.group.add(sprite);
  this.zoomGroup.add(sprite);

   //zoom in button
   designX = -143;
   designY = 464 + 480/2;
   sprite = this.scene.add.image(
     this.scaler.scaleX(this.scaler.designRefWidth() + designX),
     this.scaler.scaleY(designY),
     'icon_zoom_progress'
   );
   sprite.setOrigin(0.5, 0.5);
   this.scaler.scaleSpriteByHeight(sprite);
   sprite.keepData = {
     resized: true,
     xlocation: 'right',
     designX: designX,
     designY: designY
   };
   sprite.depth = this.depth;
   this.group.add(sprite);
   this.iconZoomProgressSprite = sprite;
   this.zoomGroup.add(sprite);

   sprite.setInteractive({ draggable: true });
   // set drag events
   sprite.on('drag', function (pointer, dragX, dragY) {
    // if(self.scaler.getDirection() == 'Horizontal'){
    //   let offset = dragY - sprite.keepData.y;
    //   (Math.abs(offset) > self.dragMaxOffset) && (self.dragMaxOffset = Math.abs(offset));
    //   self.emit('drag-students', {offset: offset});
    // } else{
    //   let offset = -(dragX - sprite.keepData.x);
    //   (Math.abs(offset) > self.dragMaxOffset) && (self.dragMaxOffset = Math.abs(offset));
    //   self.emit('drag-students', {offset: offset});
    // }
    if(this.scaler.getDirection() == 'Horizontal') {
      this.iconZoomProgressSprite.y = pointer.y;
    } else {
      this.iconZoomProgressSprite.y = this.scaler.width - pointer.x;
    }

    if(this.iconZoomProgressSprite.y <= this.scaler.vScale() * (464 - 480/2)) {
      this.iconZoomProgressSprite.y = this.scaler.vScale() * (464 - 480/2);
    }

    if(this.iconZoomProgressSprite.y >= this.scaler.vScale() * (464 + 480/2)) {
      this.iconZoomProgressSprite.y = this.scaler.vScale() * (464 + 480/2);
    }

    var percent = (this.scaler.scaleY(464 + 480/2) - this.iconZoomProgressSprite.y)/(this.scaler.vScale() * 480);
    percent = percent.toFixed(3);
    this.zoom = 1 + percent *2;
    this.setZoom();
  }, this);

  sprite.on('dragstart', function (pointer, dragX, dragY) {
    //console.log(`drag start.. ${pointer.x} ${pointer.y}`);
    // self.dragMaxOffset = 0;
    // sprite.keepData.y = sprite.y;
    // self.emit('dragstart-students');
  }, this);

  sprite.on('dragend', function (pointer, dragX, dragY) {
    // self.emit('dragend-students');
  }, this);

  //zoom in button
  designX = -143;
  designY = 164.5;
  sprite = this.scene.add.image(
    this.scaler.scaleX(this.scaler.designRefWidth() + designX),
    this.scaler.scaleY(designY),
    'btn_zoom_in'
  );
  sprite.setOrigin(0.5, 0.5);
  this.scaler.scaleSpriteByHeight(sprite);
  sprite.keepData = {
    resized: true,
    xlocation: 'right',
    designX: designX,
    designY: designY
  };
  sprite.depth = this.depth;
  sprite.visible = false;
  this.zoomGroup.add(sprite);

  sprite.setInteractive();
  sprite.on("pointerdown", pointer => {
    this.zoomInSprite.alpha = 0.6;
  });
  sprite.on("pointerup", pointer => {
    this.zoomInSprite.alpha = 1.0;

    // let zoom = this.imageSprite.scaleX;
    this.zoom += 0.2;

    this.setZoom();
  });
  this.group.add(sprite);
  this.zoomInSprite = sprite;

  //zoom out button
  designX = -143;
  designY = 765.5;
  sprite = this.scene.add.image(
    this.scaler.scaleX(this.scaler.designRefWidth() + designX),
    this.scaler.scaleY(designY),
    'btn_zoom_out'
  );
  sprite.setOrigin(0.5, 0.5);
  this.scaler.scaleSpriteByHeight(sprite);
  sprite.depth = this.depth;
  sprite.visible = false;
  sprite.keepData = {
    resized: true,
    xlocation: 'right',
    designX: designX,
    designY: designY
  };
  this.zoomGroup.add(sprite);

  sprite.setInteractive();
  sprite.on("pointerdown", pointer => {
    this.zoomOutSprite.alpha = 0.6;
  });
  sprite.on("pointerup", pointer => {
    this.zoomOutSprite.alpha = 1.0;

    // let zoom = this.imageSprite.scaleX;
    this.zoom -= 0.2;
    this.setZoom();
  });
  this.group.add(sprite);
  this.zoomOutSprite = sprite;

  this.zoomGroup.setVisible(false);
  this.updateZoomButtons();
  // label textf
  // let style = {
  //   font: "32px Arial",
  //   fill: "#ffffff",
  //   align: "center",
  // }

  designX = 0;
  designY = 948;
  sprite = this.scene.add.rectangle(
    this.scaler.scaleX(this.scaler.designRefWidth()/2 + designX),
    this.scaler.scaleY(designY),
    450,
    120,
    0x000000, 0.2);
  sprite.setOrigin(0.5, 0.5);
  this.scaler.scaleSpriteByHeight(sprite);
  sprite.keepData = {
    resized: true,
    xlocation: 'center',
    designX: designX,
    designY: designY
  };
  sprite.depth = this.depth;
  this.group.add(sprite);

  // left button
  designX = -164;
  designY = 948;
  sprite = this.scene.add.image(
    this.scaler.scaleX(this.scaler.designRefWidth()/2 + designX),
    this.scaler.scaleY(designY),
    'btn_left_portfolio'
  );
  sprite.setOrigin(0.5, 0.5);
  this.scaler.scaleSpriteByHeight(sprite);
  sprite.depth = this.depth;
  sprite.keepData = {
    resized: true,
    xlocation: 'center',
    designX: designX,
    designY: designY
  };
 
  sprite.setInteractive();
  sprite.on("pointerdown", pointer => {
    this.leftSprite.alpha = 0.6;
  });
  sprite.on("pointerup", pointer => {
    this.leftSprite.alpha = 1.0;
    this.prev();
    // bgSprite.destroy();
    // assetSprite.destroy();
    // closeSprite.destroy();
  });
  this.group.add(sprite);
  this.leftSprite = sprite;
 
  // right button
  designX = 164;
  designY = 948;
  sprite = this.scene.add.image(
    this.scaler.scaleX(this.scaler.designRefWidth()/2 + designX),
    this.scaler.scaleY(designY),
    'btn_right_portfolio'
  );
  sprite.setOrigin(0.5, 0.5);
  this.scaler.scaleSpriteByHeight(sprite);
  sprite.depth = this.depth;
  sprite.keepData = {
    resized: true,
    xlocation: 'center',
    designX: designX,
    designY: designY
  };
 
  sprite.setInteractive();
  sprite.on("pointerdown", pointer => {
    this.rightSprite.alpha = 0.6;
  });
  sprite.on("pointerup", pointer => {
    this.rightSprite.alpha = 1.0;
    this.next();
    // bgSprite.destroy();
    // assetSprite.destroy();
    // closeSprite.destroy();
  });
  this.group.add(sprite);
  this.rightSprite = sprite;

  designX = 0;
  designY = 948;
  sprite = this.scene.add.bitmapText(
    this.scaler.scaleX(this.scaler.designRefWidth()/2 + designX),
    this.scaler.scaleY(designY),
    'numberPortfolioFont',
    '', 24);
  sprite.setCenterAlign();
  sprite.setOrigin(0, 0.5);
  //this.scaler.scaleSpriteByHeight(closeSprite);
  sprite.depth = this.depth;
  this.group.add(sprite);
  this.labelSprite = sprite;
  sprite.keepData = {
    resized: true,
    xlocation: 'center',
    designX: designX,
    designY: designY
  };

  let style = {
    font: "32px Arial",
    fill: "#ffffff",
    align: "center",
  };
  sprite = this.scene.add.text(
    this.scaler.width,
    this.scaler.height,
    '',
    style);
  sprite.setOrigin(1, 1);
  sprite.depth = this.depth;
  this.group.add(sprite);
  this.videoProgress = sprite;
  this.videoProgress.alpha = 0;

   // video cover sprite
   sprite = this.scene.add.image(0, 0, 'empty');
   sprite.setOrigin(0.5, 0.5);
   sprite.depth = this.depth + 1000;
   this.group.add(sprite);
   this.coverSprite = sprite;

  this.controllerSprites = this.videoController.create();
  
  this.controllerSprites.forEach(sprite => {
    this.group.add(sprite);
  });

  // video play sprite
  sprite = this.scene.add.image(0, 0, 'btn_play');
  sprite.setOrigin(0.5, 0.5);
  this.group.add(sprite);
  sprite.depth = this.depth + 10000;
  sprite.on("pointerdown", pointer => {
    this.playSprite.alpha = 0.6;
  });
  sprite.on("pointerup", pointer => {
    this.playSprite.alpha = 1.0;
    // this.videoSprites[this.current] && this.videoSprites[this.current].play();

    var current = _self.scene.viewer.current.portfolio.current;
    var videoSprites = _self.scene.viewer.current.portfolio.videoSprites;
    var videoSprite = videoSprites[current];

    // videoSprite && videoSprite.setPaused(!videoSprite.isPaused());
    if(videoSprite){
      videoSprite.play();
    }

    this.playSprite.visible = false;
    this.coverSprite.visible = false;

    if(window.videoCannotPlay) {
      this.showVideoMask(videoSprite);
    }
  });
  sprite.setInteractive();
  this.playSprite = sprite;

  // image sprite
  sprite = this.scene.add.image(0, 0, 'empty');
  sprite.setOrigin(0.5, 0.5);
  sprite.depth = this.depth;
  sprite.keepData = {
    x:0,
    y:0
  };
  this.group.add(sprite);
  this.imageSprite = sprite;

  // image drag sprite
  sprite = this.scene.add.image(0, 0, 'empty');
  sprite.setOrigin(0.5, 0.5);
  sprite.setAlpha(0.2);
  sprite.depth = this.depth + 1;
  sprite.keepData = {
    x:0,
    y:0
  };
  sprite.setInteractive({ draggable: true });
  sprite.on('dragstart', function (pointer, dragX, dragY) {
    //console.log(`${this.imageSprite.x} ${this.imageSprite.x}`)
    this.imageSprite.keepData.x = this.imageSprite.x;
    this.imageSprite.keepData.y = this.imageSprite.y;
    this.imageDragSprite.keepData.x = this.imageDragSprite.x;
    this.imageDragSprite.keepData.y = this.imageDragSprite.y;
  }, this);

  sprite.on('drag', function (pointer, dragX, dragY) {
    //console.log(`drag: ${dragX} ${dragX}`)
    let offsetX, offsetY;
    if(this.scaler.getDirection() == 'Horizontal'){
      offsetX = this.imageDragSprite.keepData.x - dragX;
      offsetY = this.imageDragSprite.keepData.y - dragY;
    } else{
      offsetX = this.imageDragSprite.keepData.y - dragY;
      offsetY = dragX - this.imageDragSprite.keepData.x;
    }

    let imageBounds = this.imageSprite.getBounds();
    let target = {
      x: this.imageSprite.keepData.x - offsetX,
      y: this.imageSprite.keepData.y - offsetY
    };

    if (target.x + imageBounds.width * 0.5 < this.displayBounds.left + this.displayBounds.width) {
      //console.log("stop right");
    } else if (target.x - imageBounds.width * 0.5 > this.displayBounds.left) {
      //console.log("stop left");
    } else {
      this.imageSprite.x = target.x;
    }

    if (target.y + imageBounds.height * 0.5 < this.displayBounds.top + this.displayBounds.height) {
      //console.log("stop right");
    } else if (target.y - imageBounds.height * 0.5 > this.displayBounds.top) {
      //console.log("stop left");
    } else {
      this.imageSprite.y = target.y;
    }

    this.cropZoomTexture();

  }, this);

  sprite.on('dragend', function (pointer, dragX, dragY) {
  }, this);

  sprite.on('wheel', function (pointer, deltaX, deltaY, deltaZ) {
    //this.emit('wheel', {offset: deltaY * 0.4});
  }, this);

  this.group.add(sprite);
  this.imageDragSprite = sprite;

  this.hide();
};

Portfolio.prototype.updateZoomButtons = function() {
  if(this.zoom <= 1.0) {
    this.zoomOutSprite.alpha = 0.5;
    this.zoomOutSprite.disableInteractive();
  } else {
    this.zoomOutSprite.alpha = 1;
    this.zoomOutSprite.setInteractive();
  }
  if(this.zoom >= 3.0) {
    this.zoomInSprite.alpha = 0.5;
    this.zoomInSprite.disableInteractive();
  } else {
    this.zoomInSprite.alpha = 1;
    this.zoomInSprite.setInteractive();
  }
};

Portfolio.prototype.setZoom = function() {
  if(this.zoom < 1.0) {
    this.zoom = 1.0;
  }
  if(this.zoom > 3.0) {
    this.zoom = 3.0;
  }
  this.updateZoomButtons();

  this.imageSprite.setScale(this.imageSprite.keepData.initialScale * this.zoom);
  
  // reposition center
  // this.imageSprite.x -= (this.displayBounds.left + this.displayBounds.width * 0.5 - this.imageSprite.x) * (this.zoom - 1);
  // this.imageSprite.y -= (this.displayBounds.top + this.displayBounds.height * 0.5 - this.imageSprite.y) * (this.zoom - 1);
  this.cropZoomTexture();  

  this.iconZoomProgressSprite.y = this.scaler.scaleY(464 + 480/2) - this.scaler.vScale() * 480 * ((this.zoom - 1)/2);
};

Portfolio.prototype.update = function() {
  if(this.videoSprites[this.current]){
    var progress = this.videoSprites[this.current].getProgress() * 100;
    this.videoProgress.setText(progress.toFixed(2) + '%');
  }

  this.checkLoading();

  this.videoController.update();

};

Portfolio.prototype.loadAndOpen = function(key, byGroup) {
  this.current = -1;
  for(let i = 0; i < _.keys(this.items).length; i ++) {
    if (_.keys(this.items)[i] === key) {
      this.current = i;
    }
  }

  if(byGroup) {
    var group = this.items[key].group;
    this.showItems = this.itemsByGroup[group];
    this.current = -1;
    for(let i = 0; i < _.keys(this.showItems).length; i ++) {
      if (_.keys(this.showItems)[i] === key) {
        this.current = i;
      }
    }
  } else {
    this.showItems = this.items;
  }

  this.targetItem = key;

  // if(byGroup) {
  //   var group = this.items[target_item].group;
  //   this.showItems = this.itemsByGroup[group];
  // } else {
  //   this.showItems = this.items;
  // }

  // if(target_item){
  //   this.targetItem = target_item;
  // } else{
  //   var indexs = [];
  //   for(var key in this.showItems){
  //     indexs.push(key.split('__')[1]);
  //   }
  //   var min = Math.min.apply(null, indexs);
  //   this.targetItem = 'item__' + min;
  // }

  let portfolioKeys = [];
  _.keys(this.items).forEach(key => {
    portfolioKeys.push(this.items[key]['texture']);
  });

  let regionImages = this.getMaterials("images");
  Object.keys(regionImages).map(key => {
    if (portfolioKeys.indexOf(key) >= 0){
      this.scene.load.image(key, window.publicPath + regionImages[key]);
    }
  });

  let regionVideos = this.getMaterials("videos");
  Object.keys(regionVideos).map(key => {
    if (portfolioKeys.indexOf(key) >= 0){
      this.scene.load.image(key, window.publicPath + regionVideos[key].replace(/\.(mp4|mov)$/i, '.cover.jpg'));
    }
  });
  // this.scene.load.removeListener('progress')
  // this.scene.load.on('progress', function (progress){
  //   console.log("progress=" + progress);
  // }, this);

  // this.scene.load.removeListener('complete')
  // this.scene.load.on('complete', function (key, type, data) {
  //   // Your handler code
  //   console.log('complete##');

  //   this.open('item__1');
  // }, this);

  if(this.createState == GC.CREATED){
    this.open(key);
  } else{
    this.scene.load.start();
    this.loadState = GC.LOADED;
  }

};

Portfolio.prototype.loadingProgress = function() {
  let total = 0;
  let missing = 0;

  let portfolioKeys = [];
  _.keys(this.showItems).forEach(key => {
    portfolioKeys.push(this.showItems[key]['texture']);
  });

  let regionImages = this.getMaterials("images");
  let regionVideos = this.getMaterials("videos");
  let sources = Object.assign(regionImages, regionVideos);
  Object.keys(sources).map(key => {
    total ++;
    if (portfolioKeys.indexOf(key) < 0) {
      // simply pass it
    } else if (this.scene.textures.get(key).key === '__MISSING') {
      missing ++;
    }
  });

  return (total == 0 ) ? 1.0 : (total - missing) / total;
};

Portfolio.prototype.checkLoading = function() {

  if (this.loadState == GC.LOADED && this.createState == GC.DESTROYED) {
    let progress = this.loadingProgress();
    if (progress >= 1.0) {
      this.open(this.targetItem);
      this.createState = GC.CREATED;
    }
  }
};


Portfolio.prototype.loadVideos = function() {
  var self = this;
  let sprite;
  
  this.videoSprites.forEach(sprite => {
    sprite.destroy();
  });
  this.videoSprites = [];

  for(let i = 0; i < _.keys(this.showItems).length; i ++) {
    let item = this.showItems[_.keys(this.showItems)[i]];
    if (item && item.mimeType.split('/')[0] == 'video'){
      // video sprite
      sprite = this.scene.add.video(0, 0);
      sprite.setOrigin(0.5, 0.5);
      sprite.depth = this.depth;
      this.group.add(sprite);

      sprite.loadURL(window.publicPath + this.videos[item.texture]);
      
      

      sprite.on('created', function(video, width, height){
        let videoSprite = this.videoSprites[this.current];
        let [dw, dh] = this.assetSize(width, height);
        videoSprite.setDisplaySize(dw, dh);
        videoSprite.keepData.videoReady = true;

        videoSprite.setInteractive();

        this.coverSprite.setDisplaySize(dw, dh);

        // left: this.scaler.scaleX(170 + window.sidebarOffset + GC.sidebarWidth),
        // top: this.scaler.scaleX(112),
        // width: this.scaler.scaleX(this.scaler.designRefWidth() - (170 + window.sidebarOffset + GC.sidebarWidth) - (316)),
        // height: this.scaler.scaleY(this.scaler.designRefHeight() - 112 - 244),

        this.videoController.updateBounds(
          this.displayBounds.left + this.displayBounds.width/2,
          this.displayBounds.top + this.displayBounds.height/2,
          dw,
          dh
        );

        var duration = utils.timeFormate(videoSprite.getDuration());
        this.videoController.setDurationText(duration);
        this.videoController.videoControllerResize();
      }, this);

      sprite.on('complete', function(video, width, height){
        let videoSprite = this.videoSprites[this.current];
        this.playSprite.visible = true;
        this.exitFullscreen();
      }, this);
      sprite.keepData = {
        videoReady: false //
      };
      sprite.setPaused(true);

      // sprite.setInteractive();
      sprite.on("pointerdown", pointer => {
        // console.log("video ponter up");
      });
      sprite.on("pointerup", pointer => {
        // console.log("video ponter down");
        if(!window.videoCannotPlay && this.showVideoController) {
          this.videoController.showController();
          return;
        } 

        let videoSprite = this.videoSprites[this.current];

        // console.log(videoSprite.isPaused())
        // videoSprite.setPaused(!videoSprite.isPaused());
        if(videoSprite.isPlaying() == false){
          videoSprite.play();
          this.playSprite.visible = false;
        } else{
          videoSprite.setPaused(true);
          this.playSprite.visible = true;
        }

        this.showVideoMask(videoSprite);

        // videoSprite.stop();
        // we simply close the cover is video played        
      });


      //this.videoSprites[this.current].setPaused(true);
      this.videoSprites[i] = sprite;
    }
  }

  this.videoLoaded = true;
};

Portfolio.prototype.open = function(key, byGroup) {
  // this.current = -1;
  // for(let i = 0; i < _.keys(this.items).length; i ++) {
  //   if (_.keys(this.items)[i] === key) {
  //     this.current = i;
  //   }
  // }

  this.loadVideos();

  if (this.current >= 0) {
    this.currentItem = this.showItems[key];
    this.show();
  }

};

Portfolio.prototype.openAlbums = function() {
  this.loadAndOpen(Object.keys(this.items)[0]);
};

Portfolio.prototype.assetSize = function (assetWidth, assetHeight) {
  let ratio;
  if (this.displayBounds.width/this.displayBounds.height > assetWidth/assetHeight) {
    ratio = assetWidth / assetHeight;
    return [this.displayBounds.height * ratio , this.displayBounds.height];
  } else {
    ratio = assetHeight / assetWidth;
    return [this.displayBounds.width , this.displayBounds.width * ratio];
  }

  return ratio;
};

Portfolio.prototype.show = function() {
  this.group.setVisible(true);
  this.videoController.controllerGroup.setVisible(false);
  this.videoController.loadingSprite.setVisible(false);

  if (this.current == 0) {
    this.leftSprite.visible = false;
  }
  if (this.current == _.keys(this.showItems).length - 1) {
    this.rightSprite.visible = false;
  }

  // disable all by default
  this.imageSprite.visible = false;
  this.imageDragSprite.visible = false;

  this.zoomGroup.setVisible(false);

  _.forEach(this.videoSprites, sprite => {
    sprite && (sprite.visible = false);
  });
  //this.videoSprite.visible = false;
  this.playSprite.visible = false;
  this.coverSprite.visible = false;

  if (this.currentItem && this.currentItem.mimeType.split('/')[0] == 'image'){
    this.imageSprite.visible = true;
    this.imageDragSprite.visible = true;
    this.imageSprite.setTexture(this.currentItem.texture);
    this.imageSprite.setScale(1);

    this.zoomGroup.setVisible(true);
  } else if (this.currentItem && this.currentItem.mimeType.split('/')[0] == 'video'){
    this.videoSprites[this.current] && (this.videoSprites[this.current].visible = true);
    this.playSprite.visible = true;

    this.coverSprite.setTexture(this.currentItem.texture);
    this.coverSprite.visible = true;
    // this.videoProgress.visible = true;

    if(this.videoSprites[this.current]) {
      this.videoController.setVideoSprite(this.videoSprites[this.current]);
    }
  }

  this.resize();
};

Portfolio.prototype.next = function() {
  // this.loadingSprite.setVisible(false);
  
  this.stop();

  if (!this.showItems || _.keys(this.showItems).length < 1) {
    return;
  }

  this.current = (this.current + 1) % _.keys(this.showItems).length;
  this.currentItem = this.showItems[_.keys(this.showItems)[this.current]];
  this.show();
};

Portfolio.prototype.prev = function() {
  // this.loadingSprite.setVisible(false);
  this.stop();

  if (!this.showItems || _.keys(this.showItems).length < 1) {
    return;
  }
  this.current = (this.current - 1);
  if (this.current < 0) {
    this.current = _.keys(this.showItems).length;
  }
  this.currentItem = this.showItems[_.keys(this.showItems)[this.current]];
  this.show();
};

Portfolio.prototype.hide = function() {
  this.stop();
  try {
    this.group.setVisible(false);
  }
  catch(err) {
    console.log(err);
  }
};

Portfolio.prototype.stop = function() {
  if (this.currentItem && this.currentItem.mimeType.split('/')[0] == 'video'){    
    this.videoSprites[this.current].setPaused(true);

    //this.videoSprite.stop();
    /*
    let sprite = this.scene.add.video(0, 0);
    sprite.setOrigin(0.5, 0.5);
    this.group.add(sprite);
    sprite.on('created', function(video, width, height){
      let ratio = this.assetRatio(width, height);
      this.videoSprite.setScale(ratio, ratio);
    }, this);
    this.videoSprite =sprite;
    */

  }
};

Portfolio.prototype.resize = function() {
  let screenWidth = this.scaler.scaleX(this.scaler.designRefWidth());
  let screenHeight = this.scaler.scaleY(this.scaler.designRefHeight());

  this.displayBounds = {
    left: this.scaler.scaleX(170 + window.sidebarOffset + GC.sidebarWidth),
    top: this.scaler.scaleX(112),
    width: this.scaler.scaleX(this.scaler.designRefWidth() - (170 + window.sidebarOffset + GC.sidebarWidth) - (316)),
    height: this.scaler.scaleY(this.scaler.designRefHeight() - 112 - 244),
  };

  //this.bgSprite.setScale(screenWidth, screenHeight);
  this.bgSprite.setScale(screenWidth, screenHeight);

  this.labelSprite.setText('' + (this.current + 1) + ' / ' + _.keys(this.showItems).length);
  this.labelSprite.x = this.scaler.scaleX(this.scaler.designRefWidth()/2 + this.labelSprite.keepData.designX) - this.labelSprite.width/2;

  //this.videoSprite.setScale(1);
  if (this.videoSprites[this.current]) {
    let videoSprite = this.videoSprites[this.current];
    if(videoSprite.keepData.videoReady == true) {
      let [dw, dh] = this.assetSize(
        videoSprite.getBounds().width,
        videoSprite.getBounds().height
      );
      videoSprite.setDisplaySize(dw, dh);
    }
    videoSprite.setPosition(this.displayBounds.left + this.displayBounds.width/2, this.displayBounds.top + this.displayBounds.height/2);

    let [dw, dh] = this.assetSize(
      this.coverSprite.getBounds().width,
      this.coverSprite.getBounds().height
    );
    this.coverSprite.setDisplaySize(dw, dh);
    this.coverSprite.setPosition(this.displayBounds.left + this.displayBounds.width/2, this.displayBounds.top + this.displayBounds.height/2);

    this.videoController.updateBounds(
      this.displayBounds.left + this.displayBounds.width/2,
      this.displayBounds.top + this.displayBounds.height/2,
      dw,
      dh
    );
    this.videoController.videoControllerResize();
    
  } else if (this.imageSprite) {
    // let [dw, dh] = this.assetSize(
    //   this.imageSprite.getBounds().width,
    //   this.imageSprite.getBounds().height
    // );
    this.imageSprite.setScale(1);

    let dw = this.imageSprite.getBounds().width,
        dh = this.imageSprite.getBounds().height;
    this.imageSprite.setDisplaySize(dw, dh);
    //this.imageSprite.setSize(this.displayBounds.width, this.displayBounds.height);
    this.imageSprite.setScale(_.min([this.displayBounds.width/dw, this.displayBounds.height/dh]));
    this.imageSprite.setPosition(this.displayBounds.left + this.displayBounds.width/2, this.displayBounds.top + this.displayBounds.height/2);
    this.imageSprite.keepData.initialScale = this.imageSprite.scale;

    this.imageDragSprite.setPosition(this.displayBounds.left + this.displayBounds.width/2, this.displayBounds.top + this.displayBounds.height/2);
    this.imageDragSprite.setScale(this.displayBounds.width, this.displayBounds.height);
    this.cropZoomTexture();

    this.zoom = 1;
    this.setZoom();
  }


  this.scaler.scaleSpriteByHeight(this.playSprite);
  this.playSprite.setPosition(this.displayBounds.left + this.displayBounds.width/2, this.displayBounds.top + this.displayBounds.height/2);

  // resize all image and videos
  // this.imageSprite.setTexture(this.currentItem.texture);
  // let ratio = this.assetRatio(
  //   this.imageSprite.getBounds().width,
  //   this.imageSprite.getBounds().height
  // );
  // this.imageSprite.setScale(ratio, ratio);
};

Portfolio.prototype.isEmpty = function() {
  if(this.items && JSON.stringify(this.items) !== '{}'){
    return false;
  } else {
    return true;
  }
};

Portfolio.prototype.updatePortfolioTexture = function() {
  var image = this.scene.textures.get(this.currentItem.texture).getSourceImage();

  this.zoomTexture.setSize(this.zoomTextureWidth, this.zoomTextureHeight);
  var ctx = this.zoomTexture.getContext('2d');
  var width = image.width * this.zoom;
  var height = image.height * this.zoom;

  var sx = (width - image.width)/2 / this.zoom;
  var sy = 0;//(height - image.height)/2 / this.zoom;
  var swidth;
  var sheight;

  var dx, dy;
  var drawWidth;
  var drawHeight;
  if(width > height){
    swidth = image.width / this.zoom;
    sheight = height;//swidth * height/width;


    drawWidth = this.zoomTextureWidth;
    drawHeight = image.height / this.zoom;

    dx = 0;
    dy = (this.zoomTextureHeight - drawHeight)/2;
    // sy += (this.zoomTextureHeight - drawHeight)/2;
  } else{
    sheight = image.height;
    swidth = sheight * width/height;

    // drawHeight = this.zoomTextureHeight;
    // drawWidth = drawHeight * width/height;
    // sx += (this.zoomTextureWidth - drawWidth)/2;
  }

  // var swidth = this.zoomTextureWidth * this.zoom;
  // var sheight = this.zoomTextureHeight * this.zoom;
  // var sx = (this.zoomTextureWidth - swidth)/2;
  // var sy = (this.zoomTextureHeight - sheight)/2;

  // var swidth = image.width;
  // var sheight = image.height;
  // var sx =

  this.zoomTexture.clear();
  ctx.drawImage(image, sx, sy, swidth, sheight, dx, dy, drawWidth, drawHeight);

  this.imageSprite.setTexture('canvastexture');
};

Portfolio.prototype.cropZoomTexture = function() {

  // reposition it if necessay
  let [dw, dh] = this.assetSize(
    this.imageSprite.getBounds().width,
    this.imageSprite.getBounds().height
  );

  let zoom = this.imageSprite.scaleX;
  let imageBounds = this.imageSprite.getBounds(); // need check

  let rightMargin = (this.displayBounds.left + this.displayBounds.width) - (imageBounds.left + imageBounds.width);
  let leftMargin = (imageBounds.left - this.displayBounds.left);
  let bottomMargin = (this.displayBounds.top + this.displayBounds.height) - (imageBounds.top + imageBounds.height);
  let topMargin = (imageBounds.top - this.displayBounds.top);

  if (rightMargin > 0 && leftMargin > 0) {
    // center it
    this.imageSprite.x += (rightMargin - leftMargin) * 0.5;
  } else if (rightMargin > 0) {
    this.imageSprite.x += (rightMargin - leftMargin) * 0.5;
  } else if (leftMargin > 0) {
    this.imageSprite.x -= (leftMargin - rightMargin) * 0.5;
  }

  if (bottomMargin > 0 && topMargin > 0) {
    // center it
    this.imageSprite.y += (bottomMargin - topMargin) * 0.5;
  } else if (bottomMargin > 0) {
    this.imageSprite.y += (bottomMargin - topMargin) * 0.5;
  } else if (topMargin > 0) {
    this.imageSprite.y -= (topMargin - bottomMargin) * 0.5;
  }


  // recalculate image boundary for crop
  imageBounds = this.imageSprite.getBounds();
  let offsetX = (this.displayBounds.left - imageBounds.left)/zoom;
  let offsetY = (this.displayBounds.top - imageBounds.top)/zoom;
  let cropWidth = this.displayBounds.width/zoom;
  let cropHeight = this.displayBounds.height/zoom;

  this.imageSprite.setCrop(offsetX, offsetY, cropWidth, cropHeight);

};

Portfolio.prototype.getMaterials = function(key) {
  let materials = {};
  if(this.materials && this.materials[key])
  materials = this.materials[key];

  return materials;
};

Portfolio.prototype.fullscreen = function () {
  console.log('FULLSCREEN PORTFOLIO.');
  document.getElementById('portfolio-video-container').style.display = 'none';
};

Portfolio.prototype.exitFullscreen = function () {
  console.log('Exit FULLSCREEN PORTFOLIO.');
  var current = _self.scene.viewer.current.portfolio.current;
  var videoSprites = _self.scene.viewer.current.portfolio.videoSprites;
  var videoSprite = videoSprites[current];
  if(videoSprite && videoSprite.isPlaying() == true){
    videoSprite.setPaused(true);
  }
  this.coverSprite.visible = true;
  _self.scene.viewer.current.portfolio.playSprite.visible = true;
  document.getElementById('portfolio-video-container').style.display = 'none';
};

Portfolio.prototype.showVideoMask = function (videoSprite) {
  var self = this;
  if(videoSprite) {
    videoSprite.video.removeEventListener('x5videoenterfullscreen', this.fullscreen, false);
    videoSprite.video.addEventListener('x5videoenterfullscreen', this.fullscreen, false);

    videoSprite.video.removeEventListener('x5videoexitfullscreen', this.exitFullscreen, false);
    videoSprite.video.addEventListener('x5videoexitfullscreen', this.exitFullscreen, false);

    document.getElementById('btn-close-portfolio').onclick = function(){
      self.exitFullscreen();
    };

    if(window.videoCannotPlay) {
      this.openVideoContainer(videoSprite);
    }
  }
};

Portfolio.prototype.openVideoContainer = function (videoSprite) {
  var parent = document.getElementById('portfolio-video-container');
  var children = parent.getElementsByTagName('video');
  for(var m = children.length - 1; m >= 0; m -- ){
    parent.removeChild(children[m]);
  }
  parent.appendChild(videoSprite.video);
  parent.style.display = 'block';
  videoSprite.video.style.width = '100%';
  videoSprite.video.style.height = '100%';
  videoSprite.video.style.margin = 'auto';
  videoSprite.video.style.position = 'absolute';
  videoSprite.video.style.top = '0px';
  videoSprite.video.style.bottom = '0px';
  videoSprite.video.controls = true;
};

Portfolio.prototype.compare = function (x, y) {//比较函数
  if (x < y) {
      return -1;
  } else if (x > y) {
      return 1;
  } else {
      return 0;
  }
};

export default Portfolio;
