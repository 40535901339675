import BuildStageConfig from "./config/build-stage-config";
import MD5 from "crypto-js/md5";

import shareIconImg from '../images/share_icon.jpg';

import galleryac0b from "../dataset/galleries-ac0b.txt";
import gallery5f91 from "../dataset/galleries-5f91.txt";
let galleries = {
  'ac0b': galleryac0b,
  '5f91': gallery5f91
}

function normalizePath(path) {
  if (!path || path.length < 1) {
    return '';
  }

  let normalized = path;
  while(normalized.length > 0 && normalized[normalized.length - 1] === '/') {
    normalized = normalized.slice(0, normalized.length - 1);
  }
  return normalized;
}

let archivedUrl = BuildStageConfig.archivedUrl
if (archivedUrl.startsWith('///')) {
  archivedUrl = window.location.protocol + archivedUrl.substr(1)
}
let shortId = MD5(normalizePath(archivedUrl)).toString().substr(0, 4)

var galleriesMaps = {
  // 'https://exhibition.ad.tsinghua.edu.cn': gallery18df,
  // 'http://exhibition-origin.ad.onekind.com.cn': galleryd9db,
  // 'http://exhibition-staging.ad.onekind.com.cn': gallery39c6,
  'default': galleries[shortId]
};

function Utils() {

};

Utils.prototype.uploadPhoto = function(data) {
  $.ajax({
    url: '',
    type: "POST",
    async: true,
    data: data,
    success: function(data) {
      
    }, error : function(response) {
      console.log(JSON.stringify(response));
    }
  });
};

Utils.prototype.fetchRegions = function() {
  let jsonData = galleriesMaps[window.location.origin] || galleriesMaps['default'];
  $.ajax({
    //url: 'http://exhibition-staging.ad.onekind.com.cn/galleries',
    url: jsonData,
    type: "GET",
    async: true,
    success: function(data) {
      // console.log('GET IMAGES SUCCESS!');
      // console.log(data);
      window.custom.systemList = (typeof data === 'string') ? JSON.parse(data) : data;

    }, error : function(response) {
      console.log("GET IMAGES FAILED!");
      console.log(JSON.stringify(response));
    }
  });
};

Utils.prototype.setCookie = function(name,value)
{
  var Days = 30;
  var exp = new Date();
  exp.setTime(exp.getTime() + Days*24*60*60*1000);
  // console.log(name + '..' + decodeURIComponent(value));
  document.cookie = name + "="+ escape (value) + ";expires=" + exp.toGMTString();
};

Utils.prototype.getCookie = function(name)
{
  var arr,reg=new RegExp("(^| )"+name+"=([^;]*)(;|$)");

  if(arr = document.cookie.match(reg))

    return unescape(arr[2]);
  else
    return null;
};

Utils.prototype.delCookie = function(name)
{
  var exp = new Date();
  exp.setTime(exp.getTime() - 1);
  var cval = this.getCookie(name);
  if(cval != null)
    document.cookie= name + "="+cval+";expires="+exp.toGMTString();
};

Utils.prototype.isIphone = function(){
  return /iphone/gi.test(navigator.userAgent);
};

Utils.prototype.isIphoneXORIphone11Wechat = function(){
  if (typeof window !== 'undefined' && window) {
    return /iphone/gi.test(navigator.userAgent) && /micromessenger/gi.test(window.navigator.userAgent) && window.screen.height >= 812;
  }
  return false;
};

Utils.prototype.isWechat = function(){
  if (typeof window !== 'undefined' && window) {
    return /micromessenger/gi.test(window.navigator.userAgent);
  }
  return false;
};

Utils.prototype.getUrlParam = function(name) {
  var reg = new RegExp("(^|&)" + name + "=([^&]*)(&|$)");
  var r = window.location.search.substr(1).match(reg);
  if (r != null) return unescape(r[2]);
  return null;
};

Utils.prototype.wechatShare = function(){
  let url = window.location.href;

  let configUrl = url.replace(/ee-expo.tsinghua-ieit.+/,'ee-expo.tsinghua-ieit.com/ws/wxconfig?url=' + document.location.href);

  let sharedPicUrl = window.location.origin + '/' + shareIconImg;
  let sharedPageUrl = 'https://ee-expo.tsinghua-ieit.com/';

  // console.log(configUrl);
  // console.log(sharedPageUrl);
  // console.log(sharedPicUrl);

  $.getJSON(configUrl,function(config){
    console.log('wechat share..');
    // console.log(config);
    // alert (data.result);
    // alert(JSON.stringify(config));

    config.debug = false;
    config.jsApiList = [
      'updateTimelineShareData',
      'updateAppMessageShareData',
      'onMenuShareTimeline',
      'onMenuShareAppMessage'
    ];


    // console.log('config....');
    // console.log(JSON.stringify(config));
    window.wx = wx;
    wx.config(config);

    wx.ready(function(){

      wx.updateTimelineShareData && wx.updateTimelineShareData({
        title: '清华大学电子工程系70周年系庆科研展线上展厅',
        link: sharedPageUrl,
        imgUrl: sharedPicUrl,
        success: function () {
        },
        cancel: function () {
        },
        complete: function(res){
        }
      });

      wx.updateAppMessageShareData && wx.updateAppMessageShareData({
        title: '清华大学电子工程系70周年系庆科研展线上展厅',
        desc: '努力从“跟随者”变成“领跑者”',
        link: sharedPageUrl,
        imgUrl: sharedPicUrl,
        success: function () {
        },
        cancel: function () {
        },
        complete: function(res){
        }
      });

      // 即将废弃
      wx.onMenuShareTimeline && wx.onMenuShareTimeline({
        title: '清华大学电子工程系70周年系庆科研展线上展厅',
        link: sharedPageUrl,
        imgUrl: sharedPicUrl,
        success: function () {
        },
        cancel: function () {
        },
        complete: function(res){
        }
      });

      // 即将废弃
      wx.onMenuShareAppMessage && wx.onMenuShareAppMessage({
        title: '清华大学电子工程系70周年系庆科研展线上展厅',
        link: sharedPageUrl,
        imgUrl: sharedPicUrl,
        desc: '努力从“跟随者”变成“领跑者”',
        type: '',
        dataUrl: '',
        success: function () {
        },
        cancel: function () {
        },
        complete: function(res){
        }
      });

    });

    wx.error(function(res){
      console.log('error!!');
      console.log(res);
    });
  });
};

Utils.prototype.getVideoColor = function(video){
  var r= 0, g = 0, b = 0;
  if(video.width != undefined && video.width > 0 && video.height != undefined && video.height > 0){
      var canvas = video.snapshot().canvas;
      var context = canvas.getContext("2d");

      // 获取像素数据
      // var data = context.getImageData(0, 0, canvas.width, canvas.height).data;
      var width = 40;
      var height = 40;
      var data = context.getImageData((video.width - width)/2, (video.height - height)/2, width, height).data;

      // 取所有像素的平均值
      for (var row = 0; row < width; row++) {
          for (var col = 0; col < height; col++) {
              r += data[((width * row) + col) * 4];
              g += data[((width * row) + col) * 4 + 1];
              b += data[((width * row) + col) * 4 + 2];
          }
      }

      // 求取平均值
      r /= (width * height);
      g /= (width * height);
      b /= (width * height);

      // 将最终的值取整
      r = Math.round(r);
      g = Math.round(g);
      b = Math.round(b);
  }
  return {r: r, g: g, b: b};
};

Utils.prototype.timeFormate = function (timeInSeconds) {
  let result = parseInt(timeInSeconds);
  let h = Math.floor(result / 3600) < 10 ? '0' + Math.floor(result / 3600) : Math.floor(result / 3600);
  let m = Math.floor((result / 60 % 60)) < 10 ? '0' + Math.floor((result / 60 % 60)) : Math.floor((result / 60 % 60));
  let s = Math.floor((result % 60)) < 10 ? '0' + Math.floor((result % 60)) : Math.floor((result % 60));

  let res = '';
  if(h !== '00') res += `${h}:`;
  res += `${m}:`;
  res += `${s}`;
  return res;
};

Utils.prototype.slideAimation = function(scene, direction, func) {
  let startX, targetX;
  let duration = 500;
  if(direction == 'in') {
    startX = scene.scaler.scaleBgX(0, 'right');
    targetX = 0;
  }
  if(direction == 'out') {
    startX = 0;
    targetX = -scene.scaler.scaleBgX(0, 'right');
  }
  if(direction == 'back') {
    startX = 0;
    targetX = scene.scaler.scaleBgX(0, 'right');
  }
  if(direction == 'leftIn') {
    startX = -scene.scaler.scaleBgX(0, 'right');
    targetX = 0;
  }


  // var ease = 'Linear';
  var ease = 'Sine.easeInOut';
  if(scene.scaler.getDirection() == 'Horizontal') {
    scene.tweens.add({
      targets: scene.cameras.main,
      x: {
        start: startX,
        to: targetX
      },
      duration: duration,
      delay: 0,
      ease: ease,
      repeat: 0,
      yoyo: false,
      onComplete: function () {
        if(func) {
          func();
        }
      },
    });
  } else {
    scene.tweens.add({
      targets: scene.cameras.main,
      y: {
        start: startX,
        to: targetX
      },
      duration: duration,
      delay: 0,
      ease: ease,
      repeat: 0,
      yoyo: false,
      onComplete: function () {
        if(func) {
          func();
        }
      },
    });
  }

};

Utils.prototype.slideAimationVertical = function(scene, direction, func) {
  let startY, targetY;
  let duration = 500;

  // var ease = 'Linear';
  var ease = 'Sine.easeInOut';
  if(scene.scaler.getDirection() == 'Horizontal') {
    if(direction == 'bottomOut') {
      startY = 0;
      targetY = scene.scaler.scaleY(scene.scaler.designRefHeight());
    }
    if(direction == 'bottomIn') {
      startY = scene.scaler.scaleY(scene.scaler.designRefHeight());
      targetY = 0;
    }
    scene.tweens.add({
      targets: scene.cameras.main,
      y: {
        start: startY,
        to: targetY
      },
      duration: duration,
      delay: 0,
      ease: ease,
      repeat: 0,
      yoyo: false,
      onComplete: function () {
        if(func) {
          func();
        }
      },
    });
  } else {
    if(direction == 'bottomOut') {
      startY = 0;
      targetY = -scene.scaler.scaleY(scene.scaler.designRefHeight());
    }
    if(direction == 'bottomIn') {
      startY = -scene.scaler.scaleY(scene.scaler.designRefHeight());
      targetY = 0;
    }
    scene.tweens.add({
      targets: scene.cameras.main,
      x: {
        start: startY,
        to: targetY
      },
      duration: duration,
      delay: 0,
      ease: ease,
      repeat: 0,
      yoyo: false,
      onComplete: function () {
        if(func) {
          func();
        }
      },
    });
  }

};

Utils.prototype.sceneFadeInAnimation = function(scene, func) {
  var duration = 500;
  scene.tweens.add({
    targets: scene.cameras.main,
    alpha: {
      start: 0,
      to: 1
    },
    duration: duration,
    delay: 0,
    ease:'Linear',
    repeat: 0,
    yoyo: false,
    onComplete: function () {
      if(func) {
        func();
      }
    },
  });
},

Utils.prototype.sceneFadeOutAnimation = function(scene, func) {
  var duration = 500;
  scene.tweens.add({
    targets: scene.cameras.main,
    alpha: {
      start: 1,
      to: 0
    },
    duration: duration,
    delay: 0,
    ease:'Linear',
    repeat: 0,
    yoyo: false,
    onComplete: function () {
      if(func) {
        func();
      }
    },
  });
},

Utils.prototype.resized = function(scene) {
  var phaserElements = scene.children.list;
  for(var i = 0; i < phaserElements.length; i ++){
    var s = phaserElements[i];
    if(s.keepData && s.keepData.resized == true){
      if(s.keepData.offset == 'width') {
        s.keepData.width = s.keepData.initialWidth + window.sidebarOffset;
      }
      if(s.keepData.offset == 'x') {
        s.keepData.designX = s.keepData.initialX + window.sidebarOffset;
      }

      if(s.keepData.xlocation && s.keepData.xlocation == 'right'){
        s.x = scene.scaler.scaleBgX(s.keepData.designX, 'right');
      } else if(s.keepData.xlocation && s.keepData.xlocation == 'center'){
        s.x = scene.scaler.scaleBgX(s.keepData.designX, 'center');
      } else {
        s.x = scene.scaler.scaleBgX(s.keepData.designX);
      }

      if(s.keepData.scaleBgY) {
        if(s.keepData.ylocation && s.keepData.ylocation == 'bottom') {
          s.y = scene.scaler.scaleBgY(s.keepData.designY, 'bottom');
        } else if(s.keepData.ylocation && s.keepData.ylocation == 'center'){
          s.y = scene.scaler.scaleBgY(s.keepData.designY, 'center');
        } else{
          s.y = scene.scaler.scaleBgY(s.keepData.designY);
        }
      } else {
        if(s.keepData.ylocation && s.keepData.ylocation == 'bottom') {
          s.y = scene.scaler.scaleY(scene.scaler.designRefHeight() + s.keepData.designY);
        } else if(s.keepData.ylocation && s.keepData.ylocation == 'center'){
          s.y = scene.scaler.scaleY(scene.scaler.designRefHeight()/2 + s.keepData.designY);
        } else{
          s.y = scene.scaler.scaleY(s.keepData.designY);
        }
      }

      if(s.keepData.fill){
        scene.scaler.scaleSpriteFill(s);
      } else if(s.keepData.scaleByMax){
        scene.scaler.scaleSpriteByMax(s);
      } else if(s.keepData.scaleFillHeight) {
        scene.scaler.scaleSpriteFillHeight(s);
      } else if(s.keepData.scaleFillWidth) {
        scene.scaler.scaleSpriteFillWidth(s);
      } else if(s.keepData.scaleSpriteByMin) {
        scene.scaler.scaleSpriteByMin(s);
      } else {
        scene.scaler.scaleSprite(s);
      }

      if(s.keepData.initialScale) {
        s.keepData.initialScale = s.scale;
      }

      if(s.keepData.scale){
        s.scale *= s.keepData.scale;
      }

      if(s.keepData.width){
        s.displayWidth = s.keepData.width * scene.scaler.hScale();
      }

      if(s.keepData.height){
        s.displayHeight = s.keepData.height * scene.scaler.vScale();
      }
    }
  }
};

Utils.prototype.updateButtonsAlpha = function(scene) {
  var phaserElements = scene.children.list;
  phaserElements.forEach(sprite => {
    if(sprite.keepData && sprite.keepData.enableClick) {
      if(sprite.keepData.alpha != undefined) {
        sprite.alpha = sprite.keepData.alpha;
      } else {
        sprite.alpha = 1;
      }
      sprite.isPressed =  false;
    }
  });
};

Utils.prototype.disableResized = function(scene) {
  var phaserElements = scene.children.list;
  phaserElements.forEach(sprite => {
    if(sprite.keepData) {
      sprite.keepData.resized = false;
    }
  });
};

Utils.prototype.enableResized = function(scene) {
  var phaserElements = scene.children.list;
  phaserElements.forEach(sprite => {
    if(sprite.keepData) {
      sprite.keepData.resized = true;
    }
  });
};

Utils.prototype.enableAllButtons = function(scene) {
  var phaserElements = scene.children.list;
  phaserElements.forEach(sprite => {
    if(sprite.keepData && sprite.keepData.enableClick) {
      sprite.setInteractive();
    }
  });
};

Utils.prototype.disableAllButtons = function(scene) {
  var phaserElements = scene.children.list;
  phaserElements.forEach(sprite => {
    if(sprite.keepData && sprite.keepData.enableClick) {
      sprite.disableInteractive();
    }
  });
};

Utils.prototype.toGallery = function(scene, galleryId, noAnimation, lastScene) {
  const self = this;
  window.custom.selected.galleryId = galleryId;
  
  scene.launch('sceneSideBar', {
    noAnimation: noAnimation,
    galleryId: galleryId,
    lastScene: lastScene,
  });

  scene.get('sceneLoading').setLoadingProgress(0);
  if(noAnimation) {
    this.toMain(scene, galleryId);
  } else {

    this.sceneFadeInAnimation(scene.get('sceneLoading'), function() {
      if(scene.key == 'sceneDetail') {
        scene.setVisible(false);
      }
      self.toMain(scene, galleryId);
    });
  }
};

Utils.prototype.toMain = function(scene, galleryId) {
  // 进入长廊
  var systemList = window.custom.systemList;

  let latest = '';
  var code = GC.galleryMap[galleryId];
  latest = systemList[code].latest

  if(latest === '' && systemList[Object.keys(systemList)[0]]) {
    latest = systemList[Object.keys(systemList)[0]].latest
  }

  $.ajax({
    url: latest,
    type: "GET",
    async: true,
    success: function(data) {
      console.log('GET IMAGES SUCCESS!');
      let regionsRaw = data.regions;

      window.publicPath = data.publicPath;
      window.theme = data.theme;
      // self.getSystem(data.label);

      //remove avatar
      // regionsRaw.forEach(region => {
      //   if(region.meta.avatar && region.meta.avatar.texture) {
      //     for(var texture in region.preloadMaterials.images) {
      //       if(texture == region.meta.avatar.texture) {
      //         delete region.preloadMaterials.images[texture]
      //       }
      //     }
      //   }

      // });

      utils.setCookie('selected', encodeURIComponent(JSON.stringify(window.custom.selected)));
      if(window.custom.locator == null){
        utils.setCookie('locator', regionsRaw[0].meta.locator);
      }

      scene.launch('sceneMain', {
        regions: regionsRaw,
        theme: window.theme,
        multiple: 'multiple'
      });
      console.log('TO MAIN SCENE!!!');
    }, error : function(response) {
      console.log("GET IMAGES FAILED!");
      console.log(JSON.stringify(response));
    }
  });
};

export default Utils;
