
var EventEmitter = require('events').EventEmitter;

function HotspotSprite(scene, scaler) {
  this.scene = scene;
  this.scaler = scaler;
  this.dragMaxOffset;
};

HotspotSprite.prototype.__proto__ = EventEmitter.prototype;

HotspotSprite.prototype.setTarget = function (target) {
  this.target = target;
};

HotspotSprite.prototype.setPortfolio = function (portfolio) {
  this.portfolio = portfolio;
};

HotspotSprite.prototype.setVideos = function (videos) {
  this.videos = videos;
};

HotspotSprite.prototype.setPosition = function (position) {
  this.position = position;
};

HotspotSprite.prototype.setGroup = function (group) {
  this.group = group;
};

HotspotSprite.prototype.setDepth = function (depth) {
  this.depth = depth;
};

HotspotSprite.prototype.addToGroup = function (group) {
  return group.add(this.sprite);
};

HotspotSprite.prototype.getDragSprite = function () {
  return this.sprite;
};

HotspotSprite.prototype.preload = function () {
};


HotspotSprite.prototype.create = function () {
  let designX = this.position.x;
  let designY = this.position.y;

  let sprite = this.scene.add.image(
    this.scaler.scaleX(designX),
    this.scaler.scaleY(designY),
    'empty'
  );
  sprite.setOrigin(0.5, 0.5);
  sprite.keepData = {
    resized: true,
    designX: designX,
    designY: designY,
    x: sprite.x,
    y: sprite.y
  };
  sprite.depth = this.depth;
  this.group.add(sprite);

  this.sprite = sprite;

  this.sprite.setInteractive({ draggable: true });

  this.sprite.on("pointerdown", pointer => {
    //console.log("HotspotSprite point down");
  }, this);

  this.sprite.on("pointerup", pointer => {
    //console.log("HotspotSprite point up " + this.dragMaxOffset);
    (this.dragMaxOffset < 5) && (this.portfolio.loadAndOpen(this.target.portfolio, true));
  }, this);

  // set drag events
  this.sprite.on('drag', function (pointer, dragX, dragY) {
    // console.log("draggin" + dragX + ',' + dragY);
    if(this.scaler.getDirection() == 'Horizontal'){
      let offset = dragX - this.sprite.keepData.x;
      (Math.abs(offset) > this.dragMaxOffset) && (this.dragMaxOffset = Math.abs(offset));
      this.emit('drag', {offset: offset});
    } else{

      let offset = dragY - this.sprite.keepData.y;
      (Math.abs(offset) > this.dragMaxOffset) && (this.dragMaxOffset = Math.abs(offset));
      this.emit('drag', {offset: offset});
    }
  }, this);

  this.sprite.on('dragstart', function (pointer, dragX, dragY) {
    //console.log(`drag start.. ${pointer.x} ${pointer.y}`);
    this.dragMaxOffset = 0;
    this.sprite.keepData.x = this.sprite.x;
    this.sprite.keepData.y = this.sprite.y;
    this.emit('dragstart');
  }, this);

  this.sprite.on('dragend', function (pointer, dragX, dragY) {
    this.emit('dragend');
  }, this);

  this.sprite.on('wheel', function (pointer, deltaX, deltaY, deltaZ) {
    this.emit('wheel', {offset: deltaY * 0.4});
  }, this);

  this.scene.tweens.add({
    targets: this.sprite,
    alpha: 0.1,
    ease: 'Power1',
    duration: 1000,
    delay: Math.random() * 1000,
    yoyo: true,
    repeat: -1
  });

  return this.sprite;
};

HotspotSprite.prototype.loadTextures = function () {
  this.sprite.setTexture('hotspot');
  this.scaler.scaleSpriteByHeight(this.sprite);
  let bounds = this.sprite.getBounds();
  this.sprite.input.hitArea.setSize(bounds.width, bounds.height);
};

HotspotSprite.prototype.unloadTextures = function () {
  console.log("Unload hotspot sprite");
  this.sprite.setTexture('empty');
};

export default HotspotSprite;
