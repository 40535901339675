var EventEmitter = require('events').EventEmitter;

function SlideSprite(scene, scaler) {
  this.scene = scene;
  this.scaler = scaler;
  this.textures = [];
  this.position = {};

  this.params = [];

  this.current = -1;
  this.depth = 0;

  this.dragMaxOffset;
};

SlideSprite.prototype.__proto__ = EventEmitter.prototype;

SlideSprite.prototype.setTextures = function (slides) {
  // parse texture and hotspot
  _.forEach(slides, slide => {
    if (slide.texture && slide.texture.length > 0) {
      this.textures.push(slide.texture);
    } else {
      // use empty texture
      this.textures.push('empty');
    }
  });
};

SlideSprite.prototype.setParams = function (customization) {
  let index = 0;
  _.keys(customization).forEach(key => {
    let sections = key.split("__");
    if (sections && sections.length > 1 && sections[0] == 'slide') {
      //let index = parseInt(sections[1]);
      this.params[index++] = JSON.parse(JSON.stringify(customization[key]));
    }
  });
};

SlideSprite.prototype.setDepth = function (depth) {
  this.depth = depth;
};

SlideSprite.prototype.setPosition = function (point) {
  this.position.x = point.x;
  this.position.y = point.y;
};

SlideSprite.prototype.setGroup = function (group) {
  this.group = group;
};

SlideSprite.prototype.getDragSprite = function (depth) {
  return this.sprite;
};

// interface for phaser

SlideSprite.prototype.preload = function () {
};


SlideSprite.prototype.create = function () {

  let designX = this.position.x;
  let designY = this.position.y;
  let sprite = this.scene.add.image(
    this.scaler.scaleX(designX),
    this.scaler.scaleY(designY),
    'empty'
  );
  sprite.setOrigin(0.5, 1.0);
  this.scaler.scaleSpriteByHeight(sprite);
  sprite.keepData = {
    resized: true,
    designX: designX,
    designY: designY,
    x: sprite.x
  };
  sprite.depth = this.depth;
  this.group.add(sprite);
  this.sprite = sprite;

  // hotspot sprite
  sprite = this.scene.add.image(
    this.scaler.scaleX(designX),
    this.scaler.scaleY(designY),
    'empty'
  );
  sprite.setOrigin(0.5, 0.5);
  this.scaler.scaleSpriteByHeight(sprite);
  sprite.keepData = {
    resized: true,
    designX: designX,
    designY: designY,
    x: sprite.x
  };
  sprite.depth = this.depth;
  sprite.visible = false;  // by default we hide it
  this.group.add(sprite);
  this.hotspotSprite = sprite;

  this.enableHotspots();

  this.repositionBy(0);

  return [this.sprite, this.hotspotSprite];
};

SlideSprite.prototype.enableHotspots = function() {
  this.hotspotSprite.setInteractive({ draggable: true });

  this.hotspotSprite.on("pointerdown", pointer => {
    //console.log("SlideSprite point down");
  }, this);

  this.hotspotSprite.on("pointerup", pointer => {
    //console.log("SlideSprite point up " + this.dragMaxOffset);
    (this.dragMaxOffset < 5) && (this.next());
  }, this);

  // set drag events
  this.hotspotSprite.on('drag', function (pointer, dragX, dragY) {
    //console.log("draggin" + dragX);
    let offset = dragX - this.hotspotSprite.keepData.x;
    (Math.abs(offset) > this.dragMaxOffset) && (this.dragMaxOffset = Math.abs(offset));
    this.emit('drag', {offset: offset});
  }, this);

  this.hotspotSprite.on('dragstart', function (pointer, dragX, dragY) {
    //console.log(`drag start.. ${pointer.x} ${pointer.y}`);
    this.dragMaxOffset = 0;
    this.hotspotSprite.keepData.x = this.hotspotSprite.x;
    this.emit('dragstart');
  }, this);

  this.hotspotSprite.on('dragend', function (pointer, dragX, dragY) {
    this.emit('dragend');
  }, this);

  this.scene.tweens.add({
    targets: this.hotspotSprite,
    alpha: 0.1,
    ease: 'Power1',
    duration: 500,
    delay: Math.random() * 1000,
    yoyo: true,
    repeat: -1
  });

};

// position the slide by given frame
SlideSprite.prototype.repositionBy = function(index) {
  if (this.params.length < 1) {
   return;
  }
  let to = index % this.params.length;

  let designX = this.position.x + this.params[to].position.x;
  let designY = this.position.y - this.params[to].position.y;
  this.sprite.x = this.scaler.scaleX(designX);
  this.sprite.y = this.scaler.scaleY(designY);
  this.sprite.keepData.designX = designX;
  this.sprite.keepData.designY = designY;

  designX = this.position.x + this.params[to].hotspot.x;
  designY = this.position.y - this.params[to].hotspot.y;
  this.hotspotSprite.x = this.scaler.scaleX(designX);
  this.hotspotSprite.y = this.scaler.scaleY(designY);
  this.hotspotSprite.keepData.designX = designX;
  this.hotspotSprite.keepData.designY = designY;

  // we need switch current also
  this.current = to;
};

SlideSprite.prototype.switchTo = function(index) {
  if (this.params.length < 1) {
   return;
  }
  let to = index % this.params.length;

  let deltaDesignX = this.params[to].position.x - this.params[this.current].position.x;
  let deltaDesignY = this.params[to].position.y - this.params[this.current].position.y;
  this.sprite.keepData.designX += deltaDesignX;
  this.sprite.keepData.designY += deltaDesignY;
  this.sprite.x += this.scaler.scaleX(deltaDesignX);
  this.sprite.y += this.scaler.scaleY(deltaDesignY);

  let texture = this.textures[to];
  this.sprite.setTexture(texture);
  this.hotspotSprite.visible = true;

  let bounds = this.sprite.getBounds();
  //this.sprite.input.hitArea.setSize(bounds.width, bounds.height);
  //console.log(this.params[to]);
  deltaDesignX = this.params[to].hotspot.x - this.params[this.current].hotspot.x;
  deltaDesignY = (this.params[to].hotspot.y - this.params[this.current].hotspot.y) * -1; // we have different view
  this.hotspotSprite.keepData.designX += deltaDesignX;
  this.hotspotSprite.keepData.designY += deltaDesignY;
  this.hotspotSprite.x += this.scaler.scaleX(deltaDesignX);
  this.hotspotSprite.y += this.scaler.scaleY(deltaDesignY);

  this.current = to;
};


SlideSprite.prototype.next = function() {
  this.switchTo(this.current + 1);
};

SlideSprite.prototype.loadTextures = function () {
  this.hotspotSprite.setTexture('hotspot_slide');
  this.scaler.scaleSpriteByHeight(this.hotspotSprite);
  let bounds = this.hotspotSprite.getBounds();
  this.hotspotSprite.input.hitArea.setSize(bounds.width, bounds.height);

  //this.sprite.setTexture(this.textures[0])
  // this.repositionBy(0);
  this.switchTo(0);
};

SlideSprite.prototype.unloadTextures = function () {
};

export default SlideSprite;
