
function AvatarSprite(scene, scaler) {
  this.scene = scene;
  this.scaler = scaler;
};

AvatarSprite.prototype.setTexture = function (textureHash) {
  this.textureHash = textureHash;
};

AvatarSprite.prototype.setPosition = function (position) {
  this.position = position;
};

AvatarSprite.prototype.setSize = function (size) {
  this.size = size;
};

AvatarSprite.prototype.setGroup = function (group) {
  this.group = group;
};

AvatarSprite.prototype.setDepth = function (depth) {
  this.depth = depth;
};

AvatarSprite.prototype.preload = function () {
  //this.scene.load.image('empty', require("../images/demo/empty.png"));
};


AvatarSprite.prototype.create = function () {
  let designX = this.position.x;
  let designY = this.position.y;

  let sprite = this.scene.add.image(
    this.scaler.scaleX(designX),
    this.scaler.scaleY(designY),
    'empty'
  );
  sprite.setOrigin(0.5, 0.5);
  // this.scaler.scaleSpriteByHeight(sprite);
  sprite.keepData = {
    resized: true,
    designX: designX,
    designY: designY,
    size:{
      width: this.size.width,
      height: this.size.height,
    },
    x: sprite.x
  };
  sprite.depth = this.depth;
  this.group.add(sprite);

  this.sprite = sprite;
  return this.sprite;
};

AvatarSprite.prototype.loadTextures = function () {
  let texture = this.textureHash;
  if (!texture || texture.length < 1) {
    texture = 'empty';
  }
  this.sprite.setTexture(texture);
  this.sprite.setDisplaySize(
    this.scaler.vScale() * this.size.width,
    this.scaler.vScale() * this.size.height
  );
};

AvatarSprite.prototype.unloadTextures = function () {
  console.log("Unload avatar sprite");
  this.sprite.setTexture('empty');
};

export default AvatarSprite;
