import videoController from "./video-controller-sprite";

var EventEmitter = require('events').EventEmitter;

var currentVideo;
var self;

function VideoSprite(scene, scaler) {
  this.scene = scene;
  this.scaler = scaler;
  this.dragMaxOffset;
  this.scaleFactor = 1.0;
  this.videoLoaded = false;
  self = this;

  this.showVideoController = true;

  this.videoController = new videoController(scene, scaler, this);
};

VideoSprite.prototype.__proto__ = EventEmitter.prototype;

VideoSprite.prototype.setAssets = function (assets) {
  this.assets = assets;
};

VideoSprite.prototype.setPosition = function (position) {
  this.position = position;
};

VideoSprite.prototype.setGroup = function (group) {
  this.group = group;
};

VideoSprite.prototype.setDepth = function (depth) {
  this.depth = depth;

  this.videoController.depth = depth;
};

VideoSprite.prototype.setScaleFactor = function (scaleFactor) {
  this.scaleFactor = scaleFactor;
};

VideoSprite.prototype.setVideoUrl = function (url) {
  this.videoUrl = url;
};

VideoSprite.prototype.getDragSprite = function () {
  return this.sprite;
};

VideoSprite.prototype.preload = function () {
  //this.scene.load.image('empty', require("../images/demo/empty.png"));
};


VideoSprite.prototype.create = function () {
  let designX = this.position.x;
  let designY = this.position.y;
  let texture;

  //video frame sprite
  let sprite = this.scene.add.image(
    this.scaler.scaleX(designX),
    this.scaler.scaleY(designY),
    'empty'
  );
  sprite.setOrigin(0.5, 0.5);
  sprite.keepData = {
    resized: true,
    designX: designX,
    designY: designY,
    scale: this.scaleFactor,
    x: sprite.x,
    y: sprite.y
  };
  this.group.add(sprite);
  sprite.depth = this.depth;

  //sprite.setInteractive({ draggable: true });
  // sprite.on("pointerdown", pointer => {
  //   this.videoSprite.keepData.pressed = true;
  //   //console.log(pointer);
  //   //console.log(this);
  // }, this);

  // sprite.on("pointerup", pointer => {
  //   //console.log(pointer);
  //   //console.log(this);
  //   if(this.dragMaxOffset <= 5 && this.videoUrl) {
  //     if(this.showVideoController) {
  //       this.videoController.showController()
  //     } else {
  //       this.togglePlay(); 
  //     }
  //   }
  // }, this);

  // // set drag events
  // sprite.on('drag', function (pointer, dragX, dragY) {
  //   //console.log("draggin" + dragX);

  //   if(this.scaler.getDirection() == 'Horizontal'){
  //     let offset = dragX - this.frameSprite.keepData.x;
  //     (Math.abs(offset) > this.dragMaxOffset) && (this.dragMaxOffset = Math.abs(offset));
  //     this.emit('drag', {offset: offset});
  //   } else{
  //     let offset = dragY - this.frameSprite.keepData.y;
  //     (Math.abs(offset) > this.dragMaxOffset) && (this.dragMaxOffset = Math.abs(offset));
  //     this.emit('drag', {offset: offset});
  //   }
  // }, this);

  // sprite.on('dragstart', function (pointer, dragX, dragY) {
  //   //console.log(`drag start.. ${pointer.x} ${pointer.y}`);
  //   this.dragMaxOffset = 0;
  //   this.frameSprite.keepData.x = this.frameSprite.x;
  //   this.frameSprite.keepData.y = this.frameSprite.y;
  //   this.emit('dragstart');
  // }, this);

  // sprite.on('dragend', function (pointer, dragX, dragY) {
  //   this.emit('dragend');
  // }, this);

  this.frameSprite = sprite;

  // bg sprite
  sprite = this.scene.add.rectangle(
    this.scaler.scaleX(designX),
    this.scaler.scaleY(designY),
    1,
    1
    , 0x000000, 0);
  sprite.setOrigin(0.5, 0.5);
  this.scaler.scaleSpriteByHeight(sprite);
  sprite.keepData = {
    resized: true,
    designX: designX,
    designY: designY,
    size: {
      width: this.assets.video.size.width,
      height: this.assets.video.size.height,
    },
    x: sprite.x,
  };
  sprite.depth = this.depth;
  sprite.setDisplaySize(
    this.scaler.vScale() * this.assets.video.size.width,
    this.scaler.vScale() * this.assets.video.size.height
  );
  this.group.add(sprite);
  this.bgSprite = sprite;

  sprite.setInteractive({ draggable: true });
  sprite.on("pointerdown", pointer => {
    this.videoSprite.keepData.pressed = true;
    //console.log(pointer);
    //console.log(this);
  }, this);

  sprite.on("pointerup", pointer => {
    //console.log(pointer);
    //console.log(this);
    if(this.dragMaxOffset <= 5 && this.videoUrl) {
      if(this.showVideoController) {
        this.videoController.showController();
      } else {
        this.togglePlay(); 
      }
    }
  }, this);

  // set drag events
  sprite.on('drag', function (pointer, dragX, dragY) {
    //console.log("draggin" + dragX);

    if(this.scaler.getDirection() == 'Horizontal'){
      let offset = dragX - this.bgSprite.keepData.x;
      (Math.abs(offset) > this.dragMaxOffset) && (this.dragMaxOffset = Math.abs(offset));
      this.emit('drag', {offset: offset});
    } else{
      let offset = dragY - this.bgSprite.keepData.y;
      (Math.abs(offset) > this.dragMaxOffset) && (this.dragMaxOffset = Math.abs(offset));
      this.emit('drag', {offset: offset});
    }
  }, this);

  sprite.on('dragstart', function (pointer, dragX, dragY) {
    //console.log(`drag start.. ${pointer.x} ${pointer.y}`);
    this.dragMaxOffset = 0;
    this.bgSprite.keepData.x = this.bgSprite.x;
    this.bgSprite.keepData.y = this.bgSprite.y;
    this.emit('dragstart');
  }, this);

  sprite.on('dragend', function (pointer, dragX, dragY) {
    this.emit('dragend');
  }, this);

  sprite.on('wheel', function (pointer, deltaX, deltaY, deltaZ) {
    this.emit('wheel', {offset: deltaY * 0.4});
  }, this);

  // video sprite
  sprite = this.scene.add.video(
    this.scaler.scaleX(designX),
    this.scaler.scaleY(designY)
  );
  sprite.setOrigin(0.5, 0.5);
  // this.scaler.scaleSpriteByHeight(sprite);
  sprite.keepData = {
    resized: true,
    designX: designX,
    designY: designY,
    size:{
      width: this.assets.video.size.width * this.scaleFactor,
      height: this.assets.video.size.height * this.scaleFactor,
    },
    x: sprite.x
  };
  this.group.add(sprite);
  sprite.depth = this.depth;

  this.videoSprite = sprite;

  // when to load the video ?
  // if (this.videoUrl && window.videoCannotPlay != true) {
  //   this.videoSprite.loadURL(this.videoUrl);
  //   this.videoSprite.on('created', function(video, width, height){
  //     this.videoSprite.setDisplaySize(
  //       this.scaler.vScale() * this.assets.video.size.width,
  //       this.scaler.vScale() * this.assets.video.size.height
  //     );
  //   }, this);
  // }

  // if(window.videoCannotPlay == true){
  //   this.videoSprite.visible = false;
  // }

  // video cover sprite
  sprite = this.scene.add.image(
    this.scaler.scaleX(designX),
    this.scaler.scaleY(designY),
    'empty'
  );
  sprite.setOrigin(0.5, 0.5);
  // this.scaler.scaleSpriteByHeight(sprite);
  sprite.keepData = {
    resized: true,
    designX: designX,
    designY: designY,
    size:{
      width: this.assets.cover.size.width * this.scaleFactor,
      height: this.assets.cover.size.height * this.scaleFactor,
    },
    x: sprite.x
  };
  this.group.add(sprite);
  sprite.depth = this.depth;
  //this.dragSprites[l].push(sprite);
  this.coverSprite = sprite;

  this.controllerSprites = this.videoController.create();

  this.videoController.updateBounds(
    this.position.x,
    this.position.y,
    this.assets.cover.size.width * this.scaleFactor,
    this.assets.cover.size.height * this.scaleFactor
  );
  this.videoController.videoControllerResize();

  this.videoController.setVideoSprite(this.videoSprite);

  
  // video play sprite
  sprite = this.scene.add.image(
    this.scaler.scaleX(designX),
    this.scaler.scaleY(designY),
    'empty'
  );
  sprite.setOrigin(0.5, 0.5);
  sprite.keepData = {
    resized: true,
    designX: designX,
    designY: designY,
    x: sprite.x,
    y: sprite.y,
    scale: this.scaleFactor
  };
  this.group.add(sprite);
  sprite.depth = this.depth;

  sprite.on("pointerdown", pointer => {
    this.videoSprite.keepData.pressed = true;
    //console.log(pointer);
    //console.log(this);
  }, this);

  sprite.on("pointerup", pointer => {
    //console.log(pointer);
    //console.log(this);
    (this.dragMaxOffset <= 5) && this.videoUrl && (this.togglePlay());
  }, this);

  // set drag events
  sprite.on('drag', function (pointer, dragX, dragY) {
    //console.log("draggin" + dragX);
    if(this.scaler.getDirection() == 'Horizontal'){
      let offset = dragX - this.playSprite.keepData.x;
      (Math.abs(offset) > this.dragMaxOffset) && (this.dragMaxOffset = Math.abs(offset));
      this.emit('drag', {offset: offset});
    } else{
      let offset = dragY - this.playSprite.keepData.y;
      (Math.abs(offset) > this.dragMaxOffset) && (this.dragMaxOffset = Math.abs(offset));
      this.emit('drag', {offset: offset});
    }
    
  }, this);

  sprite.on('dragstart', function (pointer, dragX, dragY) {
    //console.log(`drag start.. ${pointer.x} ${pointer.y}`);
    this.dragMaxOffset = 0;
    this.playSprite.keepData.x = this.playSprite.x;
    this.playSprite.keepData.y = this.playSprite.y;
    this.emit('dragstart');
  }, this);

  sprite.on('dragend', function (pointer, dragX, dragY) {
    this.emit('dragend');
  }, this);

  sprite.on('wheel', function (pointer, deltaX, deltaY, deltaZ) {
    this.emit('wheel', {offset: deltaY * 0.4});
  }, this);

  this.playSprite = sprite;

  var sprites = [this.frameSprite, this.bgSprite, this.videoSprite, this.coverSprite, this.playSprite];
  this.controllerSprites.forEach(sprite => {
    sprites.push(sprite);
  });

  return sprites;
};

VideoSprite.prototype.togglePlay = function () {
  var self = this;
  if (!this.videoLoaded) {
    // simply load it?
    this.videoLoaded = true;
    if (this.videoUrl) {
      // this.videoSprite.loadURL(this.videoUrl);
      this.videoSprite.loadURL(this.videoUrl).video.crossOrigin = 'anonymous';
      this.videoSprite.on('created', function(video, width, height){
        var duration = utils.timeFormate(this.videoSprite.getDuration());
        this.videoController.setDurationText(duration);

        this.videoSprite.setDisplaySize(
          this.scaler.vScale() * this.assets.video.size.width * this.scaleFactor,
          this.scaler.vScale() * this.assets.video.size.height * this.scaleFactor
        );
      }, this);

      this.videoSprite.on('complete', function(video){
        // console.log(11);
      }, this);

      this.videoSprite.on('stop', function(video){
        this.videoController.hideVideoController();
      }, this);

      this.videoSprite.video.removeEventListener('x5videoenterfullscreen', this.fullscreen, false);
      this.videoSprite.video.addEventListener('x5videoenterfullscreen', this.fullscreen, false);
    
      this.videoSprite.video.removeEventListener('x5videoexitfullscreen', this.exitFullscreen, false);
      this.videoSprite.video.addEventListener('x5videoexitfullscreen', this.exitFullscreen, false);
  
      document.getElementById('btn-close-region').onclick = function(){
        self.exitFullscreen();
      };
    }
  }

  if (this.videoSprite && this.videoSprite.keepData.pressed) {
    this.videoSprite.keepData.pressed = false;
    if (currentVideo && currentVideo != this.videoSprite) {
      currentVideo.stop();
    }
    if (currentVideo != this.videoSprite) {
      currentVideo = this.videoSprite;
    }
    // seems we don't need play it? does it works on mobile as well?
    //  currentVideo.play();

    this.videoSprite.setPaused(!this.videoSprite.isPaused());

    if(!this.videoSprite.isPaused()) {
      this.videoStartTime = new Date();
      // console.log(this.videoStartTime);
    }

    // we simply close the cover is video played
    if(this.coverSprite && this.coverSprite.visible == true){
      this.coverSprite.visible = false;
    }
    this.updateControls();
  }

  if(window.videoCannotPlay){
    this.openVideoContainer();
  }
};

VideoSprite.prototype.openVideoContainer = function () {
  var parent = document.getElementById('region-video-container');
  var children = parent.getElementsByTagName('video');
  for(var i = children.length - 1; i >= 0; i -- ){
    parent.removeChild(children[i]);
  }
  parent.appendChild(this.videoSprite.video);
  parent.style.display = 'block';
  this.videoSprite.video.style.width = '100%';
  this.videoSprite.video.style.height = '100%';
  this.videoSprite.video.style.margin = 'auto';
  this.videoSprite.video.style.position = 'absolute';
  this.videoSprite.video.style.top = '0px';
  this.videoSprite.video.style.bottom = '0px';
  this.videoSprite.video.controls = true;
};

VideoSprite.prototype.updateControls = function () {
  if (this.videoUrl/* && window.videoCannotPlay != true*/) {
    if (!this.videoLoaded) {
      // just show it
      this.playSprite.visible = true;
    } else if (this.videoSprite.isPaused()) {
      if(!this.videoController.isShow()) {
        this.playSprite.visible = true;
      }
      this.frameSprite.disableInteractive();
      this.bgSprite.disableInteractive();
    } else {
      this.playSprite.visible = false;
      this.frameSprite.setInteractive({ draggable: true });
      this.bgSprite.setInteractive({ draggable: true });
    }
  }
};

VideoSprite.prototype.update = function () {
  if (this.frameSprite) {
    let bounds = this.frameSprite.getBounds();

    // if(this.videoStartTime && window.videoCannotPlay == -1) {
    //   var now = new Date();
    //   if(now.getTime() - this.videoStartTime.getTime() >= 100) {
    //     if (
    //       (bounds.left + bounds.width < 0) || 
    //       (this.scene.cameras.main && bounds.left > this.scene.cameras.main.worldView.width)
    //     ) {
    //     // 认为视频不能正常在phaser播放
    //       window.videoCannotPlay = 3;
    //       this.openVideoContainer();
    //     } else {
    //       window.videoCannotPlay = 1;
    //     }
    //   }
    // }

    if (bounds.left + bounds.width < 0) {
      // console.log("out of bound then we stop video");      
      if(!window.videoCannotPlay){
        this.videoSprite.stop();
      }
    }
    if (this.scene.cameras.main && bounds.left > this.scene.cameras.main.worldView.width) {
      if(!window.videoCannotPlay){
        this.videoSprite.stop();
      }
    }

    this.updateControls();

    this.videoController.update();
  }
};

VideoSprite.prototype.loadTextures = function () {
  let texture = this.assets.frame.texture;
  texture = (texture && texture.length > 0) ? texture : 'empty';
  this.frameSprite.setTexture(texture);
  this.scaler.scaleSpriteByHeight(this.frameSprite);
  this.frameSprite.scale *= this.scaleFactor;

  // we don't need reset the hit area as the freame is not enabled interactive
  // at current stage
  //let bounds = this.frameSprite.getBounds();
  //this.frameSprite.input.hitArea.setSize(bounds.width, bounds.height);

  // cover sprite
  texture = this.assets.cover.texture;
  texture = (texture && texture.length > 0) ? texture : 'empty';
  this.coverSprite.setTexture(texture);
  this.coverSprite.setDisplaySize(
    this.scaler.vScale() * this.assets.cover.size.width * this.scaleFactor,
    this.scaler.vScale() * this.assets.cover.size.height * this.scaleFactor
  );

  // play button
  this.playSprite.setTexture('btn_play');
  this.scaler.scaleSpriteByHeight(this.playSprite);
  this.playSprite.setInteractive({ draggable: true });

  if(!this.videoUrl){
    this.playSprite.visible = false;
  }
};


VideoSprite.prototype.unloadTextures = function () {
  //console.log("Unload video sprite");
  //this.frameSprite.setTexture('empty');
  //this.coverSprite.setTexture('empty');
  //this.playSprite.setTexture('empty');
};

VideoSprite.prototype.fullscreen = function () {
  console.log('FULLSCREEN.');
  // window.videoCannotPlay = 2;
  document.getElementById('region-video-container').style.display = 'none';
};

VideoSprite.prototype.exitFullscreen = function () {
  console.log('Exit FULLSCREEN.');
  this.videoSprite.setPaused(true);
  this.playSprite.visible = true;
  this.coverSprite.visible = true;
  document.getElementById('region-video-container').style.display = 'none';
};

VideoSprite.prototype.hideFrame = function () {
  this.frameSprite.alpha = 0;
};

export default VideoSprite;
