'use strict';

import GC from "./const";

var ScenePreface = new Phaser.Class({

  Extends: Phaser.Scene,

  initialize:

  function ScenePreface ()
  {
    Phaser.Scene.call(this, { key: 'scenePreface', active: false });

    this.currentLan = 'cn';
  },

  init: function(params){
    const self = this;
    this.params = params;

    this.sceneBoot = this.scene.get('sceneBoot');
    this.scaler = this.sceneBoot.scaler;

    this.sceneBoot.scaleMode = 'full';
    this.scaler.scaleMode = 'full';

    this.scene.bringToTop();

    this.sceneBoot.windowResized = true;
  },

  preload: function ()
  {

  },

  create: function ()
  {
    var self = this;
    var sprite, designX, designY;

    designX = 0;
    designY = 0;
    sprite = this.add.image(
      this.scaler.scaleX(this.scaler.designRefWidth()/2 + designX),
      this.scaler.scaleY(this.scaler.designRefHeight()/2 + designY),
      'bg_preface'
    );
    sprite.setOrigin(0.5, 0.5);
    this.scaler.scaleSprite(sprite);
    sprite.keepData = {
      resized: true,
      xlocation: 'center',
      ylocation: 'center',
      locationByBg: true,
      designX: designX,
      designY: designY,
      fill: true,
    };

    designX = -110;
    designY = 155.5;
    sprite = this.add.image(
      this.scaler.scaleBgX(designX, 'right'),
      this.scaler.scaleY(designY),
      'label'
    );
    sprite.setOrigin(1, 0.5);
    this.scaler.scaleSprite(sprite);
    sprite.keepData = {
      resized: true,
      xlocation: 'right',
      designX: designX,
      designY: designY,
    };

    designX = 11;
    designY = 209;
    sprite = this.add.image(
      this.scaler.scaleBgX(designX, 'center'),
      this.scaler.scaleY(designY),
      'preface_' + this.currentLan
    );
    sprite.setOrigin(0.5, 0);
    this.scaler.scaleSprite(sprite);
    sprite.keepData = {
      resized: true,
      xlocation: 'center',
      designX: designX,
      designY: designY,
    };
    this.prefaceSprite = sprite;

    designX = 171;
    designY = 834;
    sprite = this.add.image(
      this.scaler.scaleBgX(designX),
      this.scaler.scaleY(designY),
      'btn_lan_' + this.currentLan
    );
    sprite.setOrigin(0.5, 0.5);
    this.scaler.scaleSprite(sprite);
    sprite.keepData = {
      resized: true,
      designX: designX,
      designY: designY,
      scaleBgX: true,
      enableClick: true,
    };
    this.btnLanSprite = sprite;
    
    sprite.setInteractive();
    
    sprite.on("pointerdown", pointer => {
      this.btnLanSprite.isPressed = true;
      this.btnLanSprite.alpha = 0.5;
    });

    sprite.on("pointerup", pointer => {
      if(this.btnLanSprite.isPressed) {
        this.changeLanguage()
      }
      this.btnLanSprite.isPressed = false;
      this.btnLanSprite.alpha = 1;
    });

    designX = 171;
    designY = 945.5;
    sprite = this.add.image(
      this.scaler.scaleBgX(designX),
      this.scaler.scaleY(designY),
      'btn_back'
    );
    sprite.setOrigin(0.5, 0.5);
    this.scaler.scaleSprite(sprite);
    sprite.keepData = {
      resized: true,
      designX: designX,
      designY: designY,
      scaleBgX: true,
      enableClick: true,
    };

    const btnBackSprite = sprite;
    
    sprite.setInteractive();
    
    sprite.on("pointerdown", pointer => {
      btnBackSprite.isPressed = true;
      btnBackSprite.alpha = 0.5;
    });

    sprite.on("pointerup", pointer => {
      if(btnBackSprite.isPressed) {
        this.back();
      }
      btnBackSprite.isPressed = false;
      btnBackSprite.alpha = 1;
    });

    designX = -190;
    designY = 520;
    sprite = this.add.image(
      this.scaler.scaleBgX(designX, 'right'),
      this.scaler.scaleY(designY),
      'btn_next'
    );
    sprite.setOrigin(0.5, 0.5);
    this.scaler.scaleSprite(sprite);
    sprite.keepData = {
      resized: true,
      xlocation: 'right',
      designX: designX,
      designY: designY,
      enableClick: true,
    };

    const btnNextSprite = sprite;
    
    sprite.setInteractive();
    
    sprite.on("pointerdown", pointer => {
      btnNextSprite.isPressed = true;
      btnNextSprite.alpha = 0.5;
    });

    sprite.on("pointerup", pointer => {
      if(btnNextSprite.isPressed) {
        this.next();
      }
      btnNextSprite.isPressed = false;
      btnNextSprite.alpha = 1;
    });

    this.input.on('pointerup', function () {
      utils.updateButtonsAlpha(this);
    }, this);

    if(this.params.animation) {
      utils.disableAllButtons(this);
      utils.sceneFadeInAnimation(this, function() {
        utils.enableAllButtons(self);
      });
    }
  },

  update: function(){
  },

  resized: function () {
    if(this.animationing) {
      return;
    }
    utils.resized(this);
  },

  changeLanguage: function() {
    if(this.currentLan === 'cn') {
      this.currentLan = 'en';
    } else {
      this.currentLan = 'cn';
    }

    this.prefaceSprite.setTexture('preface_' + this.currentLan);
    this.btnLanSprite.setTexture('btn_lan_' + this.currentLan);
  },

  back: function() {
    this.scene.start('sceneEnter');
  },

  next: function() {
    const self = this;
    utils.disableAllButtons(this);
      
    this.animationing = true;
    utils.sceneFadeOutAnimation(this, function() {
      self.animationing = false;
      self.scene.stop();
    });
    
    this.scene.launch('sceneAuthors', {
      animation: true,
    });
  },
});
export default ScenePreface;