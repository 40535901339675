'use strict';

import viewer from "./viewer";

var SceneMain = new Phaser.Class({

  Extends: Phaser.Scene,

  initialize:

  function SceneMain ()
  {
    Phaser.Scene.call(this, { key: 'sceneMain', active: false });

    this.controlParams = {
      远景速度: 1,
      中景速度: 1.353,
      近景速度: 2.100,
      超景速度: 4.665,
      动画帧数: 6,
      查看作品集: function() {
        // if(viewer.regions[0] && viewer.regions[0].portfolio){
        //   viewer.regions[0].portfolio.open('item__1');
        // }
      }
    };
  },

  init: function(params){
    var sceneBoot = this.scene.get('sceneBoot');
    this.scaler = sceneBoot.scaler;
    this.sceneBoot = sceneBoot;  

    this.hasData = false;
    if(params.regions){
      this.hasData = true;
    }

    this.viewer = viewer;
    if(this.hasData){
      this.viewer.init(
        params.regions, 
        params.theme, 
        [this.controlParams.超景速度, this.controlParams.近景速度, this.controlParams.中景速度, this.controlParams.远景速度, this.controlParams.近景速度], 
        params.multiple
      );
    }
    this.created = false;

    sceneBoot.scaleMode = 'height';
    this.scaler.scaleMode = 'height';

    sceneBoot.currentScene = 'sceneMain';
    this.updating = true;

    sceneBoot.windowResized = true;

    this.firstRegionLoaded = false;
    this.tipPlayed = false;

    this.params = params;

    this.scene.bringToTop();
    
    this.scene.get('sceneLoading').scene.bringToTop();
    this.scene.get('sceneSideBar').scene.bringToTop();
    // this.scene.get('sceneSideBar').buttonsPositionUp();
    // this.scene.get('sceneSideBar').showRegionLabel();

    this.firstResized = false;
  },

  preload: function ()
  {
    console.log('SceneMain preload..');
    var designX, designY, sprite;
    designX = 0;
    designY = 0;
    var source = 'bg';
    var sprite = this.add.image(
      this.scaler.scaleBgX(designX),
      this.scaler.scaleBgY(designY),
      source
    );
    sprite.setOrigin(0, 0);
    this.scaler.scaleSprite(sprite);
    sprite.keepData = {
      resized: true,
    };
    this.bgSprite = sprite;

    if(this.hasData){
      this.viewer.setScene(this);
      this.viewer.preload();
    }
  },

  create: function ()
  {
    var designX, designY, sprite;

    this.tipGroup = this.add.group();
    
    designX = 0;
    designY = 0;
    sprite = this.add.image(
      this.scaler.scaleX(this.scaler.designRefWidth()/2 + designX),
      this.scaler.scaleY(this.scaler.designRefHeight()/2 + designY),
      'tip_mask'
    );
    sprite.setOrigin(0.5, 0.5);
    this.scaler.scaleSpriteFill(sprite);
    sprite.keepData = {
      resized: true,
      fill: true,
      xlocation: 'center',
      ylocation: 'center',
      designX: designX,
      designY: designY
    };
    sprite.depth = 70000;
    this.tipGroup.add(sprite);

    designX = -349;
    designY = -100;
    sprite = this.add.image(
      this.scaler.scaleX(this.scaler.designRefWidth() + designX),
      this.scaler.scaleY(this.scaler.designRefHeight()/2 + designY),
      'icon_slide'
    );
    sprite.setOrigin(0.5, 0.5);
    sprite.keepData = {
      resized: true,
      xlocation: 'right',
      ylocation: 'center',
      designX: designX,
      designY: designY
    };
    sprite.depth = 70000;
    this.scaler.scaleSpriteByHeight(sprite);
    this.tipGroup.add(sprite);
    this.iconSlideSprite = sprite;

    // designX = 0;
    // designY = 449;
    // sprite = this.add.image(
    //   this.scaler.scaleX(this.scaler.designRefWidth()/2 + designX),
    //   this.scaler.scaleY(this.scaler.designRefHeight()/2 + designY),
    //   'text_slide'
    // );
    // sprite.setOrigin(0.5, 0.5);
    // sprite.keepData = {
    //   resized: true,
    //   xlocation: 'center',
    //   ylocation: 'center',
    //   designX: designX,
    //   designY: designY
    // };
    // sprite.depth = 70000;
    // this.scaler.scaleSpriteByHeight(sprite);
    // this.tipGroup.add(sprite);

    this.input.on('pointerdown', function () {
      this.tipGroup.setVisible(false);
    }, this);

    this.input.on('pointerup', function () {
      utils.updateButtonsAlpha(this);
    }, this);

    this.tipGroup.setVisible(false);

    this.sceneBoot.windowResized = true;
  },

  update: function(){
    if(this.scene.isVisible() == false){
      return;
    }

    if(this.created && this.updating){
      this.viewer.update(this);
    }

    if(this.tipPlayed == false && this.firstRegionLoaded == true){
      window.custom.noTip = true;
      this.tipPlayed = true;
      this.tipAnimation();
    }
  },

  resized: function(){
    var self = this;
    if(this.animationing) {
      return;
    }
    if(this.scene.isVisible() == false){
      return;
    }

    if(this.firstResized != null && this.firstResized == false){
      this.firstResized = true;
    }
    if(this.hasData && this.firstResized == true){
      this.firstResized = null;
      this.viewer.create();
      this.created = true;
      this.viewer.windowResized = true;
    }

    if(this.created){
      this.viewer.resized();
    }
    
    this.scaler.scaleSpriteFill(this.bgSprite);
    // this.bgSprite.width = this.scaler.designRefWidth();
    // this.bgSprite.height = this.scaler.designRefHeight();
  },

  tipAnimation: function(){
    this.tipGroup.setVisible(true);
    this.iconSlideSprite.alpha = 0;
    this.tweens.add({
      targets: this.iconSlideSprite,
      alpha: 1,
      duration: 800,
      delay: 0,
      ease: 'Linear',
      repeat: -1,
      yoyo: true,
      onComplete: function () {
      },
    });
  },

  enterAnimation: function() {
    utils.slideAimation(this, 'in', function() {
    });
  },

  outAnimation: function(func_callback) {
    var self = this;
    if(!this.scaler)
      return;
    
    this.animationing = true;

    utils.slideAimation(this, 'back', function() {
      if(func_callback) {
        func_callback();
      }
      self.animationing = false;
    });
  },

  fadeOutAnimation: function(func_callback) {
    var self = this;
    if(!this.scaler)
      return;
    
    this.animationing = true;

    utils.sceneFadeOutAnimation(this, function() {
      if(func_callback) {
        func_callback();
      }
      self.animationing = false;
    });
  },

});
export default SceneMain;