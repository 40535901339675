//Region is the space for one student

import FurnitureSprite from "./furniture-sprite";
import ArtworksSprite from "./artworks-sprite";
import AvatarSprite from "./avatar-sprite";
import Portfolio from "./portfolio";
import SlideSprite from "./slide-sprite";
import SpriteSheetSprite from "./spritesheet-sprite";
import VideoSprite from "./video-sprite";
import AudioSprite from "./audio-sprite";
import VideoLinkSprite from "./video-link-sprite";
import HotspotSprite from "./hotspot-sprite";
// import Students from "./students";
import GC from "./const";

var EventEmitter = require('events').EventEmitter;
var _ = require('lodash/core');

var PORTFOLIO_ENABLED = true;

function Region(json, scaler) {
  this.json = json;
  this.hidden = json.hidden ? true : false;
  this.columns = json.columns ? json.columns.length : 0;
  this.showWall = (json.dedicated && json.dedicated.length > 0) ? true : false;
  this.scene;
  this.scaler = scaler;
  this.draggers = []; // the draggers are the sprite need to act as draggers

  this.magicDepth;
  this.prevMagicDepth;

  // this flag is used to check whether the region is fully ready
  this.loadState = GC.UNLOAD;
  this.createState = GC.DESTROYED;

  this.dragSprites = [
    [], // most top layer
    [],
    [],
    [],
    []
  ];
  this.depthRange = {start: 0, end: 0};

  this.textureManagedSprites = [];

  this.on('dragstart', function () {
    //console.log('dragstart');
    this.dragSprites.map(layerSprites => {
      layerSprites.map(sprite => {
        sprite.keepData.x = sprite.x;
      });
    });
  });

  this.on('drag', function(evt) {
    if((this.viewer.allowLeft == false && evt.offset < 0) ||
       (this.viewer.allowRight == false && evt.offset > 0))
      return;
    for (let l = 0; l < this.dragSprites.length; l ++) {
      let layerSprites = this.dragSprites[l];
      let v = evt.speeds[l] || 0;
      layerSprites.map(sprite => {
        sprite.x = sprite.keepData.x + evt.offset * v;
      });
    }
  });

  this.on('dragend', function(evt) {
    //this.checkLoading();
  }, this);

  this.on('wheel', function(evt) {
    if(
        !evt.ignore &&
      ((this.viewer.allowLeft == false && evt.offset < 0) ||
      (this.viewer.allowRight == false && evt.offset > 0))
      ) {
        return;
    }
    
    for (let l = 0; l < this.dragSprites.length; l ++) {
      let layerSprites = this.dragSprites[l];
      let v = evt.speeds[l] || 0;
      layerSprites.map(sprite => {
        sprite.keepData.x = sprite.x;
        sprite.x = sprite.keepData.x + evt.offset * v;
      });
    }
  });

}

Region.prototype.__proto__ = EventEmitter.prototype;

Region.prototype.setScene = function(scene) {
  this.scene = scene;
};

Region.prototype.setName = function(name) {
  this.name = name;
};

Region.prototype.setViewer = function(viewer) {
  this.viewer = viewer;
};

Region.prototype.setOffset = function(offset) {
  this.offset = offset;
};

Region.prototype.setLayerSpeeds = function(layerSpeeds) {
  this.layerSpeeds = layerSpeeds;
};

Region.prototype.setLayerWidths = function(layerWidths) {
  this.layerWidths = layerWidths;
};


Region.prototype.setDepthBase = function(depthBase) {
  this.depthBase = depthBase;
};

// Returns materials for external usage
// @key
Region.prototype.getMaterials = function(key) {
  let materials = {};
  if(this.json && this.json.materials && this.json.materials[key])
  materials = this.json.materials[key];

  return materials;
};

Region.prototype.getOptions = function(key) {
  let options = {};
  if(this.json && this.json.options && this.json.options[key])
  options = this.json.options[key];

  return options;
};


Region.prototype.getLayer = function(columnsArray, columnSeq, layerSeq) {
  let column = this.json[columnsArray];
  if (!this.json
    || !this.json[columnsArray] || !this.json[columnsArray][columnSeq]
    || !this.json[columnsArray][columnSeq].layers || !this.json[columnsArray][columnSeq].layers[layerSeq]) {
      return null;
  } else {
    return this.json[columnsArray][columnSeq].layers[layerSeq];
  }
};

Region.prototype.assignDepth = function(base) {
  let d = base;
  this.depthRange.start = base;

  _.forEach(['columns', 'dedicated'], columnsArray => {
    let columns = this.json[columnsArray];
    if (!columns) return;
    for (let l = columns[0].layers.length - 1; l >= 0; l --) {
      for (let c = columns.length - 1; c >= 0; c --) {
        let column = columns[c];
        let layers = column.layers;
        let layer = layers[l];

        if (layer) {
          layer.furniture && (layer.furniture.depth = d ++);
          layer.artworks && (layer.artworks.depth = d ++);
        }

        if (l == 1 && columnsArray == 'columns') {
          if (c == 1) {
            this.magicDepth = d;
          } else if (this.json[columnsArray].length == 1) {
            this.magicDepth = d;
          }
        }

      }
    }
  });

  this.depthRange.end = (d - 1 > base) ? (d - 1) : base;
  //console.log(this.json);
  //console.log(this.depthRange);
};

// All assets are post loaded
Region.prototype.postLoadAssets = function() {
  let portfolioKeys = [];
  _.keys(this.json.portfolio).forEach(key => {
    portfolioKeys.push(this.json.portfolio[key]['texture']);
  });

  let regionImages = this.getMaterials("images");
  Object.keys(regionImages).map(key => {
    if (PORTFOLIO_ENABLED || portfolioKeys.indexOf(key) < 0){
      this.scene.textures.removeKey(key);
      if(this.json.dedicated && this.json.dedicated[0].layers[1].artworks.texture == key){
        this.scene.load.spritesheet(key, window.publicPath + regionImages[key], {frameWidth: 1600, frameHeight: 1080});  
      } else{
        this.scene.load.image(key, window.publicPath + regionImages[key]);  
      }
    }

    //this.scene.load.flagForRemoval(regionImages[key]);
  });

  let regionSpritesheets = this.getMaterials("spritesheets");
  Object.keys(regionSpritesheets).map(key => {
    let options = this.getOptions(key);
    let config = {
      frameWidth: options.width,
      frameHeight: options.height
    };
    // we need use spritesheet namespace to avoid the conflict.
    let spritesheetKey = 'spritesheet:' + key;
    this.scene.load.spritesheet(spritesheetKey, window.publicPath + regionSpritesheets[key], config);
  });

  let materials = this.json.materials;
  // if (PORTFOLIO_ENABLED) {
    this.portfolio.setMaterials(this.json.materials);
    this.portfolio.setItems(this.json.portfolio);
    this.portfolio.setVideos(materials.videos);
  // }
};

Region.prototype.loadingProgress = function() {
  let total = 0;
  let missing = 0;

  let portfolioKeys = [];
  _.keys(this.json.portfolio).forEach(key => {
    portfolioKeys.push(this.json.portfolio[key]['texture']);
  });

  let regionImages = this.getMaterials("images");
  Object.keys(regionImages).map(key => {
    total ++;
    if (!PORTFOLIO_ENABLED && portfolioKeys.indexOf(key) >= 0) {
      // simply pass it
    } else if (this.scene.textures.get(key).key === '__MISSING') {
      missing ++;
    }
  });

  let regionSpritesheets = this.getMaterials("spritesheets");
  Object.keys(regionSpritesheets).map(key => {
    total ++;
    // we need use spritesheet namespace to avoid the conflict.
    let spritesheetKey = 'spritesheet:' + key;
    //this.scene.load.spritesheet(spritesheetKey, regionSpritesheets[key], config);
    this.scene.textures.get(spritesheetKey).key;
    if(this.scene.textures.get(spritesheetKey).key === '__MISSING') {
      missing ++;
    }
  });

  // check it? also
  //this.portfolio.preload();

  return (total == 0 ) ? 1.0 : (total - missing) / total;
};

Region.prototype.checkLoading = function() {
  // we load 2 regions
  let distance = 0.6 * (GC.dedicatedWidth + GC.regionWidth) * this.scaler.hScale();
  if (this.loadState == GC.UNLOAD && this.startAnchorSprite.x   > -distance && this.startAnchorSprite.x < distance) {
    //if (this.loadState == GC.UNLOAD) {
    console.log("To load the region "  + this.name);
    this.loadState = GC.LOADING;
    this.postLoadAssets();
    this.scene.load.start();
    this.loadState = GC.LOADED;
  }

  if (this.loadState == GC.LOADED && this.createState == GC.DESTROYED) {
    let progress = this.loadingProgress();
    // console.log(progress);
    if (progress >= 1.0) {
      this.loadingSprite.visible = false;
      this.loadTextures();
      this.createState = GC.CREATED;
    } else {
      this.loadingSprite.setText('Loading ' +  this.name + " " + Math.floor(progress * 100) + '% ...');
    }
  }
};

Region.prototype.loadTextures = function() {
  this.textureManagedSprites.map(sprite => sprite.loadTextures());
};

Region.prototype.unloadTextures = function() {
  this.unloading = true;
};


Region.prototype.createInternal = function() {
  let offset = this.offset;
  let speeds = this.layerSpeeds;
  let cellWidths = this.layerWidths;
  let layersDistance = [
    offset / speeds[0] * speeds[0],
    offset / speeds[0] * speeds[1],
    offset / speeds[0] * speeds[2],
    offset / speeds[0] * speeds[3],
    offset / speeds[0] * speeds[4]
  ];

  let depthBase = this.depthBase;
  this.assignDepth(depthBase);

  let materials = this.json.materials;
  // if (PORTFOLIO_ENABLED) {
    this.portfolio.create();
  // }

  // this.students.create();

  // Add all layers
  ['columns', 'dedicated'].map((columnsArray) => {
    if (!this.json[columnsArray]) return;
    for (let c = 0; c < this.json[columnsArray].length; c ++) {  // column
      for (let l = 0; l < this.json[columnsArray][c].layers.length; l ++) {  // layer
        let layer = this.getLayer(columnsArray, c, l);
        if (layer) {
          let layerBase = cellWidths[l];
          var offsetX = layersDistance[l] + layerBase * c;
          let designX, designY, sprite;
          let group = this.scene.add.group();

          //信息墙背景
          if(columnsArray == 'dedicated'){
            designX = offsetX + layer.furniture.position.x;
            designY = this.scaler.designRefHeight()/2 - layer.furniture.position.y;
            sprite = new FurnitureSprite(this.scene, this.scaler);
            sprite.setPosition({x: designX, y: designY});
            if (columnsArray == 'dedicated' && this.prevMagicDepth) {
              sprite.setDepth(this.prevMagicDepth - 1);
            } else {
              sprite.setDepth(layer.artworks.depth - 1);
            }
            sprite.setTexture('bg_wall');
            sprite.setGroup(group);
            this.textureManagedSprites.push(sprite);
            this.dragSprites[l].push(sprite.create());
          }

          //
          //---- The furniture part
          //
          designX = offsetX + layer.furniture.position.x;
          designY = this.scaler.designRefHeight()/2 - layer.furniture.position.y;
          sprite = new FurnitureSprite(this.scene, this.scaler);
          sprite.setPosition({x: designX, y: designY});
          if (columnsArray == 'dedicated' && this.prevMagicDepth) {
            sprite.setDepth(this.prevMagicDepth);
          } else {
            sprite.setDepth(layer.artworks.depth);
          }
          sprite.setTexture(layer.furniture.texture);
          sprite.setGroup(group);
          this.textureManagedSprites.push(sprite);
          this.dragSprites[l].push(sprite.create());
          

          //
          //---- The artworks part
          //
          designX = offsetX + layer.artworks.position.x;
          designY = this.scaler.designRefHeight()/2 - layer.artworks.position.y;
          sprite = new ArtworksSprite(this.scene, this.scaler);
          if (layer.artworks.anchor) {
            sprite.setAnchor(layer.artworks.anchor);
          }
          sprite.setPosition({x: designX, y: designY});
          if (columnsArray == 'dedicated' && this.prevMagicDepth) {
            sprite.setDepth(this.prevMagicDepth);
          } else {
            sprite.setDepth(layer.artworks.depth);
          }
          sprite.setTexture(layer.artworks.texture);
          if (columnsArray == 'dedicated'){
            sprite.setFrame(0);
          } 
          sprite.setGroup(group);
          this.textureManagedSprites.push(sprite);
          this.dragSprites[l].push(sprite.create());

          _.forEach(layer.artworks.hotspots, hotspot=>{
            //console.log(hotspot.position);
            designX = offsetX + hotspot.position.x;
            designY = this.scaler.designRefHeight()/2 - hotspot.position.y;
            sprite = new HotspotSprite(this.scene, this.scaler);
            sprite.setPosition({x: designX, y: designY});
            sprite.setDepth(layer.artworks.depth);
            sprite.setTarget(hotspot);
            sprite.setPortfolio(this.portfolio);
            sprite.setVideos(materials.videos);
            sprite.setGroup(group);
            this.draggers.push(sprite);
            this.textureManagedSprites.push(sprite);
            this.dragSprites[l].push(sprite.create());
          });

          //
          //---- The additional part by type
          //
          if (layer.category == 'video') {
            designX = offsetX + layer.customization.position.x;
            designY = this.scaler.designRefHeight()/2 - layer.customization.position.y;
            sprite = new VideoSprite(this.scene, this.scaler);
            sprite.setPosition({x: designX, y: designY});

            if(layer.customization.zoom != undefined) {
              sprite.setScaleFactor(layer.customization.zoom);
            }
            
            sprite.setDepth(layer.artworks.depth);
            if(materials.videos[layer.assets.video.texture]){
              sprite.setVideoUrl(window.publicPath + materials.videos[layer.assets.video.texture]);
            }
            sprite.setAssets(layer.assets);
            sprite.setGroup(group);
            this.draggers.push(sprite);
            this.textureManagedSprites.push(sprite);
            sprite.create().map(s => this.dragSprites[l].push(s));

            if(layer.customization.hideFrame) {
              sprite.hideFrame();
            }
          }

          if (layer.category == 'audio') {
            designX = offsetX + layer.customization.position.x;
            designY = this.scaler.designRefHeight()/2 - layer.customization.position.y;
            sprite = new AudioSprite(this.scene, this.scaler);
            sprite.setPosition({x: designX, y: designY});
            sprite.setDepth(layer.artworks.depth);
            sprite.setAudioUrl(materials.audios[layer.assets.audio.texture]);
            sprite.setAssets(layer.assets);
            sprite.setGroup(group);
            this.draggers.push(sprite);
            this.textureManagedSprites.push(sprite);
            sprite.create().map(s => this.dragSprites[l].push(s));
          }

          if (layer.category == 'video-link') {
            designX = offsetX + layer.customization.position.x;
            designY = this.scaler.designRefHeight()/2 - layer.customization.position.y;
            sprite = new VideoLinkSprite(this.scene, this.scaler);
            sprite.setPosition({x: designX, y: designY});
            sprite.setDepth(layer.artworks.depth);
            sprite.setVideoUrl(layer.customization.url);
            sprite.setAssets(layer.assets);
            sprite.setGroup(group);
            this.draggers.push(sprite);
            this.textureManagedSprites.push(sprite);
            sprite.create().map(s => this.dragSprites[l].push(s));
          }

          if (layer.category == 'animation' && layer.assets.spritesheet.texture && layer.assets.spritesheet.texture.length > 0) {
            // this model includes spritesheet
            designX = offsetX + layer.customization.position.x;
            designY = this.scaler.designRefHeight()/2 - layer.customization.position.y;

            sprite = new SpriteSheetSprite(this.scene, this.scaler);
            sprite.setPosition({x: designX, y: designY});
            sprite.setDepth(layer.artworks.depth);
            if (_.keys(layer.customization).indexOf('zoom') >= 0) {
              sprite.setScaleFactor(layer.customization.zoom);
            }
            sprite.setTexture('spritesheet:' + layer.assets.spritesheet.texture);
            sprite.setSpriteSheet({
              texture: 'spritesheet:' + layer.assets.spritesheet.texture,
              fps: layer.customization.fps
            });
            sprite.setGroup(group);
            this.textureManagedSprites.push(sprite);
            this.dragSprites[l].push(sprite.create());

            // process all hotspots
            _.forEach(layer.assets.spritesheet.hotspots, (hotspot)=>{
              //console.log(hotspot.position);
              designX = offsetX + hotspot.position.x;
              designY = this.scaler.designRefHeight()/2 - hotspot.position.y;
              sprite = new HotspotSprite(this.scene, this.scaler);
              sprite.setPosition({x: designX, y: designY});
              sprite.setDepth(layer.artworks.depth);
              sprite.setTarget(hotspot);
              sprite.setPortfolio(this.portfolio);
              sprite.setVideos(materials.videos);
              sprite.setGroup(group);
              this.draggers.push(sprite);
              this.textureManagedSprites.push(sprite);
              this.dragSprites[l].push(sprite.create());
            });

          }

          if (layer.category == 'interactive') {
            // we use the origin point as the postion
            designX = offsetX;
            designY = this.scaler.designRefHeight()/2;

            sprite = new SlideSprite(this.scene, this.scaler);
            sprite.setPosition({x: designX, y: designY});
            sprite.setDepth(layer.artworks.depth);
            sprite.setTextures(layer.assets.slide);
            sprite.setParams(layer.customization);
            sprite.setGroup(group);
            this.draggers.push(sprite);
            this.textureManagedSprites.push(sprite);
            sprite.create().map(s => this.dragSprites[l].push(s));
          }

          // if (layer.category == 'system' && layer.assets && layer.assets.avatar) {
          //   // we use the origin point as the postion
          //   designX = offsetX + layer.assets.avatar.position.x;
          //   designY = this.scaler.designRefHeight()/2 - layer.assets.avatar.position.y;

          //   sprite = new AvatarSprite(this.scene, this.scaler);
          //   sprite.setPosition({x: designX, y: designY});
          //   sprite.setSize(layer.assets.avatar.size);
          //   if (columnsArray == 'dedicated' && this.prevMagicDepth) {
          //     sprite.setDepth(this.prevMagicDepth);
          //   } else {
          //     sprite.setDepth(layer.artworks.depth);
          //   }
          //   sprite.setTexture(layer.assets.avatar.texture);
          //   sprite.setGroup(group);
          //   this.textureManagedSprites.push(sprite);
          //   this.dragSprites[l].push(sprite.create());
          // }


        }

      }  // end of layer
    }  // end of column
  });

  // finally, we enable all draggers
  this.enableDraggers();
};

Region.prototype.enableDraggers = function() {
  let region = this;
  this.draggers.map(dragger => {
    dragger.on('dragstart', function() {
      region.emit('dragger_dragstart');
    });
    dragger.on('drag', function(evt) {
      region.emit('dragger_drag', {offset: evt.offset, speeds: region.layerSpeeds});
    });
    dragger.on('dragend', function() {
      region.emit('dragger_dragend');
    });
    dragger.on('wheel', function(evt) {
      region.emit('dragger_wheel', {offset: evt.offset, speeds: region.layerSpeeds});
    });
  });
};

Region.prototype.speedChanged = function (speeds) {
};

Region.prototype.getVisibleWidth = function() {
  let viewerWidth = this.scaler.width;
  let left = this.startAnchorSprite.x;
  if (left < 0) left = 0;
  if (left > viewerWidth) left = viewerWidth;

  let right = this.endAnchorSprite.x;
  if (right < 0) right = 0;
  if (right > viewerWidth) right = viewerWidth;

  return (right - left);
};

// interface for phaser
Region.prototype.preload = function() {
  // we only
  // if (PORTFOLIO_ENABLED) {
    this.portfolio = new Portfolio(this.scene, this.scaler);
    this.portfolio.preload();
  // }

  // //students
  // var names = [];
  // for(var i = 0; i < this.viewer.regions.length; i ++){
  //   if(this.viewer.regions[i].json.hidden == false){
  //     if(this.viewer.regions[i].json.meta && this.viewer.regions[i].json.meta.name && this.viewer.regions[i].json.meta.name.texture
  //       && this.viewer.regions[i].json.meta.category != 'department'){
  //         var avatarTexture = 'empty';
  //         var authorTexture = 'empty';
  //         if(this.viewer.regions[i].json.meta.avatar){
  //           avatarTexture = this.viewer.regions[i].json.meta.avatar.texture;
  //         }
  //         if(this.viewer.regions[i].json.meta.author){
  //           authorTexture = this.viewer.regions[i].json.meta.author.texture;
  //         }

  //         var obj = {
  //           avatar: avatarTexture,
  //           author: authorTexture,
  //           region: this.viewer.regions[i]
  //         };
  //         names.push(obj);
  //     }
  //   }
  // }

  // this.students = new Students(this.scene, this.scaler, names, this.viewer);
  // this.students.preload();

  for(var key in this.json.preloadMaterials.images){
    // if(key == this.json.meta.author.texture){
    //   this.scene.load.spritesheet(key, window.publicPath  + this.json.preloadMaterials.images[key], {frameWidth: 313, frameHeight: 100});
    // } else{
    //   this.scene.load.image(key, window.publicPath  + this.json.preloadMaterials.images[key]);
    // }
    this.scene.load.image(key, window.publicPath  + this.json.preloadMaterials.images[key]);
  }
};

Region.prototype.create = function() {

  // we use two anchor sprites for space location
  // the anchor is put in the second layer (same as info wall)

  let speeds = this.layerSpeeds;
  let designX = (this.offset - (this.showWall ? GC.dedicatedWidth : 0)) /speeds[0] * speeds[1];
  let designY = this.scaler.designRefHeight()/2 + 0;

  let sprite = this.scene.add.image(
    this.scaler.scaleX(designX),
    this.scaler.scaleY(designY),
    'empty'
  );
  sprite.setOrigin(0.5, 0.5);
  this.scaler.scaleSpriteByHeight(sprite);
  sprite.keepData = {
    resized: true,
    designX: designX,
    designY: designY,
    x: sprite.x
  };
  sprite.depth = this.depthBase;
  this.dragSprites[1].push(sprite);
  this.startAnchorSprite = sprite;

  designX = (this.offset + this.columns *  GC.columnWidth) /speeds[0] * speeds[1];
  sprite = this.scene.add.image(
    this.scaler.scaleX(designX),
    this.scaler.scaleY(designY),
    'empty'
  );
  sprite.setOrigin(0.5, 0.5);
  this.scaler.scaleSpriteByHeight(sprite);
  sprite.keepData = {
    resized: true,
    designX: designX,
    designY: designY,
    x: sprite.x
  };
  sprite.depth = this.depthBase;
  this.dragSprites[1].push(sprite);
  this.endAnchorSprite = sprite;

  designX = this.offset + 0;
  sprite = this.scene.add.text(
    this.scaler.scaleX(designX),
    this.scaler.scaleY(designY),
    '',
    {fontSize: '28px'}
  );
  sprite.setOrigin(0, 0.5);
  this.scaler.scaleSpriteByHeight(sprite);
  sprite.setColor('#ffffff');
  sprite.keepData = {
    resized: true,
    designX: designX,
    designY: designY,
    x: sprite.x
  };
  sprite.setText('Region ' +  this.name + '');
  sprite.depth = this.depthBase + 49;
  this.dragSprites[0].push(sprite);
  this.loadingSprite = sprite;
  this.loadingSprite.visible = false;

  // portfolio button
  // designX = (this.offset) / this.layerSpeeds[0] * this.layerSpeeds[1];
  // designY = this.scaler.designRefHeight();
  // sprite = this.scene.add.image(
  //   this.scaler.scaleX(designX),
  //   this.scaler.scaleY(designY),
  //   'btn_portfolio'
  // );
  // sprite.setOrigin(0, 1);
  // this.scaler.scaleSpriteByHeight(sprite);
  // sprite.keepData = {
  //   resized: true,
  //   designX: designX,
  //   designY: designY,
  //   x: sprite.x
  // };
  // sprite.depth = this.depthBase + 49;
  // sprite.setInteractive();
  // sprite.on("pointerdown", pointer => {

  // });
  // sprite.on("pointerup", pointer => {
  //   this.portfolio.open('item__1');
  // });

  // this.dragSprites[1].push(sprite);
  // this.portfolioSprite = sprite;

  this.createInternal();
};

Region.prototype.update = function() {
  this.draggers.map(dragger => {
    dragger.update && dragger.update();
  });
  this.checkLoading();

  this.portfolio && (this.portfolio.update());

  // this.students.update();

  // if (this.name == "R1") {
  //   console.log(`${this.startAnchorSprite.x} ${this.endAnchorSprite.x} `);
  // }

  if (this.unloading)  {
    console.log("unload region " + this.name);
    this.textureManagedSprites.map(sprite => sprite.unloadTextures());
    this.unloading = false;
  }

};

Region.prototype.resize = function() {
  this.portfolio && (this.portfolio.resize());

  // this.students.resize();
};

export default Region;
