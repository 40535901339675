"use strict";

let GC = {
  resolution: 1080,

  dedicatedWidth: (6900 - 2.0) * (3 / 6) ,

  columnWidth: (6900 - 2.0) * 1,

  regionWidth: (6900 - 2.0) * 2,

  layerSpeeds: [(6900 - 2.0)/(1478 - 2.0), (3104 - 2.0)/(1478 - 2.0), (2000 - 2.0)/(1478 - 2.0), (1478 - 2.0)/(1478 - 2.0)],

  layerWidths: [ (6900 - 2.0), (3104 - 2.0), (2000 - 2.0), (1478 - 2.0) ],

  UNLOAD: 'unload',
  LOADING: 'loading',
  LOADED: 'loaded',

  DESTROYED: 'destroyed',
  CREATED: 'created',

  sidebarWidth: 150,

  animationDuration: 800,

  galleryMap: [
    '2257654e', // 科技前沿
    '6f45cfb9', // 经济主战场
    'b0d3edb2', // 国家重大需求
    'e751d1cb', // 人民生命健康
  ],
};


export default GC;

//console.log(GC);
