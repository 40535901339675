'use strict';

import Scroller from "./scroller";

var SceneAuthors = new Phaser.Class({

  Extends: Phaser.Scene,

  initialize:

  function SceneAuthors ()
  {
    Phaser.Scene.call(this, { key: 'sceneAuthors', active: false });

    this.isFirstTime = true;

    // 作者名单有改动时,需要修改
    this.authorNumber = 56;
  },

  init: function(params){
    const self = this;
    this.params = params;

    this.sceneBoot = this.scene.get('sceneBoot');
    this.scaler = this.sceneBoot.scaler;

    this.sceneBoot.scaleMode = 'full';
    this.scaler.scaleMode = 'full';

    this.scene.bringToTop();

    this.sceneBoot.windowResized = true;
  },

  preload: function ()
  {

  },

  create: function ()
  {
    var self = this;
    var sprite, designX, designY;

    designX = 0;
    designY = 0;
    sprite = this.add.image(
      this.scaler.scaleX(this.scaler.designRefWidth()/2 + designX),
      this.scaler.scaleY(this.scaler.designRefHeight()/2 + designY),
      'bg_preface'
    );
    sprite.setOrigin(0.5, 0.5);
    this.scaler.scaleSprite(sprite);
    sprite.keepData = {
      resized: true,
      xlocation: 'center',
      ylocation: 'center',
      locationByBg: true,
      designX: designX,
      designY: designY,
      fill: true,
    };

    designX = 306.5;
    designY = 271.5;
    sprite = this.add.image(
      this.scaler.scaleBgX(designX),
      this.scaler.scaleY(designY),
      'title_authors_0'
    );
    sprite.setOrigin(0.5, 0.5);
    this.scaler.scaleSprite(sprite);
    sprite.keepData = {
      resized: true,
      designX: designX,
      designY: designY,
    };

    this.scroller = new Scroller(this, this.scaler, 'Horizontal');

    designX = 0;
    designY = 554;
    sprite = this.add.rectangle(
      this.scaler.scaleX(this.scaler.designRefWidth()/2 + designX),
      this.scaler.scaleY(designY),
      this.scaler.designRefWidth(),
      640
      , 0x000000, 0);
    sprite.setOrigin(0.5, 0.5);
    this.scaler.scaleSpriteFill(sprite);
    sprite.keepData = {
      resized: true,
      xlocation: 'center',
      designX: designX,
      designY: designY
    };
    const bgSprite = sprite;
    
    const dragSprites = [];
    const avatarSprites = [];
    const nameSprites = [];
    for(let i = 0; i < this.authorNumber; i ++) {
      (function(i) {       
        designX = 256.5 + parseInt(i/2) * 531;
        designY = 504.5 + i%2 * 226;
        sprite = self.add.image(
          self.scaler.scaleBgX(designX),
          self.scaler.scaleY(designY),
          'avatars', i
        );
        sprite.setOrigin(0.5, 0.5);
        self.scaler.scaleSprite(sprite);
        sprite.keepData = {
          resized: true,
          designX: designX,
          designY: designY,
          enableClick: true,
        };
        dragSprites.push(sprite);
        avatarSprites.push(sprite);

        sprite.on("pointerdown", pointer => {
          if(self.scroller.dragMaxOffset > 5) {
            return
          }
          avatarSprites[i].isPressed = true;
          avatarSprites[i].alpha = 0.5;
          nameSprites[i].alpha = 0.5;
        });

        sprite.on("pointerup", pointer => {
          if(self.scroller.dragMaxOffset > 5) {
            return
          }
          if(avatarSprites[i].isPressed) {
            self.toRegion(i);
          }
          avatarSprites[i].isPressed = false;
          avatarSprites[i].alpha = 1;
          nameSprites[i].alpha = 1;
        });

        designX = 345 + parseInt(i/2) * 531;
        designY = 573.5 +  i%2 * 226;
        sprite = self.add.image(
          self.scaler.scaleBgX(designX),
          self.scaler.scaleY(designY),
          'names', i
        );
        sprite.setOrigin(0, 1);
        self.scaler.scaleSprite(sprite);
        sprite.keepData = {
          resized: true,
          designX: designX,
          designY: designY,
          enableClick: true,
        };
        dragSprites.push(sprite);
        nameSprites.push(sprite);

        sprite.on("pointerdown", pointer => {
          if(self.scroller.dragMaxOffset > 5) {
            return
          }
          nameSprites[i].isPressed = true;
          nameSprites[i].alpha = 0.5;
          nameSprites[i].alpha = 0.5;
        });

        sprite.on("pointerup", pointer => {
          if(self.scroller.dragMaxOffset > 5) {
            return
          }
          if(nameSprites[i].isPressed) {
            self.toRegion(i);
          }
          nameSprites[i].isPressed = false;
          nameSprites[i].alpha = 1;
          avatarSprites[i].alpha = 1;
        });
      })(i)
    }

    this.scroller.setDragBg(bgSprite);

    if(dragSprites.length > 0) {
      this.scroller.setDragSprites(dragSprites);
      this.scroller.setScrollerBounds({
        left: 184,
        right: -213,
        rightEnd: -400,
      });
    }

    designX = 156;
    designY = 0;
    sprite = this.add.image(
      this.scaler.scaleBgX(designX, 'right'),
      this.scaler.scaleBgY(designY, 'center'),
      'mask_right'
    );
    sprite.setOrigin(1, 0.5);
    this.scaler.scaleSpriteFillHeight(sprite);
    sprite.keepData = {
      resized: true,
      xlocation: 'right',
      ylocation: 'center',
      designX: designX,
      designY: designY,
      scaleBgX: true,
      scaleBgY: true,
      scaleFillHeight: true,
    };
    this.maskSprite = sprite;
    this.maskSprite.setVisible(false);

    this.tipMaskGroup = this.createTipMask();

    designX = -110;
    designY = 155.5;
    sprite = this.add.image(
      this.scaler.scaleBgX(designX, 'right'),
      this.scaler.scaleY(designY),
      'label'
    );
    sprite.setOrigin(1, 0.5);
    this.scaler.scaleSprite(sprite);
    sprite.keepData = {
      resized: true,
      xlocation: 'right',
      designX: designX,
      designY: designY,
    };

    designX = 171;
    designY = 945.5;
    sprite = this.add.image(
      this.scaler.scaleBgX(designX),
      this.scaler.scaleY(designY),
      'btn_back'
    );
    sprite.setOrigin(0.5, 0.5);
    this.scaler.scaleSprite(sprite);
    sprite.keepData = {
      resized: true,
      designX: designX,
      designY: designY,
      scaleBgX: true,
      enableClick: true,
    };

    const btnBackSprite = sprite;
    
    sprite.setInteractive();
    
    sprite.on("pointerdown", pointer => {
      btnBackSprite.isPressed = true;
      btnBackSprite.alpha = 0.5;
    });

    sprite.on("pointerup", pointer => {
      if(btnBackSprite.isPressed) {
        this.back();
      }
      btnBackSprite.isPressed = false;
      btnBackSprite.alpha = 1;
    });

    designX = -190;
    designY = 520;
    sprite = this.add.image(
      this.scaler.scaleBgX(designX, 'right'),
      this.scaler.scaleY(designY),
      'btn_next'
    );
    sprite.setOrigin(0.5, 0.5);
    this.scaler.scaleSprite(sprite);
    sprite.keepData = {
      resized: true,
      xlocation: 'right',
      designX: designX,
      designY: designY,
      enableClick: true,
    };

    const btnNextSprite = sprite;
    
    sprite.setInteractive();
    
    sprite.on("pointerdown", pointer => {
      btnNextSprite.isPressed = true;
      btnNextSprite.alpha = 0.5;
    });

    sprite.on("pointerup", pointer => {
      if(btnNextSprite.isPressed) {
        this.next();
      }
      btnNextSprite.isPressed = false;
      btnNextSprite.alpha = 1;
    });


    if(!this.isFirstTime) {
      this.closeTipMask();
    }

    this.input.on('pointerup', function () {
      utils.updateButtonsAlpha(this);
    }, this);

    if(this.params.animation) {
      utils.disableAllButtons(this);
      utils.sceneFadeInAnimation(this, function() {
        utils.enableAllButtons(self);
        
        if(self.isFirstTime) {
          self.tipAnimation();
        }
      });
    } else {
      if(self.isFirstTime) {
        self.tipAnimation();
      }
    }
  },

  update: function(){
    if(this.animationing) {
      return;
    }
    this.scroller.checkEnding();
    this.scroller.checkCrop();
  },

  resized: function () {
    if(this.animationing) {
      return;
    }
    utils.resized(this);
  },

  createTipMask: function() {
    var sprite, designX, designY;

    const group = this.add.group();

    designX = 0;
    designY = 0;
    sprite = this.add.rectangle(
      this.scaler.scaleX(this.scaler.designRefWidth()/2 + designX),
      this.scaler.scaleY(this.scaler.designRefHeight()/2 + designY),
      this.scaler.designRefWidth(),
      this.scaler.designRefHeight()
      , 0x000000, 0);
    sprite.setOrigin(0.5, 0.5);
    this.scaler.scaleSpriteFill(sprite);
    sprite.keepData = {
      resized: true,
      fill: true,
      xlocation: 'center',
      ylocation: 'center',
      designX: designX,
      designY: designY
    };
    group.add(sprite);

    sprite.setInteractive();
    
    sprite.on("pointerdown", pointer => {
    });

    sprite.on("pointerup", pointer => {
      this.closeTipMask();
    });

    designX = 0;
    designY = 0;
    sprite = this.add.image(
      this.scaler.scaleBgX(designX, 'right'),
      this.scaler.scaleBgY(designY, 'center'),
      'mask_right'
    );
    sprite.setOrigin(1, 0.5);
    this.scaler.scaleSpriteFillHeight(sprite);
    sprite.keepData = {
      resized: true,
      xlocation: 'right',
      ylocation: 'center',
      designX: designX,
      designY: designY,
      scaleBgX: true,
      scaleBgY: true,
      scaleFillHeight: true,
    };
    group.add(sprite);

    designX = -327;
    designY = 615;
    sprite = this.add.image(
      this.scaler.scaleBgX(designX, 'right'),
      this.scaler.scaleY(designY),
      'icon_slide_white'
    );
    sprite.setOrigin(0.5, 0.5);
    this.scaler.scaleSprite(sprite);
    sprite.keepData = {
      resized: true,
      xlocation: 'right',
      designX: designX,
      designY: designY,
      scaleBgX: true,
    };
    this.iconSlideSprite = sprite;
    group.add(sprite);

    return group;
  },

  toRegion: function(i) {
    const rid = Locators[i].gallery;
    const locator = Locators[i].locator;
    const galleryId = GC.galleryMap.indexOf(rid);
    window.custom.locator = locator;
      
    this.hide();
    utils.toGallery(this.scene, galleryId, false, 'sceneAuthors');
  },

  hide: function() {
    const self = this;
    utils.disableAllButtons(this);
    
    this.animationing = true;
    utils.sceneFadeOutAnimation(this, function() {
      self.scene.setVisible(false);
      self.scene.sendToBack();
    });
  },

  show: function() {
    utils.enableAllButtons(this);
    this.animationing = false;
    this.cameras.main.alpha = 1;
    this.scene.setVisible(true);
    this.scene.bringToTop();
  },

  back: function() {
    this.scene.start('scenePreface', {
      animation: false,
    });
  },

  next: function() {
    const self = this;
    utils.disableAllButtons(this);
      
    this.animationing = true;
    utils.sceneFadeOutAnimation(this, function() {
      self.animationing = false;
      self.scene.stop();
    });
    
    this.scene.launch('sceneGallery', {
      animation: true,
    });
  },

  closeTipMask: function() {
    this.isFirstTime = false;
    this.tipMaskGroup.setVisible(false);
    this.tipAnimationStop();

    this.maskSprite.setVisible(true);
  },

  tipAnimation: function() {
    this.tipAnimationStop();

    this.tipTween = this.tweens.add({
      targets: this.iconSlideSprite,
      alpha: 0,
      duration: 800,
      delay: 0,
      ease: 'Linear',
      repeat: -1,
      yoyo: true,
      onComplete: function () {
      },
    });
  },

  tipAnimationStop: function() {
    if(this.tipTween) {
      this.tipTween.remove();
    }
  },
});
export default SceneAuthors;