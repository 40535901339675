'use strict';

import GC from "./const";

var SceneGallery = new Phaser.Class({

  Extends: Phaser.Scene,

  initialize:

  function SceneGallery ()
  {
    Phaser.Scene.call(this, { key: 'sceneGallery', active: false });
  },

  init: function(params){
    const self = this;
    this.params = params;

    this.sceneBoot = this.scene.get('sceneBoot');
    this.scaler = this.sceneBoot.scaler;

    this.sceneBoot.scaleMode = 'full';
    this.scaler.scaleMode = 'full';

    this.scene.bringToTop();

    this.sceneBoot.windowResized = true;
  },

  preload: function ()
  {

  },

  create: function ()
  {
    var self = this;
    var sprite, designX, designY;

    designX = 0;
    designY = 0;
    sprite = this.add.image(
      this.scaler.scaleBgX(designX, 'center'),
      this.scaler.scaleY(this.scaler.designRefHeight()/2 + designY),
      'bg_preface'
    );
    sprite.setOrigin(0.5, 0.5);
    this.scaler.scaleSprite(sprite);
    sprite.keepData = {
      resized: true,
      xlocation: 'center',
      ylocation: 'center',
      locationByBg: true,
      designX: designX,
      designY: designY,
      fill: true,
    };

    designX = -110;
    designY = 155.5;
    sprite = this.add.image(
      this.scaler.scaleBgX(designX, 'right'),
      this.scaler.scaleY(designY),
      'label'
    );
    sprite.setOrigin(1, 0.5);
    this.scaler.scaleSprite(sprite);
    sprite.keepData = {
      resized: true,
      xlocation: 'right',
      designX: designX,
      designY: designY,
    };

    designX = 180;
    designY = 256;
    sprite = this.add.image(
      this.scaler.scaleBgX(designX),
      this.scaler.scaleY(designY),
      'gallery_title'
    );
    sprite.setOrigin(0, 0.5);
    this.scaler.scaleSprite(sprite);
    sprite.keepData = {
      resized: true,
      designX: designX,
      designY: designY,
      scaleBgX: true,
    };

    const gallerySprites = []
    for(let i = 0; i < 4; i ++) {
      (function(i) {
        designX = 12 + 535 * (i % 2);
        designY = 430 + 320 * parseInt(i/2);
        sprite = self.add.image(
          self.scaler.scaleBgX(designX, 'center'),
          self.scaler.scaleY(designY),
          'gallery_' + i
        );
        sprite.setOrigin(0.5, 0.5);
        self.scaler.scaleSprite(sprite);
        sprite.keepData = {
          resized: true,
          xlocation: 'center',
          designX: designX,
          designY: designY,
          enableClick: true,
        };
        gallerySprites.push(sprite);
  
        sprite.setInteractive();
      
        sprite.on("pointerdown", pointer => {
          gallerySprites[i].isPressed = true;
          gallerySprites[i].alpha = 0.5;
        });
  
        sprite.on("pointerup", pointer => {
          if(gallerySprites[i].isPressed) {
            self.toGallery(i);
          }
          gallerySprites[i].isPressed = false;
          gallerySprites[i].alpha = 1;
        });
      })(i);
    }

    designX = 171;
    designY = 832.5;
    sprite = this.add.image(
      this.scaler.scaleBgX(designX),
      this.scaler.scaleY(designY),
      'btn_authors'
    );
    sprite.setOrigin(0.5, 0.5);
    this.scaler.scaleSprite(sprite);
    sprite.keepData = {
      resized: true,
      designX: designX,
      designY: designY,
      scaleBgX: true,
      enableClick: true,
    };

    const btnAuthorsSprite = sprite;
    
    sprite.setInteractive();
    
    sprite.on("pointerdown", pointer => {
      btnAuthorsSprite.isPressed = true;
      btnAuthorsSprite.alpha = 0.5;
    });

    sprite.on("pointerup", pointer => {
      if(btnAuthorsSprite.isPressed) {
        this.toAuthorList()
      }
      btnAuthorsSprite.isPressed = false;
      btnAuthorsSprite.alpha = 1;
    });

    designX = 171;
    designY = 945.5;
    sprite = this.add.image(
      this.scaler.scaleBgX(designX),
      this.scaler.scaleY(designY),
      'btn_back'
    );
    sprite.setOrigin(0.5, 0.5);
    this.scaler.scaleSprite(sprite);
    sprite.keepData = {
      resized: true,
      designX: designX,
      designY: designY,
      scaleBgX: true,
      enableClick: true,
    };

    const btnBackSprite = sprite;
    
    sprite.setInteractive();
    
    sprite.on("pointerdown", pointer => {
      btnBackSprite.isPressed = true;
      btnBackSprite.alpha = 0.5;
    });

    sprite.on("pointerup", pointer => {
      if(btnBackSprite.isPressed) {
        this.back();
      }
      btnBackSprite.isPressed = false;
      btnBackSprite.alpha = 1;
    });

    this.input.on('pointerup', function () {
      utils.updateButtonsAlpha(this);
    }, this);

    if(this.params.animation) {
      utils.disableAllButtons(this);
      utils.sceneFadeInAnimation(this, function() {
        utils.enableAllButtons(self);
      });
    }
  },

  update: function(){
  },

  resized: function () {
    if(this.animationing) {
      return;
    }
    utils.resized(this);
  },

  toAuthorList: function() {
    this.outAnimation();
    this.scene.launch('sceneAuthorList', {
      animation: true,
    });
  },

  toGallery: function(i) {
    this.outAnimation();
    utils.toGallery(this.scene, i);
  },

  back: function() {
    this.scene.start('sceneAuthors', {
      animation: false,
    });
  },

  outAnimation: function() {
    const self = this;
    this.animationing = true;
    utils.sceneFadeOutAnimation(this, function() {
      self.animationing = false;
      self.scene.stop();
    });
  },
});
export default SceneGallery;