
function SpriteSheetSprite(scene, scaler) {
  this.scene = scene;
  this.scaler = scaler;
  this.scaleFactor = 1.0;
};

SpriteSheetSprite.prototype.setTexture = function (textureHash) {
  this.textureHash = (textureHash && textureHash.length > 0) ? textureHash : 'empty';
};

SpriteSheetSprite.prototype.setSpriteSheet = function (spritesheet) {
  this.spritesheet = spritesheet;
};

SpriteSheetSprite.prototype.setPosition = function (position) {
  this.position = position;
};

SpriteSheetSprite.prototype.setDepth = function (depth) {
  this.depth = depth;
};

SpriteSheetSprite.prototype.setScaleFactor = function (scaleFactor) {
  this.scaleFactor = scaleFactor;
};

SpriteSheetSprite.prototype.setGroup = function (group) {
  this.group = group;
};

SpriteSheetSprite.prototype.getDragSprite = function () {
  return this.sprite;
};

SpriteSheetSprite.prototype.preload = function () {
  //this.scene.load.image('empty', require("../images/demo/empty.png"));
};


SpriteSheetSprite.prototype.create = function () {
  let designX = this.position.x;
  let designY = this.position.y;

  let sprite = this.scene.add.sprite(
    this.scaler.scaleX(designX),
    this.scaler.scaleY(designY),
    'empty'
  );
  sprite.setOrigin(0.5, 1.0);
  //this.scaler.scaleSpriteByHeight(sprite);
  sprite.keepData = {
    resized: true,
    designX: designX,
    designY: designY,
    scaleFactor: this.scaleFactor,  // the scale factor is specified by uesr
    x: sprite.x
  };
  sprite.depth = this.depth;
  this.group.add(sprite);
  this.sprite = sprite;

  return this.sprite;
};

SpriteSheetSprite.prototype.loadTextures = function () {
  this.sprite.setTexture(this.textureHash);
  this.scaler.scaleSpriteByHeight(this.sprite);
  this.sprite.setScale(this.sprite.scaleX * this.scaleFactor, this.sprite.scaleY * this.scaleFactor);

  let animationKey = 'AK_' + ('' + new Date().getTime()).slice(8) + Math.floor((Math.random() * 10000));
  this.scene.anims.create({
    key: animationKey,
    frames: this.scene.anims.generateFrameNumbers(this.spritesheet.texture),
    frameRate: this.spritesheet.fps,
    repeat: -1
  });
  // console.log("animation started with key" + animationKey);
  // console.log("animation started with fsp" + this.spritesheet.fps);
  this.sprite.anims.play(animationKey);
};

SpriteSheetSprite.prototype.unloadTextures = function () {
};

export default SpriteSheetSprite;
