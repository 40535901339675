
function FurnitureSprite(scene, scaler) {
  this.scene = scene;
  this.scaler = scaler;
};

FurnitureSprite.prototype.setTexture = function (textureHash) {
  this.textureHash = (textureHash && textureHash.length > 0) ? textureHash : 'empty';
};

FurnitureSprite.prototype.setPosition = function (position) {
  this.position = position;
};

FurnitureSprite.prototype.setDepth = function (depth) {
  this.depth = depth;
};

FurnitureSprite.prototype.setGroup = function (group) {
  this.group = group;
};

FurnitureSprite.prototype.getDragSprite = function () {
  return this.sprite;
};

FurnitureSprite.prototype.preload = function () {
  //this.scene.load.image('empty', require("../images/demo/empty.png"));
};


FurnitureSprite.prototype.create = function () {
  let designX = this.position.x;
  let designY = this.position.y;

  let sprite = this.scene.add.image(
    this.scaler.scaleX(designX),
    this.scaler.scaleY(designY),
    'empty'
  );
  sprite.setOrigin(0.5, 0.5);
  //this.scaler.scaleSpriteByHeight(sprite);
  sprite.keepData = {
    resized: true,
    designX: designX,
    designY: designY,
    x: sprite.x
  };
  sprite.depth = this.depth;
  this.group.add(sprite);

  this.sprite = sprite;
  return this.sprite;
};

FurnitureSprite.prototype.loadTextures = function () {
  this.sprite.setTexture(this.textureHash);
  this.scaler.scaleSpriteByHeight(this.sprite);
};

FurnitureSprite.prototype.unloadTextures = function () {
  console.log("Unload furniture sprite " + this.textureHash);
  this.sprite.setTexture('empty');
  // furniture is not uploaed by user generally, so we don't
  // unload the texture as it maybe used by others
  //this.scene.textures.remove(this.textureHash);
};

export default FurnitureSprite;
