
function ArtworksSprite(scene, scaler) {
  this.scene = scene;
  this.scaler = scaler;
};

ArtworksSprite.prototype.setTexture = function (textureHash) {
  this.textureHash = (textureHash && textureHash.length > 0) ? textureHash : 'empty';
};

ArtworksSprite.prototype.setPosition = function (position) {
  this.position = position;
};

ArtworksSprite.prototype.setAnchor = function (anchor) {
  this.anchor = anchor;
};

ArtworksSprite.prototype.setGroup = function (group) {
  this.group = group;
};

ArtworksSprite.prototype.setDepth = function (depth) {
  this.depth = depth;
};

ArtworksSprite.prototype.addToGroup = function (group) {
  return group.add(this.sprite);
};

ArtworksSprite.prototype.getDragSprite = function () {
  return this.sprite;
};

ArtworksSprite.prototype.preload = function () {
  //this.scene.load.image('empty', require("../images/demo/empty.png"));
};


ArtworksSprite.prototype.create = function () {
  let designX = this.position.x;
  let designY = this.position.y;

  let sprite = this.scene.add.image(
    this.scaler.scaleX(designX),
    this.scaler.scaleY(designY),
    'empty'
  );
  if (this.anchor) {
    sprite.setOrigin(this.anchor.x, this.anchor.y);
  } else {
    sprite.setOrigin(0.5, 0.5);
  }
  //this.scaler.scaleSpriteByHeight(sprite);
  sprite.keepData = {
    resized: true,
    designX: designX,
    designY: designY,
    x: sprite.x
  };
  sprite.depth = this.depth;
  this.group.add(sprite);

  this.sprite = sprite;
  return this.sprite;
};

ArtworksSprite.prototype.loadTextures = function () {
  this.sprite.setTexture(this.textureHash);
  this.sprite.setFrame(this.frame);
  this.scaler.scaleSpriteByHeight(this.sprite);
};

ArtworksSprite.prototype.unloadTextures = function () {
  console.log("Unload artworks sprite " + this.textureHash);
  this.sprite.setTexture('empty');
  if (this.textureHash.length >= 32) {
    this.scene.textures.remove(this.textureHash);
    this.sprite.setSize(1, 1);
    this.sprite.destroy();
  } 
};

ArtworksSprite.prototype.setFrame = function (frame) {
  this.frame = frame;
};


export default ArtworksSprite;
