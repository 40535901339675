import GC from "./const";

function UIController(scene, scaler, viewer, mode) {
  this.scene = scene;
  this.scaler = scaler;
  this.viewer = viewer;
  this.mode = mode;
  this.depth = 59000;
  this.sidebarDepth = 60001;
  this.group = this.scene.add.group();
  this.regionNumberText;
  this.btnPortfolio;
  // this.btnShare;
  this.wallSprite;
  this.workInfoGroup;
  // this.bgRectLeftSprite;
  // this.bgRectRightSprite;
  this.textWorkSprite;
  this.bgWorkSprite;
  this.regionUpdated = false;

  this.currentRegionIndex = 0;

  this.sidebarWidth = 300;
};

UIController.prototype.preload = function() {
};

UIController.prototype.create = function() {

  if(this.mode == 'single'){
    this.createSingleUI();
  }

  if(this.mode == 'multiple'){
    this.createMultipleUI();

    this.enterAinmation();
  }

  this.resize();
};

UIController.prototype.update = function() {

  if(this.viewer.current.regionUpdated == true)
    return;
  if(this.viewer.current.loadState == GC.LOADED && this.viewer.current.createState == GC.CREATED){
    this.updateUIRegion();
    this.viewer.current.regionUpdated = true;
  }
};

UIController.prototype.resize = function() {
  return;
  var children = this.group.getChildren();
  for(var i = 0; i < children.length; i ++){
    var sprite = children[i];

    if(sprite.keepData && sprite.keepData.resized){
      if(sprite.keepData.location && sprite.keepData.location == 'right'){
        sprite.x = this.scaler.scaleX(this.scaler.designRefWidth() + sprite.keepData.designX);
      } else{
        sprite.x = this.scaler.scaleX(sprite.keepData.designX);
      }
      sprite.y = this.scaler.scaleY(sprite.keepData.designY);
    }
    this.scaler.scaleSpriteByHeight(sprite);
  }


  //button test
  // this.btnTest.setPosition(
  //   this.scaler.scaleX(this.scaler.designRefWidth() - 170 + 130),
  //   this.scaler.scaleY(this.scaler.designRefHeight() - 220)
  // );

  // this.bgRectRightSprite.setPosition(
  //   this.scaler.scaleX(this.scaler.designRefWidth() - 300 + 130),
  //   this.scaler.scaleY(this.scaler.designRefHeight() - 90)
  // );

  // this.textWorkSprite.setPosition(
  //   this.scaler.scaleX(this.scaler.designRefWidth() - 300 - this.bgRectRightSprite.width + 130),
  //   this.scaler.scaleY(this.scaler.designRefHeight() - 90)
  // );

  // this.bgWorkSprite.setPosition(
  //   this.scaler.scaleX(this.scaler.designRefWidth() - 300 - this.bgRectRightSprite.width + 130),
  //   this.scaler.scaleY(this.scaler.designRefHeight() - 90)
  // );
  // this.bgWorkSprite.setScale(this.textWorkSprite.getBounds().width, this.bgRectRightSprite.getBounds().height);

  // this.bgRectLeftSprite.setPosition(
  //   this.scaler.scaleX(this.scaler.designRefWidth() - 300 - this.bgRectRightSprite.width - this.textWorkSprite.width + 130),
  //   this.scaler.scaleY(this.scaler.designRefHeight() - 90)
  // );

  // var index = this.currentRegionIndex;
  // if(this.viewer.regions[index].json.meta.label.hidden == false){
  //   var x1 = 0;
  //   if(this.regionNumberText){
  //     x1 = this.regionNumberText.x + this.regionNumberText.width;
  //   }
  //   var x2 = this.bgRectLeftSprite.x - this.bgRectLeftSprite.width;
  //   if(x1 >= x2){
  //     this.setGroupVisible(this.workInfoGroup, false);
  //   } else{
  //     this.setGroupVisible(this.workInfoGroup, true);
  //   }
  // } else{
  //   this.setGroupVisible(this.workInfoGroup, false);
  // }



  if(this.mode == 'multiple'){
    // this.btnShare.setPosition(
    //   this.scaler.scaleX(this.scaler.designRefWidth() - 40),
    //   this.scaler.scaleY(this.scaler.designRefHeight() - 90)
    // );

  }
};

UIController.prototype.createSingleUI = function() {
  let sprite, designX, designY;

  // portfolio button
  designX = -192;
  designY = 994;
  sprite = this.scene.add.image(
    this.scaler.scaleX(this.scaler.designRefWidth() + designX),
    this.scaler.scaleY(designY),
    'btn_portfolio'
  );
  sprite.setOrigin(0.5, 0.5);
  this.scaler.scaleSpriteByHeight(sprite);
  sprite.keepData = {
    resized: true,
    location: 'right',
    designX: designX,
    designY: designY
  };
  sprite.depth = this.depth;

  sprite.setInteractive();
  sprite.on("pointerdown", pointer => {
    this.btnPortfolio.alpha = 0.6;
  });
  sprite.on("pointerup", pointer => {
    this.btnPortfolio.alpha = 1.0;

    var index = this.currentRegionIndex;
    if(this.viewer && this.viewer.regions[index] && this.viewer.regions[index].portfolio){
      this.viewer.regions[index].portfolio.open('item__1');
    }

  });
  this.group.add(sprite);
  this.btnPortfolio = sprite;

  // this.workInfoGroup = this.createWorkInfoPannal();
  // this.addGroup(this.group, this.workInfoGroup);
};


UIController.prototype.createMultipleUI = function() {
  let sprite, designX, designY;

  var style = {
    font: "20px Arial",
    fill: "#ffffff",
    align: "center",
  };
  designX = 207 + window.sidebarOffset;
  designY = 989;

  sprite = this.scene.add.bitmapText(
    this.scaler.scaleX(designX),
    this.scaler.scaleY(designY),
    'numberRegionFont',
    '', 27).setOrigin(0, 0.5);//.setCenterAlign();
  this.scaler.scaleSpriteByHeight(sprite);
  sprite.keepData = {
    resized: true,
    // xlocation: 'right',
    designX: designX,
    designY: designY,
    offset: 'x',
    initialX: 207,
  };
  sprite.setText('R ' + 1 + ' / ' + this.viewer.regions.length);
  sprite.depth = this.depth;
  this.group.add(sprite);
  this.regionNumberText = sprite;
  // this.regionNumberText.visible = false;

  // portfolio button
  designX = -253;
  designY = 977;
  sprite = this.scene.add.image(
    this.scaler.scaleX(this.scaler.designRefWidth() + designX),
    this.scaler.scaleY(designY),
    'btn_portfolio'
  );
  sprite.setOrigin(0.5, 0.5);
  this.scaler.scaleSpriteByHeight(sprite);
  sprite.keepData = {
    resized: true,
    xlocation: 'right',
    designX: designX,
    designY: designY,
    enableClick: true,
  };
  sprite.depth = this.depth;
  

  this.group.add(sprite);
  this.btnPortfolio = sprite;

  // this.btnPortfolio.visible = false;

  designX = -160;
  designY = 938;
  sprite = this.scene.add.image(
    this.scaler.scaleX(this.scaler.designRefWidth() + designX),
    this.scaler.scaleY(designY),
    'empty'
  );
  sprite.setOrigin(1, 0);
  this.scaler.scaleSpriteByHeight(sprite);
  sprite.keepData = {
    resized: true,
    xlocation: 'right',
    designX: designX,
    designY: designY,
    x: sprite.x,
    enableClick: true
  };
  sprite.depth = this.depth;
  this.portfolioName = sprite;  
  this.group.add(sprite);

  [this.btnPortfolio].map(sprite=>{
    sprite.setInteractive();
    sprite.on("pointerdown", pointer => {
      this.btnPortfolio.alpha = 0.6;
      this.portfolioName.alpha = 0.6;

      this.btnPortfolio.isPressed = true;
    });
    sprite.on("pointerup", pointer => {
      this.btnPortfolio.alpha = 1.0;
      this.portfolioName.alpha = 1.0;
  
      if(this.btnPortfolio.isPressed) {
        this.viewer.current.portfolio.openAlbums();
      }

      this.btnPortfolio.isPressed = false;
      // var index = this.currentRegionIndex;
      // if(this.viewer && this.viewer.regions[index] && this.viewer.regions[index].portfolio){
      //   this.viewer.regions[index].portfolio.open('item__1');
      // }
    });
  });

  //test button
  // designX = this.scaler.designRefWidth() - 170 + 130;
  // designY = this.scaler.designRefHeight() - 220;
  // sprite = this.scene.add.image(
  //   this.scaler.scaleX(designX),
  //   this.scaler.scaleY(designY),
  //   'btn_portfolio'
  // );
  // sprite.setOrigin(1, 0.5);
  // this.scaler.scaleSpriteByHeight(sprite);
  // sprite.keepData = {
  //   resized: true,
  //   designX: designX,
  //   designY: designY,
  //   x: sprite.x
  // };
  // sprite.depth = this.depth;
  // sprite.setInteractive();
  // sprite.on("pointerdown", pointer => {
  //   this.btnTest.alpha = 0.6;
  // });
  // sprite.on("pointerup", pointer => {
  //   this.btnTest.alpha = 1.0;

  //   var index = this.currentRegionIndex;
  //   return this.viewer.regions[index].unloadTextures();
  // });

  // this.group.add(sprite);
  // this.btnTest = sprite;

  // share button
  // designX = this.scaler.designRefWidth() - 40;
  // // designY = this.scaler.designRefHeight() - 40;
  // sprite = this.scene.add.image(
  //   this.scaler.scaleX(designX),
  //   this.scaler.scaleY(designY),
  //   'btn_share'
  // );
  // sprite.setOrigin(1, 0.5);
  // this.scaler.scaleSpriteByHeight(sprite);
  // sprite.keepData = {
  //   resized: true,
  //   designX: designX,
  //   designY: designY,
  //   x: sprite.x
  // };
  // sprite.depth = this.depth;

  // sprite.setInteractive();
  // sprite.on("pointerdown", pointer => {
  //   this.btnShare.alpha = 0.6;
  // });
  // sprite.on("pointerup", pointer => {
  //   this.btnShare.alpha = 1.0;
  //   // this.hide();
  //   // bgSprite.destroy();
  //   // assetSprite.destroy();
  //   // closeSprite.destroy();
  // });
  // this.group.add(sprite);
  // this.btnShare = sprite;

  // this.workInfoGroup = this.createWorkInfoPannal();
  // this.addGroup(this.group, this.workInfoGroup);
  // this.enableDraggers();
};

UIController.prototype.setCurrentRegion = function(regionIndex) {
  this.currentRegionIndex = regionIndex;
  this.updateUIRegion();
};

UIController.prototype.updateUIRegion = function() {
  var index = this.currentRegionIndex;
  if(this.mode == 'multiple'){
    
    // if(this.scene.scene.get('sceneSideBar') && this.scene.scene.get('sceneSideBar').regionNumberText) {
    //   this.scene.scene.get('sceneSideBar').regionNumberText.setText((index + 1) + '/' + this.viewer.regions.length);
    // }

    this.regionNumberText.setText('R ' + (index + 1) + ' / ' + this.viewer.regions.length);
  }

  var source = 'empty';
  if(this.viewer.regions[index].json.meta.label.texture){
    source = this.viewer.regions[index].json.meta.label.texture;
  }
  // this.textWorkSprite.setTexture(source);

  if(this.scene.textures.getTextureKeys().indexOf(source) < 0){
    source = 'empty';
  }
  this.portfolioName.setTexture(source);

  //button
  if(this.viewer && this.viewer.regions[index] && this.viewer.regions[index].portfolio && this.viewer.regions[index].portfolio.isEmpty() == false){
    this.btnPortfolio.visible = true;
    this.portfolioName.visible = true;
  } else{
    this.btnPortfolio.visible = false;
    this.portfolioName.visible = false;
  }

  this.resize();
};

UIController.prototype.createWorkInfoPannal = function() {
  let sprite, designX, designY;
  var group = this.scene.add.group();

  // designX = this.scaler.designRefWidth() - 300 + 130;
  // designY = this.scaler.designRefHeight() - 90;
  // sprite = this.scene.add.image(
  //   this.scaler.scaleX(designX),
  //   this.scaler.scaleY(designY),
  //   'bg_rect_right'
  // );
  // sprite.setOrigin(1, 0.5);
  // this.scaler.scaleSpriteByHeight(sprite);
  // sprite.keepData = {
  //   resized: true,
  //   designX: designX,
  //   designY: designY,
  //   x: sprite.x
  // };
  // sprite.depth = this.depth;
  // group.add(sprite);
  // this.bgRectRightSprite = sprite;

  designX = this.scaler.designRefWidth() - 300 - this.bgRectRightSprite.width + 130;
  // designY = this.scaler.designRefHeight() - 40;
  sprite = this.scene.add.image(
    this.scaler.scaleX(designX),
    this.scaler.scaleY(designY),
    'empty'
  );
  sprite.setOrigin(1, 0.5);
  this.scaler.scaleSpriteByHeight(sprite);
  sprite.keepData = {
    resized: true,
    designX: designX,
    designY: designY,
    x: sprite.x
  };
  sprite.depth = this.depth;
  group.add(sprite);
  this.textWorkSprite =  sprite;

  designX = this.scaler.designRefWidth() - 300 - this.bgRectRightSprite.width + 130;
  sprite = this.scene.add.image(
    this.scaler.scaleX(designX),
    this.scaler.scaleY(designY),
    'bg_dot_work_info'
  );
  sprite.setOrigin(1, 0.5);
  sprite.setScale(this.textWorkSprite.getBounds().width, this.bgRectRightSprite.getBounds().height);
  sprite.keepData = {
    resized: true,
    designX: designX,
    designY: designY,
    x: sprite.x
  };
  sprite.depth = this.depth;
  group.add(sprite);
  this.bgWorkSprite = sprite;

  // designX = this.scaler.designRefWidth() - 300 - this.bgRectRightSprite.width - this.textWorkSprite.width + 130;
  // sprite = this.scene.add.image(
  //   this.scaler.scaleX(designX),
  //   this.scaler.scaleY(designY),
  //   'bg_rect_left'
  // );
  // sprite.setOrigin(1, 0.5);
  // this.scaler.scaleSpriteByHeight(sprite);
  // sprite.keepData = {
  //   resized: true,
  //   designX: designX,
  //   designY: designY,
  //   x: sprite.x
  // };
  // sprite.depth = this.depth;
  // group.add(sprite);
  // this.bgRectLeftSprite = sprite;

  return group;
};

UIController.prototype.addGroup = function(parentGroup, childGroup){
  var children = childGroup.getChildren();
  for(var i = 0; i < children.length; i ++){
    childGroup.add(children[i]);
  }
};

UIController.prototype.setGroupVisible = function(group, visible){
  var children = group.getChildren();
  for(var i = 0; i < children.length; i ++){
    children[i].visible = visible;
  }
};

UIController.prototype.enableDraggers = function() {
  // let region = this;
  // this.draggers.map(dragger => {
  //   dragger.on('dragstart', function() {
  //     region.emit('dragger_dragstart');
  //   });
  //   dragger.on('drag', function(evt) {
  //     region.emit('dragger_drag', {offset: evt.offset, speeds: region.layerSpeeds});
  //   });
  //   dragger.on('dragend', function() {
  //     region.emit('dragger_dragend');
  //   });
  // });
};

UIController.prototype.toHall = function() {
  this.scene.scene.start('sceneGuide', {autoAnimation: true});
};

UIController.prototype.enterAinmation = function() {
  var sceneGuide = this.scene.scene.get('sceneGuide');
  // sceneGuide.showSlidebar();
  // this.scene.scene.get('sceneSideBar').setButtonsEnable();
};

export default UIController;
