var EventEmitter = require('events').EventEmitter;

function Scroller(scene, scaler, dragDirection) {
  this.scene = scene;
  this.scaler = scaler;
  this.dragDirection = dragDirection; //'Horizontal'->横向, 'Vertical'->竖向
  this.dragSprites = [];
  this.dragMaxOffset = 0;

  this.scrollerBounds = {
    top: 0,
    bottom: 1080,
  };

  this.firstEnd = false;
  this.lastEnd = false;

  this.on('dragstart', function () {
    this.dragSprites.map(sprite => {
      sprite.keepData.x = sprite.x;
      sprite.keepData.y = sprite.y;
    });
  });

  this.on('drag', function(evt) {
    if(evt.offset < 0 && this.lastEnd == true)
      return;
    if(evt.offset > 0 && this.firstEnd == true)
      return;
    let v = 2;

    if(this.dragDirection == 'Horizontal') {// 横向滑动
      this.dragSprites.map(sprite => {
        sprite.x = sprite.keepData.x + evt.offset * v;
  
        sprite.keepData.designX =  this.scaler.getDesignY(sprite.x);
      });
    } else {
      this.dragSprites.map(sprite => {
        sprite.y = sprite.keepData.y + evt.offset * v;
  
        sprite.keepData.designY =  this.scaler.getDesignY(sprite.y);
      });
    }
  });

  this.on('dragend', function(evt) {
    this.dragSprites.map(sprite => {
      sprite.alpha = sprite.keepData.alpha;
    });
  }, this);

  this.on('wheel', function(evt) {
    if(evt.offset < 0 && this.firstEnd == true)
      return;
    if(evt.offset > 0 && this.lastEnd == true)
      return;
    let v = 2;

    if(this.dragDirection == 'Horizontal') {// 横向滑动
      this.dragSprites.map(sprite => {
        sprite.keepData.x = sprite.x;
        sprite.x = sprite.keepData.x - evt.offset * v;
  
        // sprite.keepData.designY =  this.scaler.getDesignY(sprite.y);
      });
    } else {
      this.dragSprites.map(sprite => {
        sprite.keepData.y = sprite.y;
        sprite.y = sprite.keepData.y - evt.offset * v;
  
        // sprite.keepData.designY =  this.scaler.getDesignY(sprite.y);
      });
    }
  });
}

Scroller.prototype.__proto__ = EventEmitter.prototype;

Scroller.prototype.create = function() {
  let designX, designY, sprite;

  // designX = 0;
  // designY = 0;
  // sprite = this.scene.add.rectangle(
  //   this.scaler.scaleX(this.scaler.designRefWidth()/2 + designX),
  //   this.scaler.scaleY(this.scaler.designRefHeight()/2 + designY),
  //   this.scaler.designRefWidth(),
  //   this.scaler.designRefHeight()
  //   , 0x000000, 0);
  // sprite.setOrigin(0.5, 0.5);
  // this.scaler.scaleSpriteFill(sprite);
  // sprite.keepData = {
  //   resized: true,
  //   fill: true,
  //   xlocation: 'center',
  //   ylocation: 'center',
  //   designX: designX,
  //   designY: designY
  // };
  // this.bgSprite = sprite;
  
  return sprite;
};

Scroller.prototype.setDragBg = function(sprite) {
  this.bgSprite = sprite;
};

Scroller.prototype.setDragSprites = function(sprites) {
  this.dragSprites = sprites;

  // var allSprites = sprites;
  var allSprites = sprites.concat([this.bgSprite]);
  allSprites.forEach(sprite => {
    sprite.setInteractive({ draggable: true });

    sprite.on('dragstart', function (pointer, dragX, dragY) {
      // console.log(`drag start.. ${pointer.x} ${pointer.y}`);
      this.dragMaxOffset = 0;
      sprite.keepData.x = sprite.x;
      sprite.keepData.y = sprite.y;
      this.emit('dragstart');
    }, this);
  
    sprite.on('drag', function (pointer, dragX, dragY) {
      //console.log(dragX);
      // check whether out area
      let offset;
      if(this.scaler.getDirection() == 'Horizontal') {
        if(this.dragDirection == 'Horizontal') {
          offset = dragX - sprite.keepData.x;
        } else {
          offset = dragY - sprite.keepData.y;
        }
  
        (Math.abs(offset) > this.dragMaxOffset) && (this.dragMaxOffset = Math.abs(offset));
        this.emit('drag', {offset: offset});
      } else {
        if(this.dragDirection == 'Horizontal') {
          offset = (dragY - sprite.keepData.y);
        } else {
          offset = (sprite.keepData.x - dragX);
        }
  
        (Math.abs(offset) > this.dragMaxOffset) && (this.dragMaxOffset = Math.abs(offset));
        this.emit('drag', {offset: offset});
      }
  
    }, this);
  
    sprite.on('dragend', function (pointer, dragX, dragY) {
      // console.log('drag end');
      this.emit('dragend');
    }, this);
  
    sprite.on('wheel', function (pointer, deltaX, deltaY, deltaZ) {
      this.emit('wheel', {offset: deltaY * 0.4});
    }, this); 
  });
  
   
};

Scroller.prototype.setScrollerBounds = function(scrollerBounds) {
  this.scrollerBounds = scrollerBounds
};

Scroller.prototype.checkEnding = function () {
  var self = this;
  var min, max;
  var sprites = this.dragSprites;
  if(sprites.length <= 0)
    return;

  let start, end;
  
  if(this.dragDirection == 'Horizontal') {// 横向滑动
    if(sprites[0].originX == 0) {
      min = sprites[0].x;
    } else if(sprites[0].originX == 0.5) {
      min = sprites[0].x - sprites[0].displayWidth/2;
    } else if(sprites[0].originX == 1) {
      min = sprites[0].x - sprites[0].displayWidth;
    }

    if(sprites[sprites.length - 1].originX == 0) {
      max = sprites[sprites.length - 1].x + sprites[sprites.length - 1].displayWidth;
    } else if(sprites[sprites.length - 1].originX == 0.5) {
      max = sprites[sprites.length - 1].x + sprites[sprites.length - 1].displayWidth/2;
    } else if(sprites[sprites.length - 1].originX == 1) {
      max = sprites[sprites.length - 1].x;
    }

    let right = this.scrollerBounds.right
    if(this.scrollerBounds.rightEnd) {
      right = this.scrollerBounds.rightEnd
    }
    if(this.scaler.scaleMode === 'full') {
      start = this.scaler.scaleBgX(this.scrollerBounds.left);
      end = this.scaler.scaleBgX(right, 'right');
    } else {
      start = this.scaler.scaleX(this.scrollerBounds.left);
      end = this.scaler.scaleX(this.scaler.designRefWidth() + right);
    }
  } else { //纵向滑动
    if(sprites[0].originY == 0) {
      min = sprites[0].y;
      max = sprites[sprites.length - 1].y + sprites[sprites.length - 1].displayHeight;  
    } else if(sprites[0].originY == 0.5) {
      min = sprites[0].y - sprites[0].displayHeight/2;
      max = sprites[sprites.length - 1].y + sprites[sprites.length - 1].displayHeight/2;  
    } else if(sprites[0].originY == 1) {
      min = sprites[0].y - sprites[0].displayHeight;
      max = sprites[sprites.length - 1].y;  
    }
    
    let bottom = this.scrollerBounds.bottom
    if(this.scrollerBounds.bottomEnd) {
      bottom = this.scrollerBounds.bottomEnd
    }
    if(this.scaler.scaleMode === 'full') {
      start = this.scaler.scaleBgY(this.scrollerBounds.top);
      end = this.scaler.scaleBgY(bottom, 'bottom');
    } else {
      start = this.scaler.scaleY(this.scrollerBounds.top);
      end = this.scaler.scaleY(this.scaler.designRefHeight() + bottom);
    }
  }


  if(min >= start && max < end){
    this.firstEnd = true;
    this.lastEnd = true;
    return;
  }

  if(min >= start){
    this.firstEnd = true;

    var offset = min - start;
    if(this.dragDirection == 'Horizontal') {// 横向滑动
      sprites.map(sprite => {
        sprite.x -= offset;
        // sprite.keepData.designX =  this.scaler.getDesignX(sprite.x);
      });
    } else {
      sprites.map(sprite => {
        sprite.y -= offset;
        // sprite.keepData.designY =  this.scaler.getDesignY(sprite.y);
      });
    }
  } else{
    this.firstEnd = false;
  }

  if(max <= end){
    this.lastEnd = true;

    if(min < start){
      var offset = end - max;

      if(this.dragDirection == 'Horizontal') {// 横向滑动
        sprites.map(sprite => {
          sprite.x += offset; 
          // sprite.keepData.designX =  this.scaler.getDesignX(sprite.x);
        });
      } else {
        sprites.map(sprite => {
          sprite.y += offset; 
          // sprite.keepData.designY =  this.scaler.getDesignY(sprite.y);
        });
      }
    }

  } else{
    this.lastEnd = false;
  }
};  

Scroller.prototype.checkCrop = function () {
  if(this.dragSprites.length <= 0){
    return;
  }

  let start, end;
  if(this.dragDirection == 'Horizontal') {// 横向滑动
    if(this.scaler.scaleMode === 'full') {
      start = this.scaler.scaleBgX(this.scrollerBounds.left);
      end = this.scaler.scaleBgX(this.scrollerBounds.right, 'right');
    } else {
      start = this.scaler.scaleX(this.scrollerBounds.left);
      end = this.scaler.scaleX(this.scaler.designRefWidth() + this.scrollerBounds.right);
    }
   

    this.dragSprites.forEach(sprite => {
      if(sprite.type == 'Image' || sprite.type == 'Text') {
        let spriteStart, spriteEnd;
        let scale = 1;
        if(sprite.keepData.scale != undefined) {
          scale = sprite.keepData.scale;
        }
        if(sprite.originX == 0) {
          spriteStart = sprite.x;
          spriteEnd = sprite.x + sprite.displayWidth;
        } else if(sprite.originX == 0.5) {
          spriteStart = sprite.x - sprite.displayWidth/2;
          spriteEnd = sprite.x + sprite.displayWidth/2;
        } else if(sprite.originX == 1) {
          spriteStart = sprite.x - sprite.displayWidth;
          spriteEnd = sprite.x;
        }
        
        if(spriteStart < start) {
          var x = this.scaler.getDesignX(start - spriteStart) /scale;
          var cropW = sprite.width - x;
          sprite.setCrop(x, 0, cropW, sprite.height);
        } else if(spriteEnd > end){
          var x = 0;
          var cropW = sprite.width - this.scaler.getDesignX(spriteEnd - end)/scale;
          sprite.setCrop(x, 0, cropW, sprite.height);
        } else{
          sprite.setCrop(0, 0, sprite.width, sprite.height);
        }
      }
    });
  } else {
    if(this.scaler.scaleMode === 'full') {
      start = this.scaler.scaleBgY(this.scrollerBounds.top);
      end = this.scaler.scaleBgY(this.scrollerBounds.bottom, 'bottom');
    } else {
      start = this.scaler.scaleY(this.scrollerBounds.top);
      end = this.scaler.scaleY(this.scaler.designRefHeight() + this.scrollerBounds.bottom);
    }

    this.dragSprites.forEach(sprite => {
      if(sprite.type == 'Image' || sprite.type == 'Text') {
        let spriteStart, spriteEnd;
        var scale = 1;
        if(sprite.keepData.scale != undefined) {
          scale = sprite.keepData.scale;
        }
        if(sprite.originY == 0) {
          spriteStart = sprite.y;
          spriteEnd = sprite.y + sprite.displayHeight;
        } else if(sprite.originY == 0.5) {
          spriteStart = sprite.y - sprite.displayHeight/2;
          spriteEnd = sprite.y + sprite.displayHeight/2;
        } else if(sprite.originY == 1) {
          spriteStart = sprite.y - sprite.displayHeight;
          spriteEnd = sprite.y;
        }
        
        if(spriteStart < start) {
          var y = this.scaler.getDesignY(start - spriteStart) /scale;
          var cropH = sprite.height - y;
          sprite.setCrop(0, y, sprite.width, cropH);
        } else if(spriteEnd > end){
          var y = 0;
          var cropH = sprite.height - this.scaler.getDesignY(spriteEnd - end)/scale;
          sprite.setCrop(0, y, sprite.width, cropH);
        } else{
          sprite.setCrop(0, 0, sprite.width, sprite.height);
        }
      }
    });
  }
};

export default Scroller;