var EventEmitter = require('events').EventEmitter;

var currentAudio;

function AudioSprite(scene, scaler) {
  this.scene = scene;
  this.scaler = scaler;
  this.dragMaxOffset;
};

AudioSprite.prototype.__proto__ = EventEmitter.prototype;

AudioSprite.prototype.setAssets = function (assets) {
  this.assets = assets;
};

AudioSprite.prototype.setPosition = function (position) {
  this.position = position;
};

AudioSprite.prototype.setGroup = function (group) {
  this.group = group;
};

AudioSprite.prototype.setDepth = function (depth) {
  this.depth = depth;
};

AudioSprite.prototype.setAudioUrl = function (url) {
  this.audioUrl = url;
};

AudioSprite.prototype.getDragSprite = function () {
  return this.sprite;
};

AudioSprite.prototype.preload = function () {
  //this.scene.load.image('empty', require("../images/demo/empty.png"));
};


AudioSprite.prototype.create = function () {
  let designX = this.position.x;
  let designY = this.position.y;
  let texture;

  //audio frame sprite
  let sprite = this.scene.add.image(
    this.scaler.scaleX(designX),
    this.scaler.scaleY(designY),
    'empty'
  );
  sprite.setOrigin(0.5, 0.5);
  sprite.keepData = {
    resized: true,
    designX: designX,
    designY: designY,
    x: sprite.x
  };
  this.group.add(sprite);
  sprite.depth = this.depth;

  //sprite.setInteractive({ draggable: true });
  sprite.on("pointerdown", pointer => {
    this.audioSprite.keepData.pressed = true;
    //console.log(pointer);
    //console.log(this);
  }, this);

  sprite.on("pointerup", pointer => {
    //console.log(pointer);
    //console.log(this);
    (this.dragMaxOffset <= 5) && this.audioUrl && (this.togglePlay());
  }, this);

  // set drag events
  sprite.on('drag', function (pointer, dragX, dragY) {
    //console.log("draggin" + dragX);
    let offset = dragX - this.frameSprite.keepData.x;
    (Math.abs(offset) > this.dragMaxOffset) && (this.dragMaxOffset = Math.abs(offset));
    this.emit('drag', {offset: offset});
  }, this);

  sprite.on('dragstart', function (pointer, dragX, dragY) {
    //console.log(`drag start.. ${pointer.x} ${pointer.y}`);
    this.dragMaxOffset = 0;
    this.frameSprite.keepData.x = this.frameSprite.x;
    this.emit('dragstart');
  }, this);

  sprite.on('dragend', function (pointer, dragX, dragY) {
    this.emit('dragend');
  }, this);

  this.frameSprite = sprite;

  // audio sprite (use empty for sprite)
  /*
  sprite = this.scene.add.image(
    this.scaler.scaleX(designX),
    this.scaler.scaleY(designY),
    'empty'
  );
  sprite.setOrigin(0.5, 0.5);
  // this.scaler.scaleSpriteByHeight(sprite);
  sprite.keepData = {
    resized: true,
    designX: designX,
    designY: designY,
    size:{
      width: this.assets.audio.size.width,
      height: this.assets.audio.size.height,
    },
    x: sprite.x
  };
  this.group.add(sprite);
  sprite.depth = this.depth;

  this.audioSprite = sprite;
  */

  // AudioSprite only used for play sound, no graphical view
  if (this.audioUrl) {
    let audioKey = this.assets.audio.texture;
    let loader = this.scene.load.audio(audioKey, [this.audioUrl]);
    loader.on('filecomplete', (key) => {
      if (key == audioKey) {
        sprite = this.scene.sound.add(audioKey);
        sprite.keepData = {};
        this.audioSprite = sprite;
        //this.audioSprite.play();
        //this.audioSprite.pause();
      }

    });
    loader.start();
  }

  // audio cover sprite
  sprite = this.scene.add.image(
    this.scaler.scaleX(designX),
    this.scaler.scaleY(designY),
    'empty'
  );
  sprite.setOrigin(0.5, 0.5);
  // this.scaler.scaleSpriteByHeight(sprite);
  sprite.keepData = {
    resized: true,
    designX: designX,
    designY: designY,
    size:{
      width: this.assets.cover.size.width,
      height: this.assets.cover.size.height,
    },
    x: sprite.x
  };
  this.group.add(sprite);
  sprite.depth = this.depth;
  //this.dragSprites[l].push(sprite);
  this.coverSprite = sprite;

  // audio play sprite
  sprite = this.scene.add.image(
    this.scaler.scaleX(designX),
    this.scaler.scaleY(designY),
    'empty'
  );
  sprite.setOrigin(0.5, 0.5);
  sprite.keepData = {
    resized: true,
    designX: designX,
    designY: designY,
    x: sprite.x
  };
  this.group.add(sprite);
  sprite.depth = this.depth;

  sprite.on("pointerdown", pointer => {
    this.audioSprite.keepData.pressed = true;
    //console.log(pointer);
    //console.log(this);
  }, this);

  sprite.on("pointerup", pointer => {
    //console.log(pointer);
    //console.log(this);
    (this.dragMaxOffset <= 5) && this.audioUrl && (this.togglePlay());
  }, this);

  // set drag events
  sprite.on('drag', function (pointer, dragX, dragY) {
    //console.log("draggin" + dragX);
    let offset = dragX - this.playSprite.keepData.x;
    (Math.abs(offset) > this.dragMaxOffset) && (this.dragMaxOffset = Math.abs(offset));
    this.emit('drag', {offset: offset});
  }, this);

  sprite.on('dragstart', function (pointer, dragX, dragY) {
    //console.log(`drag start.. ${pointer.x} ${pointer.y}`);
    this.dragMaxOffset = 0;
    this.playSprite.keepData.x = this.playSprite.x;
    this.emit('dragstart');
  }, this);

  sprite.on('dragend', function (pointer, dragX, dragY) {
    this.emit('dragend');
  }, this);

  this.playSprite = sprite;
  return [this.frameSprite, /*this.audioSprite,*/ this.coverSprite, this.playSprite];
};

AudioSprite.prototype.togglePlay = function () {
  if (this.audioSprite && this.audioSprite.keepData.pressed) {
    this.audioSprite.keepData.pressed = false;
    if (currentAudio && currentAudio != this.audioSprite) {
      currentAudio.stop();
    }
    if (currentAudio != this.audioSprite) {
      currentAudio = this.audioSprite;
    }
    // seems we don't need play it? does it works on mobile as well?
    //  currentAudio.play();

    //this.audioSprite.setPaused(!this.audioSprite.isPaused());
    if (this.audioSprite.isPaused) {
      this.audioSprite.resume();
    } else if (this.audioSprite.isPlaying) {
      this.audioSprite.pause();
    } else {
      this.audioSprite.play();
    }

    // we don't hide the cover when audio is playing
    // if(this.coverSprite && this.coverSprite.visible == true){
    //   this.coverSprite.visible = false;
    // }
    this.updateControls();
  }

};

AudioSprite.prototype.updateControls = function () {
  let show = false;

  if (this.audioSprite) {
    if (this.audioSprite.isPaused) {
      show = true;
    } else if (this.audioSprite.isPlaying) {
      show = false;
    } else {
      show = true;
    }
  }

  if (show) {
    this.playSprite.visible = true;
    this.frameSprite.disableInteractive();
  } else {
    this.playSprite.visible = false;
    this.frameSprite.setInteractive({ draggable: true });
  }
};

AudioSprite.prototype.update = function () {
  if (this.frameSprite) {
    let bounds = this.frameSprite.getBounds();
    if (bounds.left + bounds.width < 0) {
      // console.log("out of bound then we stop audio");
      if (this.audioSprite && this.audioSprite.isPlaying) {
        this.audioSprite.pause();
      }
    }
    if (this.scene.cameras.main && bounds.left > this.scene.cameras.main.worldView.width) {
      if (this.audioSprite && this.audioSprite.isPlaying) {
        this.audioSprite.pause();
      }
    }
    this.updateControls();
  }
};

AudioSprite.prototype.loadTextures = function () {
  let texture = this.assets.frame.texture;
  texture = (texture && texture.length > 0) ? texture : 'empty';
  this.frameSprite.setTexture(texture);
  this.scaler.scaleSpriteByHeight(this.frameSprite);

  // we need reset the hit area
  let bounds = this.frameSprite.getBounds();
  this.frameSprite.input.hitArea.setSize(bounds.width, bounds.height);

  // cover sprite
  texture = this.assets.cover.texture;
  texture = (texture && texture.length > 0) ? texture : 'empty';
  this.coverSprite.setTexture(texture);
  this.coverSprite.setDisplaySize(
    this.scaler.vScale() * this.assets.cover.size.width,
    this.scaler.vScale() * this.assets.cover.size.height
  );

  // play button
  this.playSprite.setTexture('btn_play');
  this.scaler.scaleSpriteByHeight(this.playSprite);
  this.playSprite.setInteractive({ draggable: true });
};


module.exports = AudioSprite;
