'use strict';
// 首页

var SceneEnter = new Phaser.Class({

  Extends: Phaser.Scene,
  // Extends: Scene3D,

  initialize:

  function SceneEnter ()
  {
    Phaser.Scene.call(this, { key: 'sceneEnter', active: false });
  },

  init: function(params){
    this.params = params;

    this.sceneBoot = this.scene.get('sceneBoot');
    this.scaler = this.sceneBoot.scaler;

    this.sceneBoot.currentScene = 'sceneEnter';

    this.sceneBoot.scaleMode = 'full';
    this.scaler.scaleMode = 'full';

    this.scene.bringToTop();

    this.sceneBoot.windowResized = true;

    this.clickedEnterBtn = false;

  },

  preload: function ()
  {
  },

  create: function ()
  {
    var self = this;
    var sprite, designX, designY;

    this.homeGroup = this.createHomeGroup();

    this.enterActionGroup = this.createBtnEnterGroup();
    
    this.input.on('pointerup', function () {
      utils.updateButtonsAlpha(this);
    }, this);

    this.sceneBoot.windowResized = true;

    if(this.params && this.params.personal){
      //个人展位
      self.showBtnAnimation();
    } else if(this.params && this.params.system){
      //系长廊
      self.showBtnAnimation();
    }else{
      //正常模式
      self.getCookie();
    }
  },

  update: function(){
  },

  resized: function () {
    if(this.animationing) {
      return;
    }
    utils.resized(this);
  },

  createHomeGroup: function() {
    var self = this;
    var designX, designY, sprite;

    var group = this.add.group();

    designX = 103;
    designY = 102;
    sprite = this.add.image(
      this.scaler.scaleBgX(designX),
      this.scaler.scaleY(designY),
      'title'
    );
    sprite.setOrigin(0, 0);
    this.scaler.scaleSprite(sprite);
    sprite.keepData = {
      resized: true,
      xlocation: 'left',
      designX: designX,
      designY: designY,
    };
    group.add(sprite);

    designX = -110;
    designY = 155.5;
    sprite = this.add.image(
      this.scaler.scaleBgX(designX, 'right'),
      this.scaler.scaleY(designY),
      'label'
    );
    sprite.setOrigin(1, 0.5);
    this.scaler.scaleSprite(sprite);
    sprite.keepData = {
      resized: true,
      xlocation: 'right',
      designX: designX,
      designY: designY,
    };

    group.add(sprite);

    designX = 0;
    designY = 1013;
    sprite = this.add.image(
      this.scaler.scaleBgX(designX, 'center'),
      this.scaler.scaleY(designY),
      'copyright');
    sprite.setOrigin(0.5, 0.5);
    this.scaler.scaleSprite(sprite);
    sprite.keepData = {
      resized: true,
      xlocation: 'center',
      ylocation: 'top',
      designX: designX,
      designY: designY
    };
    group.add(sprite);

    return group;
  },

  createBtnEnterGroup: function() {
    let designX, designY, sprite;
    let group = this.add.group();

    designX = -350;
    designY = 829.5;
    sprite = this.add.image(
      this.scaler.scaleBgX(designX, 'right'),
      this.scaler.scaleY(designY),
      'btn_enter');
    sprite.setOrigin(0.5, 0.5);
    this.scaler.scaleSprite(sprite);
    sprite.keepData = {
      resized: true,
      xlocation: 'right',
      designX: designX,
      designY: designY,
      enableClick: true,
      alpha: 1,
    };
    this.btnEnterSprite = sprite;
    var btnEnterSprite = sprite;
    group.add(sprite);

    sprite.setInteractive();

    sprite.on("pointerdown", pointer => {
      btnEnterSprite.setAlpha(0.5);
      btnEnterSprite.isPressed = true;
    });

    sprite.on("pointerup", pointer => {
      btnEnterSprite.setAlpha(1.0);
      if(btnEnterSprite.isPressed) {
        this.clickEnterButton();
      }
      btnEnterSprite.isPressed = false;
    });
    return group;
  },

  createBtnEnterPersonalGroup: function() {
    let designX, designY, sprite;
    let group = this.add.group();

    designX = -160;
    designY = 750;
    sprite = this.add.image(
      this.scaler.scaleBgX(designX, 'right'),
      this.scaler.scaleY(designY),
      'btn_enter_personal');
    sprite.setOrigin(1, 0.5);
    this.scaler.scaleSprite(sprite);
    sprite.keepData = {
      resized: true,
      xlocation: 'right',
      designX: designX,
      designY: designY,
      enableClick: true,
      alpha: 1,
    };
    var btnEnterSprite = sprite;
    group.add(sprite);

    sprite.setInteractive();

    sprite.on("pointerdown", pointer => {
      btnEnterSprite.setAlpha(0.5);
      btnEnterSprite.isPressed = true;
    });

    sprite.on("pointerup", pointer => {
      btnEnterSprite.setAlpha(1.0);

      if(btnEnterSprite.isPressed) {
        this.clickPersonalEnterButton();
      }
      btnEnterSprite.isPressed = false;
    });

    designX = -266;
    designY = 767;
    sprite = this.add.image(
      this.scaler.scaleBgX(designX, 'right'),
      this.scaler.scaleY(designY),
      this.params.personal.texture);
    sprite.setOrigin(1, 0.5);
    this.scaler.scaleSprite(sprite);
    sprite.keepData = {
      resized: true,
      xlocation: 'right',
      designX: designX,
      designY: designY,
      enableClick: true,
      alpha: 1,
    };
    group.add(sprite);

    sprite.setInteractive();

    sprite.on("pointerdown", pointer => {
      btnEnterSprite.setAlpha(0.5);
      btnEnterSprite.isPressed = true;
    });

    sprite.on("pointerup", pointer => {
      btnEnterSprite.setAlpha(1.0);

      if(btnEnterSprite.isPressed) {
        this.clickPersonalEnterButton();
      }
      btnEnterSprite.isPressed = false;
    });

    return group;
  },

  createBtnEnterSystemGroup: function() {
    let designX, designY, sprite;
    let group = this.add.group();

    designX = -160;
    designY = 750;
    sprite = this.add.image(
      this.scaler.scaleBgX(designX, 'right'),
      this.scaler.scaleY(designY),
      'btn_enter_system_' + this.params.systemIndex);
    sprite.setOrigin(1, 0.5);
    this.scaler.scaleSprite(sprite);
    sprite.keepData = {
      resized: true,
      xlocation: 'right',
      designX: designX,
      designY: designY,
      enableClick: true,
      alpha: 1,
    };
    var btnEnterSprite = sprite;
    group.add(sprite);

    sprite.setInteractive();

    sprite.on("pointerdown", pointer => {
      btnEnterSprite.setAlpha(0.5);
      btnEnterSprite.isPressed = true;
    });

    sprite.on("pointerup", pointer => {
      btnEnterSprite.setAlpha(1.0);

      if(btnEnterSprite.isPressed) {
        this.clickSystemEnterButton();
      }
      btnEnterSprite.isPressed = false;
    });
    return group;
  },

  clickEnterButton: function() {
    const self = this;
    if(this.params && this.params.personal) {
      // 个人展位
      this.clickPersonalEnterButton();
    } else if(this.params && this.params.system) {
      // 系长廊
      this.clickSystemEnterButton();
    } else {
      // 正常模式
      utils.disableAllButtons(this);
      
      this.animationing = true;
      utils.sceneFadeOutAnimation(this, function() {
        self.animationing = false;
        self.scene.stop();
      });

      this.scene.launch('scenePreface', {
        animation: true,
      });
    }
  },

  clickPersonalEnterButton: function() {
    window.custom.locator = this.params.locator;
    utils.disableAllButtons(this);
    this.slideOutAnimation();
    this.scene.get('sceneAnimation').outAnimation();

    utils.toGallery(this.scene, this.params.galleryIndex);
  },

  clickSystemEnterButton: function() {
    utils.disableAllButtons(this);
    this.slideOutAnimation();
    this.scene.get('sceneAnimation').outAnimation();

    utils.toGallery(this.scene, this.params.galleryIndex);
  },

  getCookie: function(){
    var self = this;
    var selected = null;
    try {
      selected = JSON.parse(decodeURIComponent(utils.getCookie('selected')));
    }
    catch(err) {
      console.log(err);
    }
  
    window.custom.locator = utils.getCookie('locator');

    if(
      selected == null ||
      !(selected.galleryId >= 0 && selected.galleryId <= 3)
    ){
      self.showBtnAnimation();
      return;
    }
  
    window.custom.selected = selected;
  
    window.custom.noTip = true;

    this.scene.stop();

    utils.toGallery(this.scene, selected.galleryId, true);
  },
  
  showBtnAnimation: function(){
    var self = this;
    this.enterActionGroup.setVisible(true);
    this.enterActionGroup.getChildren().forEach((s, index) => {
      this.tweens.add({
        targets: s,
        alpha: {
          start: 0,
          to: s.keepData.alpha
        },
        // y: {
        //   from: this.scaler.scaleY(s.keepData.designY - 30),
        //   to: this.scaler.scaleY(s.keepData.designY)
        // },
        duration: 1000,
        delay: 0,
        ease: 'Linear',
        repeat: 0,
        yoyo: false,
        onComplete: function () {
        },
      }); 
    });
  },

  circleAnimation: function() {
    var frame = 0;
    var totalFrame = 150;
    var frames = 15;
    this.circleAnimationTimer = this.time.addEvent({
      delay: 1000/15,
      callback: function(){
        frame ++;
        
        if(frame > totalFrame - 1){
          frame = 0;
        }
        
        if(frame%frames == 0) {
          this.animationSprite.setTexture('question_animation_' + parseInt(frame/frames));
        }
        
        this.animationSprite.setFrame(frame%frames);
      },
      callbackScope: this,
      loop: true
    });

  },

  // 进入长廊动画
  slideOutAnimation: function(func) {
    const self = this;

    this.animationing = true;
    utils.slideAimation(this, 'out', function() {
      self.scene.stop();
      self.animationing = false;
      if(func) {
        func();
      }
    });
  },
});
export default SceneEnter;