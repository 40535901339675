function VideoControllerSprite(scene, scaler, parent) {
  this.scene = scene;
  this.scaler = scaler;
  this.dragMaxOffset;
  this.displayBounds = {
    centerX: 0,
    centerY: 0,
    width: 0,
    height: 0,
  };
  this.parent = parent;
};

VideoControllerSprite.prototype.setDepth = function (depth) {
  this.depth = depth;
};

VideoControllerSprite.prototype.setVideoSprite = function (sprite) {
  this.videoSprite = sprite;
};

VideoControllerSprite.prototype.create = function () {
  this.controllerGroup = this.videoController();

  let designX = 0;
  let designY = 0;
  // video play sprite
  let sprite = this.scene.add.image(
    this.scaler.scaleX(designX),
    this.scaler.scaleY(designY),
    'controller_loading'
  );
  sprite.setOrigin(0.5, 0.5);
  sprite.keepData = {
    resized: true,
    designX: designX,
    designY: designY,
    x: sprite.x,
    y: sprite.y
  };
  this.scaler.scaleSpriteByHeight(sprite);
  sprite.depth = this.depth;
  this.loadingSprite = sprite;
  sprite.setVisible(false);

  var sprites = [this.loadingSprite];
  this.controllerGroup.getChildren().forEach(sprite => {
    sprites.push(sprite);
  });

  this.controllerGroup.setVisible(false);

  return sprites;
};

VideoControllerSprite.prototype.videoController = function () {
  let designX = 0;
  let designY = 0;
  let sprite;

  var group = this.scene.add.group();

  sprite = this.scene.add.rectangle(
    this.scaler.scaleX(designX),
    this.scaler.scaleY(designY),
    1,
    1
    , 0x000000, 0.7);
  sprite.setOrigin(0.5, 0.5);
  this.scaler.scaleSpriteByHeight(sprite);
  sprite.keepData = {
    resized: true,
    designX: designX,
    designY: designY,
    size: {
      width: 0,
      height: 0,
    },
    x: sprite.x,
  };
  sprite.depth = this.depth;
  group.add(sprite);
  this.controllerBgSprite = sprite;

  sprite.setInteractive({ draggable: true });

  sprite.on("pointerdown", pointer => {
    this.controllerBgSprite.keepData.pressed = true;
  }, this);

  sprite.on("pointerup", pointer => {
    if(this.controllerBgSprite.keepData.pressed) {
      (this.dragMaxOffset <= 5) && (this.hideVideoControllerAnimation());
    }
    this.controllerBgSprite.keepData.pressed = false;    
  }, this);

  // set drag events
  sprite.on('drag', function (pointer, dragX, dragY) {
    //console.log("draggin" + dragX);
    if(this.scaler.getDirection() == 'Horizontal'){
      let offset = dragX - this.controllerBgSprite.keepData.x;
      (Math.abs(offset) > this.dragMaxOffset) && (this.dragMaxOffset = Math.abs(offset));
      if(this.parent.emit) {
        this.parent.emit('drag', {offset: offset});
      }
    } else{
      let offset = dragY - this.controllerBgSprite.keepData.y;
      (Math.abs(offset) > this.dragMaxOffset) && (this.dragMaxOffset = Math.abs(offset));
      if(this.parent.emit) {
        this.parent.emit('drag', {offset: offset});
      }
    }
    
  }, this);

  sprite.on('dragstart', function (pointer, dragX, dragY) {
    //console.log(`drag start.. ${pointer.x} ${pointer.y}`);
    this.dragMaxOffset = 0;
    this.controllerBgSprite.keepData.x = this.controllerBgSprite.x;
    this.controllerBgSprite.keepData.y = this.controllerBgSprite.y;
    if(this.parent.emit) {
      this.parent.emit('dragstart');
    }
  }, this);

  sprite.on('dragend', function (pointer, dragX, dragY) {
    if(this.parent.emit) {
      this.parent.emit('dragend');
    }
  }, this);

  sprite.on('wheel', function (pointer, deltaX, deltaY, deltaZ) {
    if(this.parent.emit) {
      this.parent.emit('wheel', {offset: deltaY * 0.4});
    }
  }, this);

  sprite = this.scene.add.rectangle(
    this.scaler.scaleX(designX),
    this.scaler.scaleY(designY),
    1,
    1
    , 0xFFFFFF, 0.5);
  sprite.setOrigin(0.5, 0.5);
  this.scaler.scaleSpriteByHeight(sprite);
  sprite.keepData = {
    resized: true,
    designX: designX,
    designY: designY,
    size:{
      width: 0,
      height: 5,
    },
    x: sprite.x,
  };
  sprite.depth = this.depth;
  this.scrollerBarBgSprite = sprite;
  group.add(sprite);

  sprite = this.scene.add.rectangle(
    this.scaler.scaleX(designX),
    this.scaler.scaleY(designY),
    1,
    1
    , 0xff0000, 0);
  sprite.setOrigin(0, 0.5);
  this.scaler.scaleSpriteByHeight(sprite);
  sprite.keepData = {
    resized: true,
    designX: designX,
    designY: designY,
    size:{
      width: 0,
      height: 5,
    },
    x: sprite.x,
  };
  sprite.depth = this.depth;
  this.scrollerBarSprite = sprite;
  group.add(sprite);

  sprite = this.scene.add.image(
    this.scaler.scaleX(designX),
    this.scaler.scaleY(designY),
    'icon_video_progress'
  );
  sprite.setOrigin(0.5, 0.5);
  sprite.keepData = {
    resized: true,
    designX: designX,
    designY: designY,
    x: sprite.x,
    y: sprite.y
  };
  sprite.depth = this.depth;
  this.scaler.scaleSpriteByHeight(sprite);
  group.add(sprite);
  this.progressIconSprite = sprite;

  sprite.setInteractive({ draggable: true });
  
  // set drag events
  sprite.on('drag', function (pointer, dragX, dragY) {
    if(this.autoHideControllerTimer) {
      this.autoHideControllerTimer.destroy();
    }

    if(this.scaler.getDirection() == 'Horizontal'){
      this.progressIconSprite.x = pointer.x;
    } else{
      this.progressIconSprite.x = pointer.y;
    }

    var totalW = this.scrollerBarBgSprite.displayWidth;

    if (this.progressIconSprite.x < this.scrollerBarSprite.x) {
      this.progressIconSprite.x = this.scrollerBarSprite.x;
    }
    if(this.progressIconSprite.x > this.scrollerBarSprite.x + totalW) {
      this.progressIconSprite.x = this.scrollerBarSprite.x + totalW;
    }
    
    var progress = (this.progressIconSprite.x - this.scrollerBarSprite.x)/totalW;
    var duration = this.videoSprite.getDuration();
    this.videoSprite.setCurrentTime(duration*progress);
  }, this);

  sprite.on('dragstart', function (pointer, dragX, dragY) {
    this.progressDraged = true;
    //console.log(`drag start.. ${pointer.x} ${pointer.y}`);
    // this.dragMaxOffset = 0;
    // this.controllerPlaySprite.keepData.x = this.controllerPlaySprite.x;
    // this.controllerPlaySprite.keepData.y = this.controllerPlaySprite.y;
  }, this);

  sprite.on('dragend', function (pointer, dragX, dragY) {
    this.progressDraged = false;
    this.autoHideController();
  }, this);

  sprite = this.scene.add.bitmapText(
    this.scaler.scaleX(designX),
    this.scaler.scaleY(designY),
    'timeFont',
    '0:00', 
    36
  );
  sprite.setOrigin(0, 0);
  // sprite.setCenterAlign();
  this.scaler.scaleSpriteByHeight(sprite);
  sprite.keepData = {
    resized: true,
    designX: designX,
    designY: designY,
    x: sprite.x,
  };
  sprite.depth = this.depth;
  group.add(sprite);
  this.timeTextSprite = sprite;

  sprite = this.scene.add.bitmapText(
    this.scaler.scaleX(designX),
    this.scaler.scaleY(designY),
    'timeFont',
    '0:00', 
    36
  );
  sprite.setOrigin(0, 0);
  // sprite.setCenterAlign();
  this.scaler.scaleSpriteByHeight(sprite);
  sprite.keepData = {
    resized: true,
    designX: designX,
    designY: designY,
    x: sprite.x,
  };
  sprite.depth = this.depth;
  group.add(sprite);
  this.durationTextSprite = sprite;

  sprite = this.scene.add.image(
    this.scaler.scaleX(designX),
    this.scaler.scaleY(designY),
    'controller_pause'
  );
  sprite.setOrigin(0.5, 0.5);
  sprite.keepData = {
    resized: true,
    designX: designX,
    designY: designY,
    x: sprite.x,
    y: sprite.y
  };
  sprite.depth = this.depth;
  this.scaler.scaleSpriteByHeight(sprite);
  group.add(sprite);
  this.controllerPlaySprite = sprite;
  sprite.setInteractive({ draggable: true });

  sprite.on("pointerdown", pointer => {
    this.controllerPlaySprite.keepData.pressed = true;
    //console.log(pointer);
    //console.log(this);
  }, this);

  sprite.on("pointerup", pointer => {
    //console.log(pointer);
    //console.log(this);
    (this.dragMaxOffset <= 5) && (this.togglePlay());
  }, this);

  // set drag events
  sprite.on('drag', function (pointer, dragX, dragY) {
    //console.log("draggin" + dragX);
    if(this.scaler.getDirection() == 'Horizontal'){
      let offset = dragX - this.controllerPlaySprite.keepData.x;
      (Math.abs(offset) > this.dragMaxOffset) && (this.dragMaxOffset = Math.abs(offset));
      if(this.parent.emit) {
        this.parent.emit('drag', {offset: offset});
      }
    } else{
      let offset = dragY - this.controllerPlaySprite.keepData.y;
      (Math.abs(offset) > this.dragMaxOffset) && (this.dragMaxOffset = Math.abs(offset));
      if(this.parent.emit) {
        this.parent.emit('drag', {offset: offset});
      }
    }
    
  }, this);

  sprite.on('dragstart', function (pointer, dragX, dragY) {
    //console.log(`drag start.. ${pointer.x} ${pointer.y}`);
    this.dragMaxOffset = 0;
    this.controllerPlaySprite.keepData.x = this.controllerPlaySprite.x;
    this.controllerPlaySprite.keepData.y = this.controllerPlaySprite.y;
    if(this.parent.emit) {
      this.parent.emit('dragstart');
    }
  }, this);

  sprite.on('dragend', function (pointer, dragX, dragY) {
    if(this.parent.emit) {
      this.parent.emit('dragend');
    }
  }, this);

  sprite.on('wheel', function (pointer, deltaX, deltaY, deltaZ) {
    if(this.parent.emit) {
      this.parent.emit('wheel', {offset: deltaY * 0.4});
    }
  }, this);

  return group;
};

VideoControllerSprite.prototype.setDurationText = function (duration) {
  this.durationTextSprite.setText(duration);
};

VideoControllerSprite.prototype.videoControllerResize = function () {
  let designX, designY, designRefWidth, designRefHeight;

  let centerX = this.displayBounds.centerX;
  let centerY = this.displayBounds.centerY;
  let width = this.displayBounds.width;
  let height = this.displayBounds.height;

  // loadingSprite
  this.loadingSprite.keepData.designX = centerX;
  this.loadingSprite.keepData.designY = centerY;
  this.loadingSprite.setPosition(centerX, centerY);
  
  // controllerBgSprite
  this.controllerBgSprite.keepData.size = {
    width: width,
    height: height,
  };
  this.controllerBgSprite.keepData.designX = centerX;
  this.controllerBgSprite.keepData.designY = centerY;
  
  this.controllerBgSprite.setDisplaySize(width, height);
  this.controllerBgSprite.setPosition(centerX, centerY);

  // scrollerBarBgSprite
  designRefWidth = width - 320;
  designRefHeight = 5;
  this.scrollerBarBgSprite.keepData.size = {
    width: designRefWidth,
    height: designRefHeight,
  };

  designX = centerX;
  designY = centerY + height/2 - 90;
  this.scrollerBarBgSprite.keepData.designX = designX;
  this.scrollerBarBgSprite.keepData.designY = designY;

  this.scrollerBarBgSprite.setDisplaySize(designRefWidth, designRefHeight);
  this.scrollerBarBgSprite.setPosition(designX, designY);

  // scrollerBarSprite
  designRefWidth = 0;
  designRefHeight = 5;
  this.scrollerBarSprite.keepData.size = {
    width: designRefWidth,
    height: designRefHeight,
  };

  designX = centerX - width/2 + 160;
  designY = centerY + height/2 - 90;
  this.scrollerBarSprite.keepData.designX = designX;
  this.scrollerBarSprite.keepData.designY = designY;

  this.scrollerBarSprite.setDisplaySize(designRefWidth, designRefHeight);
  this.scrollerBarSprite.setPosition(designX, designY);

  // progressIconSprite
  designX = centerX - width/2 + 160;
  designY = centerY + height/2 - 90;
  this.progressIconSprite.keepData.designX = designX;
  this.progressIconSprite.keepData.designY = designY;

  this.progressIconSprite.setPosition(designX, designY);

  // timeTextSprite
  designX = centerX - width/2 + 20;
  designY = centerY + height/2 - 90 - 25/2;
  this.timeTextSprite.keepData.designX = designX;
  this.timeTextSprite.keepData.designY = designY;

  this.timeTextSprite.setPosition(designX, designY);

  // durationTextSprite
  designX = centerX + width/2 - 160 + 20;
  designY = centerY + height/2 - 90 - 25/2;
  this.durationTextSprite.keepData.designX = designX;
  this.durationTextSprite.keepData.designY = designY;

  this.durationTextSprite.setPosition(designX, designY);

  // controllerPlaySprite
  designX = centerX;
  designY = centerY;
  this.controllerPlaySprite.keepData.designX = designX;
  this.controllerPlaySprite.keepData.designY = designY;

  this.controllerPlaySprite.setPosition(designX, designY);
};

VideoControllerSprite.prototype.updateBounds = function (centerX, centerY, width, height) {
  this.displayBounds = {
    centerX: centerX,
    centerY: centerY,
    width: width,
    height: height,
  };
};

VideoControllerSprite.prototype.togglePlay = function () {
  const self = this;

  this.videoSprite.setPaused(!this.videoSprite.isPaused());
  
  this.updateControllerButton();

  if(this.autoHideControllerTimer) {
    this.autoHideControllerTimer.destroy();
  }
  this.autoHideController();
};

VideoControllerSprite.prototype.showController = function () {
  this.controllerGroup.setAlpha(1);
  this.updateControllerButton();
  
  this.controllerGroup.setVisible(true);
  
  this.autoHideController();
};

VideoControllerSprite.prototype.hideVideoController = function () {
  this.controllerGroup.setVisible(false);
  if(this.autoHideControllerTimer) {
    this.autoHideControllerTimer.destroy();
  }
};

VideoControllerSprite.prototype.hideVideoControllerAnimation = function () {
  const self = this;

  this.scene.tweens.add({
    targets: this.controllerGroup.getChildren(),
    alpha: 0,
    duration: 500,
    delay: 0,
    ease: 'Linear',
    repeat: 0,
    yoyo: false,
    onComplete: function () {
      self.controllerGroup.setVisible(false);
    },
  }); 

  if(this.autoHideControllerTimer) {
    this.autoHideControllerTimer.destroy();
  }
};

VideoControllerSprite.prototype.autoHideController = function () {
  var self = this;
  // 5s后无任何操作，自动隐藏播放控件
  this.autoHideControllerTimer = this.scene.time.delayedCall(5 * 1000, function() {
    self.hideVideoControllerAnimation();
  }, this);
};

VideoControllerSprite.prototype.updateControllerButton = function() {
  if(this.videoSprite.isPlaying()) {
    this.controllerPlaySprite.setTexture('controller_pause');
  } else {
    this.controllerPlaySprite.setTexture('controller_play');
  }
};

VideoControllerSprite.prototype.update = function () {
  this.updateVideoControllerProgress();

  this.updateLoadingIcon();
};

VideoControllerSprite.prototype.updateLoadingIcon = function () {
  if(!this.videoSprite || !this.videoSprite.isPlaying()) {
    this.loadingSprite.setVisible(false);
    return;
  }
  if(!this.lastTime) {
    this.lastTime = new Date();
  }
  var now = new Date();
  if (now.getTime() - this.lastTime.getTime() >= 100) {
    if(this.videoSprite.getCurrentTime() - this.lastVideoTime < 0.01){
      this.loadingSprite.setVisible(true);

      this.loadingTween = this.scene.tweens.add({
        targets: this.loadingSprite,
        angle: 360,
        duration: 1000,
        delay: 0,
        ease: 'Linear',
        repeat: -1,
        yoyo: false,
        onComplete: function () {
        },
      });
    } else {
      this.loadingSprite.setVisible(false);
      if(this.loadingTween) {
        this.loadingTween.remove();
      }
    }
    this.lastTime = now;
    this.lastVideoTime = this.videoSprite.getCurrentTime();
  }
};

VideoControllerSprite.prototype.updateVideoControllerProgress = function () {
  if(!this.videoSprite) {
    return;
  }
  if(!this.progressDraged && this.controllerBgSprite && this.isShow()) {
    var progress = this.videoSprite.getProgress();
    this.progressIconSprite.keepData.designX = this.displayBounds.centerX - this.displayBounds.width/2 + 160 + this.scrollerBarBgSprite.width * progress;
    this.progressIconSprite.x = this.scrollerBarSprite.x + this.scrollerBarBgSprite.displayWidth * progress;

    this.scrollerBarSprite.displayWidth = this.scrollerBarBgSprite.displayWidth * progress;
    this.scrollerBarSprite.keepData.size.width = this.scrollerBarBgSprite.width * progress;
  }
  

  var currentTime = utils.timeFormate(this.videoSprite.getCurrentTime());
  this.timeTextSprite.setText(currentTime);

  if(this.videoSprite.isPaused() && this.videoSprite.getProgress() == 1) { //视频暂停播放
    this.hideVideoControllerAnimation();
  }
};

VideoControllerSprite.prototype.isShow = function () {
  var visible = false;
  if(this.controllerBgSprite.visible) {
    visible = true;
  }
  return visible;
};

export default VideoControllerSprite;
