'use strict';

import titleImg from '../images/title.png';
import copyrightImg from '../images/copyright.png';
import btnEnterImg from '../images/btn_enter.png';

import bgPrefaceImg from '../images/bg_preface.png';
import labelImg from '../images/label.png';
import prefaceCNImg from '../images/preface_cn.png';
import prefaceENImg from '../images/preface_en.png';
import btnLanCNImg from '../images/btn_lan_cn.png';
import btnLanENImg from '../images/btn_lan_en.png';
import btnBackImg from '../images/btn_back.png';
import btnNextImg from '../images/btn_next.png';

import titleAuthorsImg0 from '../images/title_authors_0.png';
import titleAuthorsImg1 from '../images/title_authors_1.png';
import titleTeacherImg from '../images/title_teacher.png';
import titleStudentImg from '../images/title_student.png';
import titleCompanyImg from '../images/title_company.png';
import btnAuthorsImg from '../images/btn_authors.png';
import maskRightImg from '../images/mask_right.png';
import avatarsImg from '../images/avatars.png';
import namesImg from '../images/names.png';
import titlesImg from '../images/titles.png';

import galleryTitleImg from '../images/gallery_title.png';
import galleryImg0 from '../images/galleries/0.png';
import galleryImg1 from '../images/galleries/1.png';
import galleryImg2 from '../images/galleries/2.png';
import galleryImg3 from '../images/galleries/3.png';



import sidebarBgImg from '../images/sidebar_bg.png';
import btnBackGalleryImg from '../images/btn_back_gallery.png';
import sidebarTextImg0 from '../images/sidebar_text/0.png';
import sidebarTextImg1 from '../images/sidebar_text/1.png';
import sidebarTextImg2 from '../images/sidebar_text/2.png';
import sidebarTextImg3 from '../images/sidebar_text/3.png';

import bgImg from '../images/bg_wh.png';

import tipMaskImg from '../images/tip_mask.png';
import iconSlideImg from '../images/icon_slide.png';
import iconSlideWhiteImg from '../images/icon_slide_white.png';
import endingImg from '../images/ending.png';
// import textStartingImg from '../images/text_starting.png';
// import textEndingImg from '../images/text_ending.png';

import btnPortfolioImg from '../images/btn_portfolio.png';
import btnClosePortfolioImg from '../images/btn_close_portfolio.png';
import btnLeftPortfolioImg from '../images/btn_left_portfolio.png';
import btnRightPortfolioImg from '../images/btn_right_portfolio.png';
// import btnShareImg from '../images/btn_share.png';
// import btnRectLeftImg from '../images/bg_rect_left.png';
// import btnRectRightImg from '../images/bg_rect_right.png';
import bgDotWorkInfoImg from '../images/bg_dot_work_info.png';

import numberRegionImg from '../images/number_region.png';
import numberRegionXml from '../images/number_region.xml';

import numberPortfolioImg from '../images/number_portfolio.png';
import numberPortfolioXml from '../images/number_portfolio.xml';

import timeFontImg from '../images/time_font.png';
import timeFontXml from '../images/time_font.xml';

import btnZoomInImg from '../images/btn_zoom_in.png';
import btnZoomOutImg from '../images/btn_zoom_out.png';
import iconZoomProgressImg from '../images/icon_zoom_progress.png';

import hotspotImg from '../images/hotspot.png';
import hotspotSlideImg from '../images/hotspot_slide.png';
import btnPlayImg from '../images/btn_play.png';

import iconVideoProgressImg from '../images/icon_video_progress.png';
import controllerPlayImg from '../images/controller_play.png';
import controllerPauseImg from '../images/controller_pause.png';
import controllerLoadingImg from '../images/controller_loading.png';

var ScenePreload = new Phaser.Class({

  Extends: Phaser.Scene,

  initialize:

  function ScenePreload ()
  {
    Phaser.Scene.call(this, { key: 'scenePreload', active: false });

    this.photoSourcesLoaded = false;
    this.photoSourcesAdded = false;
  },

  init: function(params){
    this.sceneBoot = this.scene.get('sceneBoot');
    this.scaler = this.sceneBoot.scaler;

    this.params = params;
  },

  preload: function ()
  {

    if(this.params.personal) {
      console.log(this.params.personal);
      this.load.image(this.params.personal.texture, this.params.personal.path);  
    }

    this.load.image('title', titleImg);
    this.load.image('copyright', copyrightImg);
    this.load.image('btn_enter', btnEnterImg);

    this.load.image('bg_preface', bgPrefaceImg);
    this.load.image('label', labelImg);
    this.load.image('preface_cn', prefaceCNImg);
    this.load.image('preface_en', prefaceENImg);
    this.load.image('btn_lan_cn', btnLanCNImg);
    this.load.image('btn_lan_en', btnLanENImg);
    this.load.image('btn_back', btnBackImg);
    this.load.image('btn_next', btnNextImg);

    this.load.image('title_authors_0', titleAuthorsImg0);
    this.load.image('title_authors_1', titleAuthorsImg1);
    this.load.image('title_teacher', titleTeacherImg);
    this.load.image('title_student', titleStudentImg);
    this.load.image('title_company', titleCompanyImg);
    this.load.image('btn_authors', btnAuthorsImg);
    this.load.image('mask_right', maskRightImg);

    this.load.spritesheet('avatars', avatarsImg, {frameWidth: 145, frameHeight: 144, spacing: 2});
    this.load.spritesheet('names', namesImg, {frameWidth: 333, frameHeight: 145, spacing: 1});
    this.load.spritesheet('titles', titlesImg, {frameWidth: 1105, frameHeight: 152, spacing: 1});
    

    this.load.image('gallery_title', galleryTitleImg);
    const galleryImgs = [
      galleryImg0, galleryImg1, galleryImg2, galleryImg3
    ];
    galleryImgs.forEach((img, i) => {
      this.load.image('gallery_' + i, img)
    })

    this.load.image('sidebar_bg', sidebarBgImg);
    this.load.image('btn_back_gallery', btnBackGalleryImg);
    const sidebarTextImgs = [
      sidebarTextImg0, sidebarTextImg1, sidebarTextImg2, sidebarTextImg3
    ];
    sidebarTextImgs.forEach((img, i) => {
      this.load.image('sidebar_text_' + i, img);
    })

    this.load.image('bg', bgImg);
    this.load.image('tip_mask', tipMaskImg);
    this.load.image('icon_slide', iconSlideImg);
    this.load.image('icon_slide_white', iconSlideWhiteImg);
    this.load.image('ending', endingImg);
    // this.load.image('text_starting', textStartingImg);
    // this.load.image('text_ending', textEndingImg);

    this.load.image('btn_portfolio', btnPortfolioImg);
    this.load.image('btn_close_portfolio', btnClosePortfolioImg);
    this.load.image('btn_left_portfolio', btnLeftPortfolioImg);
    this.load.image('btn_right_portfolio', btnRightPortfolioImg);
    // this.load.image('btn_share', btnShareImg);
    // this.load.image('bg_rect_left', btnRectLeftImg);
    // this.load.image('bg_rect_right', btnRectRightImg);
    this.load.image('bg_dot_work_info', bgDotWorkInfoImg);
    this.load.bitmapFont('numberRegionFont', numberRegionImg, numberRegionXml);
    this.load.bitmapFont('numberPortfolioFont', numberPortfolioImg, numberPortfolioXml);

    this.load.bitmapFont('timeFont', timeFontImg, timeFontXml);
    
    this.load.image('btn_zoom_in', btnZoomInImg);
    this.load.image('btn_zoom_out', btnZoomOutImg);
    this.load.image('icon_zoom_progress', iconZoomProgressImg);

    // load hotspot
    this.load.image('hotspot', hotspotImg);
    this.load.image('hotspot_slide', hotspotSlideImg);
    this.load.image('btn_play', btnPlayImg);

    this.load.image('icon_video_progress', iconVideoProgressImg);
    this.load.image('controller_play', controllerPlayImg);
    this.load.image('controller_pause', controllerPauseImg);
    this.load.image('controller_loading', controllerLoadingImg);

    this.scene.launch('sceneLoading');
    this.isReady = false;
    
    let d1 = new Date();
    this.load.on('progress', function (progress, e){
      this.scene.get('sceneLoading').setLoadingProgress(parseInt(progress*100));
      if(progress >= 1) {
        this.isReady = true;

        let d2 = new Date();
        console.log(d2, d1);
        console.log(d2.getTime() - d1.getTime());
      }
    }, this);
    
  },

  create: function ()
  {
  },

  update: function(){
    if(this.isReady){
      this.isReady = false;

      // if(this.textures.getTextureKeys().indexOf('start') >= 0 ) {
      //   this.scene.launch('sceneTitle');
      //   this.scene.launch('sceneEnter', this.params);
      //   this.scene.launch('sceneAnimation');
      // }

      this.scene.get('sceneLoading').closeLoadingMask();
      this.scene.get('sceneLoading').moveRight();
      
      this.scene.start('sceneModel');
      this.scene.launch('sceneEnter', this.params);
    } 
  },

});
export default ScenePreload;